import React from 'react';

import { Button, styled, Stack, Typography } from '@liscio/ui';

import { parseAmount } from '@liscio/utils';

import { getRecurringEndDateLabel } from './helpers';
import { Loading } from 'components';

export interface DetailsHeaderProps {
  forAccount?: string;
  subject?: string;
  invoiceNumber?: string;
  invoiceDateString?: string; // Format: 2023-05-30
  invoiceDueDateString?: string; // Format 05/30/2023
  invoiceAmount?: string;
  isRecurring?: boolean;
  recurringEndDateString?: string | null; // Format: 2023-11-15
  nextChargeDateString?: string | null; // Format: 2023-06-15
  status?: string;
  isLoading?: boolean;
  showActionButton?: boolean;
  actionButtonLabel?: string;
  onActionButtonClick?: () => void;
}

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: '12px',
}));

export const DetailsHeader: React.FC<DetailsHeaderProps> = ({
  forAccount = '',
  subject = '',
  invoiceDueDateString = '',
  invoiceAmount = '',
  recurringEndDateString,
  nextChargeDateString,
  status = '',
  isRecurring,
  isLoading,
  showActionButton,
  actionButtonLabel = 'Pay',
  onActionButtonClick,
}) => {
  const type = isRecurring ? 'RECURRING' : 'ONE-TIME';
  const recurringEndDateLabel = getRecurringEndDateLabel({
    isRecurring,
    recurringEndDateString,
  });
  const nextChargeDateLabel = nextChargeDateString || '-';

  if (isLoading) {
    return <Loading numberOfElements={1} mt={1} />;
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      padding="0 16px"
      width="100%"
    >
      <Stack>
        <Label>
          {forAccount} {invoiceDueDateString ? `| ${invoiceDueDateString}` : ''}
        </Label>
        <Typography>{subject}</Typography>
        <Label>
          {type}
          {isRecurring && `| END DATE: ${recurringEndDateLabel}`}
        </Label>
        <Label>{`STATUS: ${status.toUpperCase()}`}</Label>
        <Label>
          {isRecurring && `NEXT CHARGE DATE: ${nextChargeDateLabel}`}
        </Label>
      </Stack>
      <Stack justifyContent="center" alignItems="center" gap="8px">
        <Typography>{`${parseAmount(invoiceAmount)}`}</Typography>
        {showActionButton && onActionButtonClick && (
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              onActionButtonClick();
            }}
          >
            {actionButtonLabel}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default DetailsHeader;
