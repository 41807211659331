import { useCallback } from 'react';

import { AuthorizedCpaUser } from '@liscio/api';
import { useAuthStore, setAuthSession, useCpaStore } from '@liscio/common';

import { saveApplicationCpa } from 'stores/cpa/cpa-utils'; // we should move it to common

/**
 * Temporary hook for backwards compatibility
 */
export const useSetLoggedInUserSession = () => {
  const setAuthToken = useAuthStore(({ setAuthToken }) => setAuthToken);
  const setAppCpa = useCpaStore(({ setAppCpa }) => setAppCpa);

  const setLoggedInUserSession = useCallback(
    async (loggedUser: AuthorizedCpaUser) => {
      const cpaToSave = {
        logo: loggedUser.cpa_data?.cpa_logo_url || '',
        name: loggedUser.cpa_data?.cpa_name || '',
        cpa_uuid: loggedUser?.cpa_data?.cpa_uuid,
        custom_liscio_url: loggedUser?.cpa_data?.company_url,
      };
      setAuthToken(loggedUser.auth_token!);
      setAuthSession({
        auth_token: loggedUser.auth_token,
        email: loggedUser.email,
      });
      setAppCpa(cpaToSave);
      await saveApplicationCpa(cpaToSave);
    },
    [setAppCpa, setAuthToken]
  );

  return setLoggedInUserSession;
};
