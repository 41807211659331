import React from 'react';

import { useInitializedFlags } from '@liscio/common';
import { GlobalLoader } from '@liscio/ui';

import { MagicLinkLoginV5View } from './MagicLinkLoginView/MagicLinkLoginV5View';
import { MagicLinkLoginView } from './MagicLinkLoginView/MagicLinkLoginView';

export const MagicLinkLoginFlaggedView = () => {
  const {
    isInitialized,
    flags: { v5Login },
  } = useInitializedFlags();

  if (!isInitialized) {
    return <GlobalLoader open />;
  }

  if (v5Login) {
    return <MagicLinkLoginV5View />;
  }

  return <MagicLinkLoginView />;
};

export default MagicLinkLoginFlaggedView;
