import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { Button } from '@liscio/ui';

import {
  DEFAULT_ERROR_MESSAGE,
  ErrorParser,
  defaultErrorParser,
} from './parseError';

export interface ErrorDialogProps {
  // Async error, typically from react-query response
  error?: any;
  // Custom error parser to display custom error messages based on async error
  parseError?: ErrorParser;
  // Dialog Title
  title?: string;
  // Additional action to execute prior to closing dialog
  onClose?: () => void;
  // Close action button label
  closeLabel?: string;
  // Optional secondary action
  secondaryAction?: () => void;
  // Optional secondary action button label
  secondaryLabel?: string;
  // Default error message to display if unable to parse error w/ default parser
  defaultMessage?: React.ReactNode;
}

export const ErrorDialog: React.FC<ErrorDialogProps> = ({
  error,
  parseError = defaultErrorParser,
  title = 'Submission Error',
  onClose = () => {},
  closeLabel = 'Close',
  secondaryAction,
  secondaryLabel,
  defaultMessage = DEFAULT_ERROR_MESSAGE,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = useCallback(async () => {
    await onClose();
    setDialogOpen(false);
  }, [onClose, setDialogOpen]);
  const errorContent = useMemo(
    () => parseError(error, defaultMessage),
    [parseError, error, defaultMessage]
  );
  useEffect(() => {
    if (errorContent) {
      setDialogOpen(true);
    }
  }, [errorContent, setDialogOpen]);

  return (
    <Dialog
      open={dialogOpen}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" align="center">
          {errorContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {secondaryAction && secondaryLabel && (
          <Button onClick={secondaryAction}>{secondaryLabel}</Button>
        )}
        <Button onClick={closeDialog}>{closeLabel}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
