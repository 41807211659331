import { useContext, useEffect, useRef } from 'react';

import { WebsocketContext } from '../WebsocketProvider';

const useWebsocket = (authToken?: string) => {
  const hasAuthenticated = useRef(false);
  const ctx = useContext(WebsocketContext);

  useEffect(() => {
    if (!hasAuthenticated.current && authToken && ctx?.websocket) {
      ctx?.websocket?.authenticate(authToken!);
      hasAuthenticated.current = true;
    }
  }, [authToken, ctx.websocket]);

  return ctx.websocket!;
};

export default useWebsocket;
