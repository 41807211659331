import React, { useState } from 'react';

import {
  Button,
  ButtonProps,
  IconButton,
  ListItemText,
  Tooltip,
  StackProps,
  FormHelperText,
} from '@mui/material';
import { styled, Icon, Stack } from '@liscio/ui';
import { FieldError } from 'react-hook-form';

import { SelectValue } from './components';
import { AutocompleteOverlaySelect } from 'components';

export interface SelectProps<T>
  extends Omit<StackProps, 'placeholder' | 'onChange'> {
  title: string;
  placeholder?: React.ReactNode;
  value?: T | null;
  data: Array<T>;
  renderItem?(item: T): React.ReactNode;
  onChange(newValue: T | null): void;
  onSearchChange?: (newSearchString: string) => void;
  searchString?: string;
  searchDebounce?: number;
  isLoading?: boolean;
  handleChipClick?: (index: number) => void;
  error?: FieldError;
  // Dislpay error message inline rather than in tooltip
  inlineError?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  btnProps?: ButtonProps;
}

const SelectButton = styled(Button)(() => ({
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  textTransform: 'none',
}));

export const Select = <T extends RichOption | null>({
  title,
  placeholder,
  value,
  data,
  onChange,
  onSearchChange,
  isLoading,
  searchString,
  searchDebounce = 500,
  renderItem: renderItemProp,
  handleChipClick,
  error,
  inlineError = false,
  disabled,
  clearable = true,
  btnProps = {},
  ...stackProps
}: SelectProps<T>) => {
  function stopPropagation(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const openOverlay = (e: React.MouseEvent) => {
    stopPropagation(e);
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => setIsOverlayOpen(false);
  const keyExtractor = (elem: T) => (!Array.isArray(elem) && elem?.value) || 1;
  const renderItem = renderItemProp
    ? renderItemProp
    : (item: T) => <ListItemText>{(item as RichOption)?.label}</ListItemText>;
  const onSelect = (elem: T) => {
    onChange(elem);
    closeOverlay();
  };
  const onClear = (e: React.MouseEvent) => {
    stopPropagation(e);
    onChange(null);
  };

  function handleClick(e: any, index: number) {
    stopPropagation(e);

    if (handleChipClick) return handleChipClick(index);
  }

  return (
    <>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          flexGrow={1}
          {...stackProps}
        >
          <SelectButton
            endIcon={<Icon icon="caretDown" />}
            size="large"
            disabled={disabled}
            onClick={openOverlay}
            {...btnProps}
          >
            <SelectValue
              placeholder={placeholder}
              value={value}
              handleClick={handleClick}
            />
          </SelectButton>
          {error && !inlineError && (
            <Tooltip title={error.message}>
              <IconButton color="error">
                <Icon icon="error" />
              </IconButton>
            </Tooltip>
          )}
          {value && !disabled && clearable && (
            <IconButton
              aria-label="clear-select"
              onClick={onClear}
              edge="end"
              size="small"
            >
              <Icon icon="clear" />
            </IconButton>
          )}
        </Stack>
        {error && inlineError && (
          <FormHelperText error>{error.message}</FormHelperText>
        )}
      </Stack>
      <AutocompleteOverlaySelect
        open={isOverlayOpen}
        closeFunction={closeOverlay}
        data={data}
        keyExtractor={keyExtractor}
        onSearchChange={onSearchChange}
        onSelect={onSelect}
        title={title}
        renderItem={renderItem}
        isLoading={isLoading}
        searchString={searchString}
        searchDebounce={searchDebounce}
      />
    </>
  );
};

export default Select;
