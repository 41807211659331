import { useQuery, useMutation } from '@tanstack/react-query';

import apiClient from 'fetch-utils/api-client';

export function useGetSMSThreads() {
  return useQuery({
    queryKey: ['sms-threads'],
    queryFn: apiClient.sms.getThreads,
  });
}

export function useContactSMSStatus() {
  return useQuery({
    queryKey: ['contact-sms-status'],
    queryFn: apiClient.sms.getContactSMSStatus,
  });
}

export function useSMSContacts() {
  return useQuery({
    queryKey: ['sms-contacts'],
    queryFn: apiClient.sms.getSMSContacts,
  });
}

export function useGetThreadDetail({
  threadId,
  refetch,
  enabled,
}: {
  threadId: string;
  refetch?: boolean;
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: ['sms-thread-detail', threadId],
    refetchInterval: refetch ? 3000 : undefined,
    cacheTime: 5000,
    queryFn: apiClient.sms.getThreadDetail,
    enabled,
  });
}

export function usePostMessageMutation(props?: DefaultMutationHookInterface) {
  return useMutation({
    mutationFn: apiClient.sms.createSMSMessage,
    onSuccess: (response) => {
      const textMessageThreadId = response?.data?.text_message_thread_id;
      props?.onSuccess && props.onSuccess(textMessageThreadId);
    },
  });
}
