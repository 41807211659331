export const ACCOUNT_PARAMS = {
  id: ':id',
};
export type AccountParamsType = typeof ACCOUNT_PARAMS;

export const ACCOUNTS_DOMAIN = 'accounts';
export const ACCOUNTS_DOMAIN_URL = `/${ACCOUNTS_DOMAIN}/*`;

export const ACCOUNTS_PATHS = {
  list: '/list',
  details: {
    root: '/details',
    tabs: {
      overview: 'overview',
      bankAccounts: 'bank-accounts',
      contacts: 'contacts',
      logins: 'logins',
      payroll: 'payroll',
      files: 'files',
    },
  },
};

export const ACCOUNTS_PAGE_TITLES = {
  list: 'Accounts',
  details: 'Account Details',
};

/**
 * Route generators
 */
export const getAccountDetailsPath = (id: number) =>
  `/${ACCOUNTS_DOMAIN}/${id}`;
export const getAccountContactsPath = (id: number) =>
  `${getAccountDetailsPath(id)}/${ACCOUNTS_PATHS.details.tabs.contacts}`;
export const getAccountPayrollPath = (id: number) =>
  `${getAccountDetailsPath(id)}/${ACCOUNTS_PATHS.details.tabs.payroll}`;
export const getAccountFilesPath = (id: number) =>
  `${getAccountDetailsPath(id)}/${ACCOUNTS_PATHS.details.tabs.files}`;
