import React, { useEffect } from 'react';

import { Capacitor } from '@capacitor/core';

import {
  ClosePaymentDialogReasons,
  RegisteredPaymentSessionResponse,
} from '@liscio/api';
import { GlobalLoader } from '@liscio/ui';

import { ErrorDialog } from 'components';

export interface StripePaymentProcessingStateProps {
  closeFunction(closeReason: ClosePaymentDialogReasons): void;
  registerStripePaymentSessionError?: Error | null;
  stripeRegisteredPaymentSession?: RegisteredPaymentSessionResponse;
}

export const StripePaymentProcessingState: React.FC<
  StripePaymentProcessingStateProps
> = ({
  closeFunction,
  registerStripePaymentSessionError,
  stripeRegisteredPaymentSession,
}) => {
  useEffect(() => {
    const redirectUrl = stripeRegisteredPaymentSession?.data?.url;
    if (redirectUrl) {
      closeFunction(ClosePaymentDialogReasons.VOID);
      if (Capacitor.isNativePlatform()) {
        window.open(redirectUrl);
      } else {
        window.location.replace(redirectUrl);
      }
    }
  }, [closeFunction, stripeRegisteredPaymentSession]);

  return (
    <>
      <GlobalLoader open={!registerStripePaymentSessionError} />
      <ErrorDialog
        error={registerStripePaymentSessionError}
        onClose={() => closeFunction(ClosePaymentDialogReasons.VOID)}
      />
    </>
  );
};

export default StripePaymentProcessingState;
