import {
  WorkflowRequestItemFragment,
  WorkflowResponseItem,
} from '@liscio/api/graphql';

import { RequestItemBoolean } from '../RequestItemBoolean/RequestItemBoolean';
import { RequestItemText } from '../RequestItemText/RequestItemText';
import { RequestItemUpload } from '../RequestItemUpload/RequestItemUpload';
import { ResponseType } from 'fetch-utils/workflow/workflows.types';

type RequestItemType = {
  item: WorkflowRequestItemFragment;
  onUpdate: (value: boolean | null) => void;
};

export function composeConditionalItem(item: WorkflowRequestItemFragment) {
  switch (item.responseType) {
    case ResponseType.Boolean:
      return (
        <RequestItemBoolean
          responseItemId={item.responseItem?.id}
          prompt={item.prompt as string}
          value={item?.responseItem?.booleanValue}
          notApplicable={item?.responseItem?.notApplicable || false}
        />
      );
    case ResponseType.Upload:
      return (
        <RequestItemUpload
          responseItemId={item.responseItem?.id}
          value={
            item?.responseItem
              ?.liscioObjects as WorkflowResponseItem['liscioObjects']
          }
          notApplicable={item?.responseItem?.notApplicable || false}
        />
      );
    case ResponseType.Text:
      return (
        <RequestItemText
          responseItemId={item.responseItem?.id}
          prompt={item.prompt as string}
          value={item.responseItem?.textValue}
          notApplicable={item?.responseItem?.notApplicable || false}
        />
      );
    default:
      return null;
  }
}

export function composeRequestItem({ item, onUpdate }: RequestItemType) {
  switch (item.responseType) {
    case ResponseType.Boolean:
      return (
        <RequestItemBoolean
          responseItemId={item.id}
          prompt={item.prompt}
          value={item?.responseItem?.booleanValue}
          onUpdate={(v) => onUpdate(v)}
          notApplicable={item?.responseItem?.notApplicable || false}
        />
      );
    case ResponseType.Upload:
      return (
        <RequestItemUpload
          responseItemId={item.id}
          value={
            item?.responseItem
              ?.liscioObjects as WorkflowResponseItem['liscioObjects']
          }
          notApplicable={item?.responseItem?.notApplicable || false}
        />
      );
    case ResponseType.Text:
      return (
        <RequestItemText
          responseItemId={item.id}
          prompt={item.prompt}
          value={item.responseItem?.textValue}
          notApplicable={item?.responseItem?.notApplicable || false}
        />
      );
    default:
      return null;
  }
}
