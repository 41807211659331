import React, { useLayoutEffect, useMemo } from 'react';

import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useLocation, matchPath, Outlet } from 'react-router-dom';

import { deferredPaths } from './desktop-redirects';
import {
  getIsDesktopRedirectDevice,
  getDesktopSiteRedirect,
} from './desktop-site-redirect-utils';

export interface DesktopSiteRedirectProps {
  children?: React.ReactNode;
}

export const DesktopSiteRedirect = ({ children }: DesktopSiteRedirectProps) => {
  const location = useLocation();
  const ldClient = useLDClient();

  const redirectUrl = getDesktopSiteRedirect(window.location);
  const isDesktopRedirectDevice = getIsDesktopRedirectDevice(
    window.navigator.userAgent
  );
  const shouldDeferRendering = useMemo(
    () =>
      Boolean(
        deferredPaths.some((pattern) => matchPath(pattern, location.pathname))
      ),
    [location]
  );

  // Handle Desktop redirect
  useLayoutEffect(() => {
    if (redirectUrl && isDesktopRedirectDevice) {
      window.location.replace(redirectUrl);
      // Render nothing to prevent one-time-use tokens from being consumed
    }
  }, [redirectUrl, isDesktopRedirectDevice]);

  // Defer rendering until launch darkly has loaded for configured endpoints
  if (ldClient === undefined && shouldDeferRendering) {
    return <Outlet />;
  }

  return children || <Outlet />;
};
