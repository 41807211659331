import { useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useMagicLinkAuthV5Process } from '@liscio/common';
import { GlobalLoader, Box, styled } from '@liscio/ui';

import { ErrorDialog } from 'components/ErrorDialog';
import { CpaLoginV5Selector } from 'modules/authenticate/components/CpaLoginV5Selector/CpaLoginV5Selector';
import { useSetLoggedInUserSession } from 'modules/authenticate/hooks';

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.common.white,
  paddingTop: '48px',
  paddingBottom: '16px',
}));

export const MagicLinkLoginV5View = () => {
  const setLoggedUserSession = useSetLoggedInUserSession();

  const navigate = useNavigate();
  const backToLogin = useCallback(
    () => navigate('/authenticate/login', { replace: true }),
    [navigate]
  );
  const { link } = useParams<{ link: string }>();

  const { isLoading, processState, selectCpa, signInCpas, error } =
    useMagicLinkAuthV5Process({
      token: link || '',
      onProcessFinished: async (loggedInUser) => {
        await setLoggedUserSession(loggedInUser);
      },
    });

  // Cpa selection
  if (processState === 'cpaSelector') {
    return (
      <CpaLoginV5Selector
        onCancel={backToLogin}
        cpas={signInCpas}
        onCpaSelected={selectCpa}
      />
    );
  }

  return (
    <>
      <Wrapper>
        <GlobalLoader open={isLoading} />
      </Wrapper>
      <ErrorDialog error={error} onClose={backToLogin} />
    </>
  );
};
