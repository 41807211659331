import { ContactsAndEmployeeEntity } from '@liscio/api';

import { mungeRecipients, mungeAccounts } from './helpers';
import { Recipient, Account } from '../ContactSelector';
import {
  useAccountsAsTaskData,
  useSearchAccounts,
  useAccountsOfContactOnTheBasisOfUserId,
} from 'fetch-utils/accounts/accounts-hooks';
import {
  useEmployeesAndContacts,
  useEmployeesAndContactsOfAccount,
  useRelContactsAndCstOfAccounts,
  useSearchContacts,
} from 'fetch-utils/contacts/contacts-hooks';
import { useProfileData } from 'fetch-utils/users/user-hooks';

interface HookProps {
  fixedToContactId?: string;
  selectedRecipient?: Recipient | null;
  selectedAccount?: Account | null;
  recipientSearchString: string;
  accountSearchString: string;
}
export const useRecipientAccountData = ({
  fixedToContactId,
  selectedRecipient,
  selectedAccount,
  recipientSearchString,
  accountSearchString,
}: HookProps) => {
  const { isCustomer, isLoading: isProfileLoading } = useProfileData();

  const { data: employeesAndContacts, isLoading: employeesAndContactsLoading } =
    useEmployeesAndContacts();
  const { data: accountsAsTaskData, isLoading: _accountsAsTaskDataLoading } =
    useAccountsAsTaskData(isCustomer);
  const {
    data: employeesAndContactsOfAccount,
    isLoading: _employeesAndContactsOfAccountLoading,
  } = useEmployeesAndContactsOfAccount(selectedAccount?.value || ''); //maybe add isCustomer here?
  const {
    data: relContactsAndCstOfAccount,
    isLoading: _relContactsAndCstOfAccountLoading,
  } = useRelContactsAndCstOfAccounts(selectedAccount?.value || '', isCustomer);
  const { data: searchedContacts, isLoading: _searchedContactsLoading } =
    useSearchContacts(recipientSearchString);
  const { data: searchedAccounts, isLoading: _searchedAccountsLoading } =
    useSearchAccounts(accountSearchString, isCustomer);
  const { data: loggedUserAccounts, isLoading: loggedUserAccountsLoading } =
    useAccountsOfContactOnTheBasisOfUserId();

  // Use isLoading param only if query is enabled
  const accountsAsTaskDataLoading = isCustomer
    ? false
    : _accountsAsTaskDataLoading;
  const employeesAndContactsOfAccountLoading = selectedAccount
    ? _employeesAndContactsOfAccountLoading
    : false;
  const relContactsAndCstOfAccountLoading =
    selectedAccount && isCustomer ? _relContactsAndCstOfAccountLoading : false;
  const searchedContactsLoading = recipientSearchString
    ? _searchedContactsLoading
    : false;
  const searchedAccountsLoading =
    accountSearchString && !isCustomer ? _searchedAccountsLoading : false;

  const recipients = mungeRecipients({
    relContactsAndCstOfAccount,
    selectedAccount,
    recipientSearchString,
    employeesAndContacts,
    searchedContacts,
    employeesAndContactsOfAccount,
    isCustomer,
  });

  const accounts = mungeAccounts({
    fixedToContactId,
    selectedRecipient,
    accountSearchString,
    accountsAsTaskData,
    searchedAccounts,
    loggedUserAccounts,
    isCustomer,
  });

  const { recipientContacts, recipientEmployees } = recipients.reduce(
    (
      acc: {
        recipientContacts: Array<ContactsAndEmployeeEntity>;
        recipientEmployees: Array<ContactsAndEmployeeEntity>;
      },
      person
    ) => {
      if (person.type === 'contact') acc.recipientContacts.push(person);
      if (person.type === 'emp') acc.recipientEmployees.push(person);

      return acc;
    },
    { recipientContacts: [], recipientEmployees: [] }
  );

  return {
    recipientContacts,
    recipientEmployees,
    accounts,
    isLoading:
      employeesAndContactsLoading ||
      accountsAsTaskDataLoading ||
      searchedContactsLoading ||
      employeesAndContactsOfAccountLoading ||
      searchedAccountsLoading ||
      loggedUserAccountsLoading ||
      relContactsAndCstOfAccountLoading ||
      isProfileLoading,
  };
};

export default useRecipientAccountData;
