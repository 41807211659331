import { MessagePostBody, MessageStatus, Participant } from '@liscio/api';
import { newLinesHtml } from '@liscio/utils';

export interface GenerateBottomFormMessagePostBodyInterface {
  message: string;
  threadId: string;
  draftId: string;
  participants: Array<Participant>;
  status?: string;
  title: string;
  doc_ids: number[];
}

export const generateMessagePostBodyForBottomForm: (
  props: GenerateBottomFormMessagePostBodyInterface
) => MessagePostBody = ({
  message,
  draftId = '',
  threadId = '',
  participants,
  status = MessageStatus.Sent,
  title,
  doc_ids,
}) => {
  return {
    id: draftId,
    participants,
    messages: {
      message_thread_id: threadId,
      status,
      content: newLinesHtml(message),
    },
    thread: {
      title,
      is_notify_via_text: false,
      status,
    },
    doc_ids,
  };
};

export default generateMessagePostBodyForBottomForm;
