import React from 'react';

import { Typography, styled, Box } from '@liscio/ui';

import { config } from 'config';
import { COPYRIGHT_TEXT } from 'constants/settings';

const Wrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.common.white,
  paddingBottom: '12px',
}));

export const Copyright = () => (
  <Wrapper>
    <Typography variant="caption">{COPYRIGHT_TEXT}</Typography>
    <Typography variant="caption">•</Typography>
    <Typography variant="caption">{config.version}</Typography>
  </Wrapper>
);

export default Copyright;
