import { useMemo } from 'react';

import { useForm } from 'react-hook-form';

import { UploadFormType } from '@liscio/api';
import { Document } from '@liscio/api/graphql';

import getValidationSchema from './getValidationSchema';
import { RecipientAccount } from 'components/ContactSelector/ContactSelector';
import { useYupValidationResolver } from 'custom-hooks';

export interface UploadDocumentFormData {
  year: string;
  month: string;
  tags: RichOption[];
  message: string;
  files: File[];
  file_name: string;
  doc_ids?: number[];
  doc_info?: Document[];
  participants?: RecipientAccount[];
}

export default function useUploadDocumentForm(
  defaultForm?: Partial<UploadDocumentFormData>,
  type?: UploadFormType
) {
  const defaultValues = defaultForm || {};
  const validationSchema = useMemo(() => getValidationSchema(type), [type]);
  const resolver = useYupValidationResolver(validationSchema);
  return useForm<UploadDocumentFormData>({
    defaultValues: {
      files: [],
      year: '',
      month: '',
      tags: [],
      participants: [],
      ...defaultValues,
    },
    resolver,
  });
}
