import { Message } from '@liscio/api';

const ERROR_STATE = {
  messages: [],
  inboxNumberOfMessages: 0,
  sentNumberOfMessages: 0,
  draftsNumberOfMessages: 0,
  archivedNumberOfMessages: 0,
  allNumberOfMessages: 0,
};

export const extractValues: (
  data: any,
  activeTab: string,
  error?: any
) => {
  messages: Array<Message>;
  inboxNumberOfMessages: number;
  sentNumberOfMessages: number;
  draftsNumberOfMessages: number;
  archivedNumberOfMessages: number;
  allNumberOfMessages: number;
} = (data, activeTab, error) => {
  const hasError = Boolean(error);

  if (hasError) {
    return ERROR_STATE;
  }

  const messages = data?.pages
    .map((page: any) => {
      return page?.[activeTab];
    })
    .reduce((prev: any, current: any) => {
      return [...prev, ...current];
    }, []) as Array<Message>;

  return {
    messages,
    inboxNumberOfMessages: data?.pages?.[0].inbox_count,
    sentNumberOfMessages: data?.pages?.[0].sent_count,
    draftsNumberOfMessages: data?.pages?.[0].draft_count,
    archivedNumberOfMessages: data?.pages?.[0].archive_count,
    allNumberOfMessages: data?.pages?.[0].all_messages_count,
  };
};

export default extractValues;
