import { FormControlLabel, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { UploadFormType } from '@liscio/api';

import { MAX_TEXT_AREA_ROWS } from 'constants/settings';
import { ContactsSelector } from 'modules/messages/views/NewMessageView/components/NewMessageForm/components';

interface FileMessageFormSectionProps {
  type: UploadFormType;
  disabled: boolean;
  fixedToAccountId?: string;
  fixedToContactId?: string;
}

export default function FileMessageFormSection({
  type,
  disabled,
  fixedToAccountId,
  fixedToContactId,
}: FileMessageFormSectionProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  if (type !== UploadFormType.NewFile) return null;
  return (
    <>
      <FormControlLabel
        control={
          <ContactsSelector
            fixedToAccountId={fixedToAccountId}
            fixedToContactId={fixedToContactId}
          />
        }
        label="Recipients:"
        labelPlacement="top"
      />
      <FormControlLabel
        control={
          <TextField
            {...register('message')}
            disabled={disabled}
            fullWidth
            multiline
            minRows={5}
            maxRows={MAX_TEXT_AREA_ROWS}
            placeholder="Write a message..."
            error={Boolean(errors?.message)}
            helperText={errors?.message?.message as string}
          />
        }
        label="Description:"
        labelPlacement="top"
      />
    </>
  );
}
