import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { GlobalLoader } from '@liscio/ui';

import { useCpa } from 'stores/cpa/useCpa';

export interface CpaRouteProps {
  // Route path to redirect
  redirectTo?: string;
  // Whether to redirect when cpa is selected or unselected
  redirectOn?: 'selected' | 'unselected';
}

/**
 * Simple route component that redirects when a cpa hasn't been selected by the user
 */
export const CpaRoute = ({
  redirectTo = '/authenticate/cpa-selection',
  redirectOn = 'unselected',
}: CpaRouteProps) => {
  const { cpa, isLoading } = useCpa();

  if (isLoading) return <GlobalLoader open />;

  // Handle redirection
  if (
    (redirectOn === 'unselected' && !cpa) ||
    (redirectOn === 'selected' && cpa)
  ) {
    return <Navigate to={redirectTo} replace />;
  }

  return <Outlet />;
};
