import React from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { Participant } from '@liscio/api';
import { Box, Typography } from '@liscio/ui';

import { getAdditionalParticipants } from './helpers';
import { UserChip } from 'components';

export interface AdditionalParticipantListProps {
  participants: Array<Participant>;
}

export const AdditionalParticipantList: React.FC<
  AdditionalParticipantListProps
> = ({ participants }) => {
  const { watch } = useFormContext();
  const { remove } = useFieldArray({ name: 'participants' });
  const participantsInForm = watch('participants') as Array<Participant>;

  const handleRemove = (participantToRemoveId: Participant['id']) => {
    const index = participantsInForm.findIndex(
      (participantInForm) => participantInForm.id === participantToRemoveId
    );
    if (index) {
      remove(index);
    }
  };

  const additionalParticipants = getAdditionalParticipants(
    participants,
    participantsInForm
  );

  if (additionalParticipants.length === 0) {
    return null;
  }

  return (
    <Box gap={2} mb={2}>
      <Typography display="block" sx={{ mb: 2 }} variant="caption">
        Additional Participant:
      </Typography>
      {additionalParticipants.map((additionalParticipant) => (
        <UserChip
          key={additionalParticipant.id}
          userName={additionalParticipant.contact_name}
          accountName={additionalParticipant.account_name}
          onRemoveClick={() => {
            handleRemove(additionalParticipant.id);
          }}
        />
      ))}
    </Box>
  );
};

export default AdditionalParticipantList;
