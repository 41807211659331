export const TASKS_DOMAIN = 'tasks';
export const TASKS_DOMAIN_URL = `/${TASKS_DOMAIN}/*`;

export const TASKS_PATHS = {
  list: '/list',
  details: '/details',
  eDoc: '/edoc',
  getSignature: '/get-signature',
  newTask: '/new',
};

export const TASKS_PAGE_TITLES = {
  list: 'Tasks',
  details: 'Task Details',
  eDoc: 'eDoc',
  getSignature: 'Get Signature',
  newTask: 'New Task',
};
