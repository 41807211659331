import React from 'react';

import { IconButton, Typography, Icon } from '@liscio/ui';

import { DocumentDetail } from '@liscio/api';
import { downloadDocumentDetailFile } from '@liscio/common';

import { BodyWrapper } from 'components/screenComposition';
import { fileStatusToasts } from 'utils/toasts';

export interface UnsupportedFileTypeViewerProps {
  file?: DocumentDetail | void;
}

export const UnsupportedFileTypeViewer: React.FC<
  UnsupportedFileTypeViewerProps
> = ({ file }) => {
  async function handleDownloadFile() {
    if (file) {
      const { onSuccess, onError } = fileStatusToasts(
        file?.doc_name || 'Your file'
      );

      await downloadDocumentDetailFile(file).then(onSuccess).catch(onError);
    }
  }

  if (!file) {
    return null;
  }
  return (
    <BodyWrapper
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap="16px"
    >
      <Typography variant="h5" textAlign="center">
        Please download the file to view the details.
      </Typography>
      <IconButton onClick={handleDownloadFile} color="primary">
        <Icon icon="download" fontSize="large" />
      </IconButton>
    </BodyWrapper>
  );
};

export default UnsupportedFileTypeViewer;
