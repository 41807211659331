import React, { useContext, useEffect, useRef, useState } from 'react';

import { Capacitor } from '@capacitor/core';
import ScanbotSDKNative from 'cordova-plugin-scanbot-sdk';
import ScanbotSDK from 'scanbot-web-sdk';

import { VITE_SCANBOT_KEY_WEB } from './scanbot-key';

type SDKProvided = { sdk?: ScanbotSDK | typeof ScanbotSDKNative };

export const ScanbotContext = React.createContext<SDKProvided>({
  sdk: undefined,
});

interface ScanBotProviderProps {
  children: React.ReactNode;
}

export default function ScanbotProvider({ children }: ScanBotProviderProps) {
  const hasInitialized = useRef(false);
  const [sdk, setSdk] = useState<SDKProvided>({ sdk: undefined });

  useEffect(() => {
    async function initializeBrowserScanbot() {
      const scanBot = await ScanbotSDK.initialize({
        licenseKey: VITE_SCANBOT_KEY_WEB || '',
        engine: '/',
      });

      setSdk({ sdk: scanBot as ScanbotSDK });
    }

    if (!hasInitialized.current && !Capacitor.isNativePlatform()) {
      initializeBrowserScanbot();
      hasInitialized.current = true;
    }
  }, []);

  return (
    <ScanbotContext.Provider value={sdk}>{children}</ScanbotContext.Provider>
  );
}

export function useScanbotContext() {
  return useContext(ScanbotContext);
}
