import React, { MouseEvent, useCallback } from 'react';

import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { useNavigate, NavigateOptions } from 'react-router-dom';

export interface LinkProps extends MuiLinkProps {
  to?: string;
  options?: NavigateOptions;
}

export const Link = ({ to, options, onClick, ...linkProps }: LinkProps) => {
  const navigate = useNavigate();
  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      // Handle custom callback
      if (onClick) {
        onClick(event);
      }

      // local link navigate
      if (to) {
        event.preventDefault();
        event.stopPropagation();
        navigate(to, options);
      }
    },
    [onClick, to, options, navigate]
  );

  return (
    <MuiLink {...linkProps} href={linkProps.href || to} onClick={handleClick} />
  );
};
