import React from 'react';

import { styled, Box, Stack, Typography } from '@liscio/ui';
import { Interweave } from 'interweave';

import { Avatar } from 'components';

export interface CommentProps {
  avatarUrl?: string;
  commentBy?: string;
  commentBody?: string;
  tym?: string;
}

const Wrapper = styled(Stack)({
  width: '100%',
});

const Date = styled(Typography)(({ theme }) => ({
  flexShrink: 0,
  fontSize: '13px',
  color: theme.palette.common.blueGray[500],
}));

const Content = styled(Box)(({ theme }) => ({
  color: theme.palette.common.blueGray[500],
}));

export const Comment: React.FC<CommentProps> = ({
  avatarUrl = '',
  commentBy = '',
  commentBody = '',
  tym = '',
}) => {
  return (
    <Wrapper direction="row" gap="16px">
      <Avatar
        src={avatarUrl}
        alt={commentBy}
        sx={{ width: 24, height: 24, fontSize: '12px' }}
      />
      <Box flexGrow={1}>
        <Box display="flex" justifyContent="space-between" textAlign="start">
          <Typography>{commentBy}</Typography>
          <Date>{tym}</Date>
        </Box>
        <Content>
          <Interweave content={commentBody} />
        </Content>
      </Box>
    </Wrapper>
  );
};

export default Comment;
