import React, { useCallback } from 'react';

import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { ClosePaymentDialogReasons, TaskDetails } from '@liscio/api';
import { Button, Stack, Typography } from '@liscio/ui';
import { parseAmount } from '@liscio/utils';

import { BankAccount } from './components';
import { Loading } from 'components';
import { useGetCustomer } from 'fetch-utils/customers/customers-hooks';
import {
  PayInvoiceHandlerStates,
  StateData,
} from 'modules/invoices/components/PayInvoiceHandlerV2/PayInvoiceHandlerV2';

export interface SelectBankAccountStateProps {
  invoice: void | TaskDetails | undefined;
  setCurrentState: React.Dispatch<
    React.SetStateAction<PayInvoiceHandlerStates>
  >;
  setStateData: React.Dispatch<React.SetStateAction<StateData>>;
  closeFunction(closeReason: ClosePaymentDialogReasons): void;
}

export const SelectBankAccountState: React.FC<SelectBankAccountStateProps> = ({
  invoice,
  setCurrentState,
  setStateData,
  closeFunction,
}) => {
  const amount = invoice?.invoice_amount;
  const isAchPaymentAllowed = invoice?.is_ach_payment_allowed;
  const isCcPaymentAllowed = invoice?.is_cc_payment_allowed;
  const areMultipleMethodsAllowed = isAchPaymentAllowed && isCcPaymentAllowed;
  const isRecurring = invoice?.is_recurring;
  const accountId = invoice?.account_id || '';

  const { data: customer, isLoading: isCustomerLoading } =
    useGetCustomer(accountId);

  const handlePayClick = useCallback(
    (selectedBankAccountIdToPay: string) => {
      setStateData((currentState) => ({
        ...currentState,
        selectedBankAccountIdToPay,
      }));
      setCurrentState(PayInvoiceHandlerStates.PLAID_PAYMENT_PROCESSING);
    },
    [setCurrentState, setStateData]
  );

  return (
    <>
      <DialogTitle id="alert-dialog-title">{`Payment Amount: ${parseAmount(
        amount || ''
      )}`}</DialogTitle>
      <DialogContent>
        <Stack gap="8px">
          {isCustomerLoading && <Loading mt={0} numberOfElements={1} />}
          {!isCustomerLoading &&
            customer?.sources.map((source) => (
              <BankAccount
                key={source.id}
                bankName={source.bank_name}
                id={source.id}
                last4={source.last4}
                status={source.status}
                onPayClick={handlePayClick}
                isRecurring={isRecurring}
              />
            ))}
          {!isCustomerLoading && customer?.sources.length === 0 && (
            <Typography>No Accounts Available</Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (areMultipleMethodsAllowed) {
              setCurrentState(PayInvoiceHandlerStates.SELECT_PAYMENT_METHOD);
            } else {
              closeFunction(ClosePaymentDialogReasons.CANCEL);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setCurrentState(PayInvoiceHandlerStates.PLAID_ADDING_BANK_ACCOUNT);
          }}
        >
          Add New Account
        </Button>
      </DialogActions>
    </>
  );
};

export default SelectBankAccountState;
