import React from 'react';

import { Control, useController } from 'react-hook-form';

import { CheckboxList, CheckboxListProps } from './CheckboxList';

export interface RHFCheckboxListProps extends CheckboxListProps {
  name: string;
  control: Control<any>;
}

/**
 * react-hook-form ready CheckboxList
 */
export const RHFCheckboxList = ({
  name,
  control,
  ...props
}: RHFCheckboxListProps) => {
  const { field } = useController({ name, control });

  return (
    <CheckboxList {...props} value={field.value} onChange={field.onChange} />
  );
};
