import React, { useEffect } from 'react';

import toast from 'react-hot-toast';

import { GlobalLoader } from '@liscio/ui';

import { useAuth } from 'fetch-utils/users/use-auth';
import { CpaSelection } from 'modules/authenticate/components/CpaSelection';
import { useCpa } from 'stores/cpa/useCpa';

export const CpaSelectionView = () => {
  const { cpas, selectCpa, isLoading, error } = useCpa();
  const { logout } = useAuth();

  useEffect(() => {
    if (error) {
      toast.error(
        'Unable to log in to workspace. If error persists, please contact administrator.'
      );
    }
  }, [error]);

  return (
    <>
      <CpaSelection cpas={cpas} selectCpa={selectCpa} onCancel={logout} />
      <GlobalLoader open={isLoading} />
    </>
  );
};
