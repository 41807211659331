import React from 'react';

import { Button } from '@mui/material';
import { styled } from '@mui/system';

import { Box, Typography } from '@liscio/ui';

import { CpaStateItem } from 'stores/cpa/cpa.types';

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.common.white,
  paddingTop: '96px',
}));

export interface CpaSelectionProps {
  cpas: CpaStateItem[];
  selectCpa: (cpa: CpaStateItem) => void;
  onCancel: () => void;
}

export const CpaSelection = ({
  cpas,
  selectCpa,
  onCancel,
}: CpaSelectionProps) => {
  return (
    <Wrapper>
      <Typography variant="h4" textAlign="center" mb={4}>
        Select Workspace
      </Typography>
      {cpas.map((cpa) => (
        <Box key={cpa.id} display="flex" flexDirection="column" mt={3}>
          <Button
            onClick={() => selectCpa(cpa)}
            variant="contained"
            size="large"
            color="primary"
          >
            {cpa.name}
          </Button>
        </Box>
      ))}
      <Box display="flex" flexDirection="column" mt={6}>
        <Button
          onClick={onCancel}
          variant="contained"
          size="large"
          color="secondary"
        >
          Cancel
        </Button>
      </Box>
    </Wrapper>
  );
};
