import { useEffect, useRef, useState } from 'react';

import { RawMessage, Message } from '@liscio/api';
import { scrollToElement } from '@liscio/utils';

export const LAST_ELEMENT_ID = 'last_list_element';

export default function useScrollToLastMessage(
  messages: Array<Message | RawMessage> = []
) {
  const [isResizing, setIsResizing] = useState(false);
  const timeout = useRef<NodeJS.Timeout>();

  // scroll to the last element as long as we are not currently resizing
  // i.e., wait for virtual keyboard to finish squishing the viewport
  useEffect(() => {
    window.scrollTo({ top: 0 });
    const domElement = document.getElementById(LAST_ELEMENT_ID);
    if (domElement && !isResizing) {
      window.scrollTo({ top: 0 });
      scrollToElement(domElement);
    }
  }, [messages.length, isResizing]);

  function trackResizing() {
    setIsResizing(true);
    // if we hear another resize event, clear the timeout so resizing does not yet become false
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setIsResizing(false);
    }, 100);
  }

  useEffect(() => {
    window.visualViewport?.addEventListener('resize', trackResizing);

    return () =>
      window.visualViewport?.removeEventListener('resize', trackResizing);
  }, []);
}
