export const SMS_DOMAIN = 'sms';
export const SMS_DOMAIN_URL = `/${SMS_DOMAIN}/*`;

export const SMS_PATHS = {
  new: '/new',
  list: '/list',
  details: '/details',
};

export const SMS_PAGE_TITLES = {
  list: 'List',
  details: 'Message Details',
  new: 'New SMS Message',
};
