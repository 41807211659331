import React from 'react';

import { OAuthConfig as OAuthConfigInterface } from '@liscio/api';
import { useOAuthV5Process } from '@liscio/common';
import { Button, styled, useTheme, MicrosoftIcon } from '@liscio/ui';

import { config } from 'config';

const PROVIDER = 'outlook365';

const OutlookButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.brandColors.white,
  color: theme.palette.common.neutrals[800],
  border: `1px solid ${theme.palette.common.neutrals[800]}`,
}));

export const OutlookOAuthV5Login = () => {
  const oAuthConfig: OAuthConfigInterface = config.oauth[PROVIDER];

  const theme = useTheme();
  const { initiateOAuth } = useOAuthV5Process({
    provider: PROVIDER,
    oAuthConfig,
  });

  return (
    <OutlookButton
      type="button"
      onClick={initiateOAuth}
      variant="contained"
      sx={{ background: theme.palette.common.white }}
      startIcon={<MicrosoftIcon fontSize="small" />}
      size="large"
    >
      Login with Microsoft
    </OutlookButton>
  );
};
