import { ChangeEvent, useState } from 'react';

import {
  RadioGroup,
  FormControlLabel,
  Radio,
  radioClasses,
  formControlLabelClasses,
  FormGroup,
  Switch,
} from '@mui/material';
import { styled } from '@liscio/ui';
import { visuallyHidden } from '@mui/utils';
import toast from 'react-hot-toast';

import { useWorkflowViewContext } from '../../context/WorkflowViewContext';
import { RequestItemDisplayProps } from '../RequestItem/RequestItem';
import { useUpdateResponseItemBool } from 'fetch-utils/workflow/workflow-hooks';

export interface RequestItemBooleanProps extends RequestItemDisplayProps {
  value?: boolean | null;
  onUpdate?: (value: boolean | null) => void;
}

const FormControlLabelStyles = styled(FormControlLabel)(
  ({ theme, checked, disabled }) => {
    return {
      [`&.${formControlLabelClasses.root}`]: {
        padding: '12px 16px',
        alignItems: 'center',
        display: 'flex',
        border: checked
          ? `1.5px solid ${theme.palette.primary.main}`
          : `1.5px solid ${theme.palette.common.blueGray[200]}`,
        backgroundColor: checked
          ? `${theme.palette.primary.main}1a`
          : 'transparent',
        marginBottom: '5px',

        [`&:hover`]: {
          border: disabled
            ? undefined
            : `2px solid ${theme.palette.primary.main}`,
        },
        [`& .${formControlLabelClasses.label}`]: {
          color: checked ? theme.palette.primary.main : 'inherit',
          fontWeight: checked ? 'bold' : 'normal',
          textTransform: 'none',
        },
      },
      [`& .${radioClasses.root}`]: visuallyHidden,
    };
  }
);

export const RequestItemBoolean = ({
  prompt,
  responseItemId: id,
  value,
  onUpdate = () => {},
  notApplicable: _notApplicable,
}: RequestItemBooleanProps) => {
  const getValueForRadio = () => {
    if (notApplicable) return null;
    if (value === true) return true;
    if (value === false) return false;
    return null;
  };

  const [notApplicable, setNotApplicable] = useState(_notApplicable);
  const [radioValue, setRadioValue] = useState(getValueForRadio());
  const { mutate: booleanMutation, isLoading: isMutating } =
    useUpdateResponseItemBool();

  const { setDirtyField } = useWorkflowViewContext();

  // register setvalueas wasn't running, should probably convert to use <Controlled>
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === 'true';
    setRadioValue(value);
    onUpdate(value);
    setDirtyField(id, true);
    booleanMutation(
      {
        workflowResponseItemId: id,
        booleanValue: value,
      },
      {
        onSettled: () => {
          setDirtyField(id, false);
        },
      }
    );
  };

  const handleNotApplicable = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setNotApplicable(checked);
    onUpdate(checked ? null : radioValue);
    booleanMutation(
      {
        workflowResponseItemId: id,
        notApplicable: checked,
      },
      {
        onError: () => {
          toast.error('Failed to update response item');
          setNotApplicable(!checked);
          onUpdate(!checked ? null : radioValue);
        },
      }
    );
  };

  return (
    <>
      <RadioGroup aria-label={prompt} onChange={handleOnChange} value={value}>
        <FormControlLabelStyles
          key="yes"
          value={true}
          checked={notApplicable ? false : radioValue === true}
          disabled={isMutating || notApplicable}
          control={<Radio></Radio>}
          label={'Yes'}
        />
        <FormControlLabelStyles
          key={'no'}
          value={false}
          disabled={isMutating || notApplicable}
          checked={notApplicable ? false : radioValue === false}
          control={<Radio></Radio>}
          label={'No'}
        />
      </RadioGroup>
      <FormGroup>
        <FormControlLabel
          label="This item does not apply to me"
          control={
            <Switch
              disabled={isMutating}
              onChange={handleNotApplicable}
              checked={notApplicable}
            />
          }
        />
      </FormGroup>
    </>
  );
};
