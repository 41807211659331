import { useCallback, useEffect } from 'react';

import { Capacitor } from '@capacitor/core';
import { useLocation } from 'react-router-dom';

import { HomeResponse } from '@liscio/api';

import { config } from 'config';
import { useHomeData } from 'fetch-utils/users/user-hooks';

type TrackFunction = (type: string, properties: HashMap) => void;

export interface RudderStack {
  track: TrackFunction;
  page: (config: any) => void;
}

export const swapIdsWithStaticString = (url: string) => {
  if (!url) return '';

  let isLookBehindSupported = false;

  try {
    // eslint-disable-next-line prefer-regex-literals, no-new
    new RegExp('(?<=)');
    isLookBehindSupported = true;
  } catch (err) {
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
    // If the agent doesn't support look behinds, the attempted
    // creation of a RegExp object using that syntax throws and
    // isLookBehindSupported remains false.
  }

  const DIGITS_REGEX = /[0-9]+/g;
  const digitsReplaced = url.replace(DIGITS_REGEX, '<digits>');

  if (isLookBehindSupported) {
    // Not using a RegEx literal here because its very presence in the bundle throws a dramatic error in Safari.
    // eslint-disable-next-line prefer-regex-literals, no-useless-escape
    const ID_REGEX = new RegExp(
      '(?<=(sms|inbox|sent|draft|archive|all_messages|pinned|cp|i)/)\\S+(?<![new])',
      'g'
    );

    return digitsReplaced.replace(ID_REGEX, '<hash-id>');
  }

  return digitsReplaced;
};

export const getRelevantRudderDetails = (user?: HomeResponse['data']) => {
  const sanitizedPath = Capacitor.isNativePlatform()
    ? 'native-device'
    : swapIdsWithStaticString(window.location.pathname);
  if (!user) return { sanitizedPath };

  const userClone = {
    role: user.role,
    cpa_name: user.cpa_name,
    is_employee: user.is_employee,
  };

  return {
    ...userClone,
    sanitizedPath,
  };
};

export function useRudderTrack() {
  const { data: homeData } = useHomeData();
  const user = homeData?.data;

  // Note that, to prevent using rudderstack in dev and staging,
  // rudderstack only works with this environment variable:
  // VITE_RUDDERSTACK_KEY="2SA5jqr5e2ScM4kwKtpUxsKn5Ap"
  const rudderTrack: TrackFunction = useCallback(
    (type, properties = {}) => {
      window.rudderanalytics.track(type, {
        ...getRelevantRudderDetails(user),
        ...properties,
      });
    },
    [user]
  );

  const rudderTrackMock = useCallback(() => {}, []);

  return config.rudderStack.isEnabled ? rudderTrack : rudderTrackMock;
}

export function useRudderStackTrackAccountTabs() {
  const location = useLocation();
  const { data: homeData } = useHomeData();
  const user = homeData?.data;
  const splitURL = location.pathname.split('/');
  const tab = splitURL[splitURL.length - 1];

  useEffect(() => {
    if (config.rudderStack.isEnabled) {
      window.rudderanalytics?.page({
        accountTab: tab,
        ...getRelevantRudderDetails(user),
      });
    }
  }, [location, tab, user]);
}
