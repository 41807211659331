import React from 'react';

import { useNavigate } from 'react-router-dom';

import { GlobalLoader } from '@liscio/ui';

import { ConfirmationDialog } from 'components';
import { useArchiveDocument } from 'fetch-utils/documents/documents-hooks';
import { FILES_DOMAIN, FILES_PATHS } from 'modules/files/route-constants';

export interface ConfirmationDialogProps {
  id: number;
  open: boolean;
  tab?: string;
  closeFunction(): void;
  onSuccess?: (id: number) => void;
  isFileArchived?: boolean;
}

export const ArchiveFileConfirmationDialog: React.FC<
  ConfirmationDialogProps
> = ({ open, tab, id, onSuccess, closeFunction, isFileArchived }) => {
  const navigate = useNavigate();
  const handleSuccess = (res: Response & { document_ids: number[] }) => {
    if (typeof onSuccess === 'function') {
      onSuccess(id);
    } else {
      navigate(`/${FILES_DOMAIN}${FILES_PATHS.list}`, { state: { tab } });
    }

    closeFunction();
  };

  const title = isFileArchived
    ? 'Confirm unarchive this file'
    : 'Confirm archive this file';
  const content = isFileArchived
    ? 'Are you sure you want to unarchive this file?'
    : 'Are you sure you want to archive this file?';
  const confirmLabel = isFileArchived ? 'Unarchive' : 'Archive';

  const { mutate: archiveDocument, isLoading: isDocumentDeleting } =
    useArchiveDocument({
      onSuccess: handleSuccess,
    });

  const onConfirm = async () => {
    closeFunction();
    archiveDocument({
      documentId: id,
      mode: isFileArchived ? 'UNARCHIVE' : 'ARCHIVE',
    });
  };

  return (
    <>
      <ConfirmationDialog
        open={open}
        onConfirm={onConfirm}
        onCancel={closeFunction}
        title={title}
        content={content}
        confirmLabel={confirmLabel}
      />
      <GlobalLoader open={isDocumentDeleting} />
    </>
  );
};

export default ArchiveFileConfirmationDialog;
