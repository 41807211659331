import React, { useState, ReactNode } from 'react';

import { SxProps } from '@mui/material';

import { styled, Stack, Typography, Icon } from '@liscio/ui';

import { defaultRenderer } from './helpers';

interface KeyValueBoxProps {
  header: string;
  keyValues: { [key: string]: number | string | void | object | ReactNode };
  keyValuesRenderer?: (keyValues: {
    [key: string]: number | string | void | object | ReactNode;
  }) => ReactNode;
  sx?: SxProps;
  startOpen?: boolean;
  hideButton?: boolean;
}

const HeadingStack = styled(Stack)(({ theme }) => ({
  borderBo: 'none',
  fontSize: '1.1rem',
  width: '100%',
  paddingBottom: '15px',
  borderBottom: `solid 1px ${theme.palette.grey[200]}`,
}));

export default function KeyValueBox({
  header,
  keyValues,
  keyValuesRenderer = defaultRenderer,
  sx,
  startOpen = false,
  hideButton = false,
}: KeyValueBoxProps) {
  const [isOpen, setIsOpen] = useState(startOpen || hideButton);
  if (!keyValues) return null;

  function handleClick() {
    if (hideButton) return null;

    setIsOpen((currentOpenState) => !currentOpenState);
  }

  const expandIcon = isOpen ? 'chevronUp' : 'chevronDown';
  return (
    <Stack sx={sx}>
      <HeadingStack
        onClick={handleClick}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography sx={{ fontSize: '1.1rem' }}>{header}</Typography>
        {!hideButton && <Icon icon={expandIcon} />}
      </HeadingStack>
      {isOpen && keyValuesRenderer(keyValues)}
    </Stack>
  );
}
