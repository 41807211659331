export const DASHBOARD_DOMAIN = 'dashboard';
export const DASHBOARD_DOMAIN_URL = `/${DASHBOARD_DOMAIN}/*`;

export const DASHBOARD_PATHS = {
  home: '/home',
};

export const DASHBOARD_PAGE_TITLES = {
  home: 'Dashboard',
};
