import React from 'react';

import { styled, Box } from '@liscio/ui';

import { Logo, SignInForm, Copyright, ConfirmMfaForm } from './components';
import { useAuth } from 'fetch-utils/users/use-auth';

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.common.white,
  paddingTop: '48px',
  paddingBottom: '16px',
}));

const LoginView = () => {
  // Single hook instance for maintaining local auth state across flows
  const auth = useAuth();

  return (
    <>
      <Logo />
      <Wrapper>
        {auth.mfaRequired && auth.mfaEmail ? (
          <ConfirmMfaForm
            email={auth.mfaEmail}
            loginWithPasscode={auth.loginWithMfaCode}
            onBackToLogin={auth.clearMfaCredentials}
            error={auth.loginError}
          />
        ) : (
          <SignInForm auth={auth} />
        )}
      </Wrapper>
      <Copyright />
    </>
  );
};

export default LoginView;
