import React from 'react';

import { TextField, TextFieldProps } from '@mui/material';

export const TextFieldRef = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    return <TextField {...props} inputRef={ref} fullWidth />;
  }
);

export default TextFieldRef;
