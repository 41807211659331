import React from 'react';

import { useFormContext, useFieldArray } from 'react-hook-form';

import { ContactsSelector as ContactsSelectorComponent } from 'components';

export interface ContactsSelectorProps {
  fixedToAccountId?: string;
  fixedToContactId?: string;
}

export const ContactsSelector: React.FC<ContactsSelectorProps> = ({
  fixedToAccountId,
  fixedToContactId,
}) => {
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const { append, remove } = useFieldArray({ name: 'participants' });
  const value = getValues('participants');

  return (
    <ContactsSelectorComponent
      fixedToAccountId={fixedToAccountId}
      fixedToContactId={fixedToContactId}
      value={value}
      placeholder="Select participants"
      onAddElement={append}
      onRemoveElement={(_, index) => {
        remove(index);
      }}
      error={Boolean(errors?.participants)}
      helperText={String(errors?.participants?.message)}
    />
  );
};

export default ContactsSelector;
