import { Fragment, useEffect, useMemo, useState } from 'react';

import { FormControlLabel, FormGroup, Skeleton, Switch } from '@mui/material';
import { Button, Icon, Typography, Chip } from '@liscio/ui';
import toast from 'react-hot-toast';

import { UploadFormType } from '@liscio/api';
import { Document, WorkflowResponseItem } from '@liscio/api/graphql';

import { useWorkflowViewContext } from '../../context/WorkflowViewContext';
import { RequestItemDisplayProps } from '../RequestItem/RequestItem';
import { Overlay } from '@liscio/common';
import { useDocumentInfo } from 'fetch-utils/documents/documents-hooks';
import { useUpdateResponseItemDocUpload } from 'fetch-utils/workflow/workflow-hooks';
import UploadDocumentFormView from 'modules/forms/documents-form/UploadDocumentFormView';
import { UploadDocumentFormData } from 'modules/forms/documents-form/useUploadDocumentForm';

export interface RequestItemUploadProps extends RequestItemDisplayProps {
  value: WorkflowResponseItem['liscioObjects'] | undefined;
}

export const RequestItemUpload = ({
  notApplicable: _notApplicable,
  responseItemId,
  value,
}: RequestItemUploadProps) => {
  const [isAddingFile, setIsAddingFile] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [notApplicable, setNotApplicable] = useState(_notApplicable);
  const initialLegacyIds = useMemo(
    () => value?.map((item) => `${item.id}`),
    [value]
  );

  // get document metadata (by legacyId) for existing documents in the response item
  const {
    data: docData,
    isLoading: docDataIsLoading,
    fetchStatus: docDataFetchStatus, // https://github.com/TanStack/query/issues/3975
  } = useDocumentInfo(initialLegacyIds || []);

  const { setDirtyField } = useWorkflowViewContext();

  const documentsAreLoading = docDataIsLoading && docDataFetchStatus !== 'idle';

  useEffect(() => {
    if (!docData?.documents || docData?.documents?.length < 1) return;
    setUploadedFiles(docData?.documents);
  }, [docData]);

  const { mutate: uploadMutation, isLoading: isMutating } =
    useUpdateResponseItemDocUpload();

  // UUID not legacy is used as the id for the useUpdateResponseItemDocUpload mutation
  const createLiscioObjectData = (docInfo: Document[]) => {
    if (!docInfo || docInfo.length < 1) return [];

    // remove any documents that don't have a uuid so undefined isn't passed to the server
    return docInfo
      .filter((doc) => doc.uuid)
      .map((doc) => ({
        liscioObjectType: 'Document',
        id: `${doc.uuid}`,
      }));
  };

  const handleFileUpload = (
    // docInfo is passed in from uploadAndSendDocDirectly -> handleUploadDocument
    docInfo: UploadDocumentFormData['doc_info']
  ) => {
    if (!docInfo || docInfo.length < 1) return;

    setIsAddingFile(false);

    const liscioObjectData = createLiscioObjectData([
      ...docInfo,
      ...uploadedFiles,
    ]);

    setDirtyField('liscioObjects', true);
    uploadMutation(
      {
        workflowResponseItemId: responseItemId,
        liscioObjectData,
      },
      {
        onSettled: (data) => {
          setDirtyField('liscioObjects', false);
          // onError doesn't get triggered if there are errors in the response
          if (data?.updateResponseItemDocUpload?.errors?.length) {
            console.log('errors', data?.updateResponseItemDocUpload?.errors);
          } else {
            setUploadedFiles([...docInfo, ...uploadedFiles]);
          }
        },
      }
    );
  };

  const handleRemoveClick = (fileUUID: string) => {
    const fileListWithSelectedUUIDremoved = uploadedFiles.filter(
      (file) => file.uuid !== fileUUID
    );

    const liscioObjectData = createLiscioObjectData(
      fileListWithSelectedUUIDremoved
    );
    setDirtyField('liscioObjects', true);
    uploadMutation(
      {
        workflowResponseItemId: responseItemId,
        liscioObjectData,
      },
      {
        onSettled: (data) => {
          setDirtyField('liscioObjects', false);
          // onError doesn't get triggered if there are errors in the response
          if (data?.updateResponseItemDocUpload?.errors?.length) {
            console.log('errors', data?.updateResponseItemDocUpload?.errors);
          } else {
            setUploadedFiles(fileListWithSelectedUUIDremoved);
          }
        },
      }
    );
  };

  const handleNotApplicable = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotApplicable(e.target.checked);
    uploadMutation(
      {
        workflowResponseItemId: responseItemId,
        notApplicable: e.target.checked,
      },
      {
        onError: () => {
          toast.error('Failed to update response item');
          setNotApplicable(!e.target.checked);
        },
      }
    );
  };

  return (
    <>
      {!notApplicable && (
        <>
          {uploadedFiles.length > 0 && (
            <Typography variant="body1">Uploaded Files</Typography>
          )}
          {documentsAreLoading && <Skeleton height={60} />}
          {uploadedFiles.map((file) => (
            <Fragment key={file.uuid}>
              <Chip
                sx={{ width: '100%', marginBottom: '8px' }}
                label={
                  <>
                    <Typography variant="caption" sx={{ paddingRight: '5px' }}>
                      {file.doc_name}
                    </Typography>
                    <Icon icon="close" sx={{ fontSize: '.5rem' }} />
                  </>
                }
                onClick={() => handleRemoveClick(file.uuid)}
              />
            </Fragment>
          ))}
        </>
      )}
      <>
        <Button
          disabled={isMutating || notApplicable}
          onClick={() => setIsAddingFile(true)}
        >
          Upload
        </Button>
        <Overlay open={isAddingFile}>
          <UploadDocumentFormView
            type={UploadFormType.Workflow}
            goBack={() => setIsAddingFile(false)}
            onFileUpload={handleFileUpload}
          />
        </Overlay>
      </>
      <FormGroup>
        <FormControlLabel
          label="This item does not apply to me"
          control={
            <Switch
              disabled={isMutating}
              onChange={handleNotApplicable}
              checked={notApplicable}
            />
          }
        />
      </FormGroup>
    </>
  );
};
