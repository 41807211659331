import React, { useCallback, useMemo, useState } from 'react';

import { Box, BoxProps, Stack, StackProps } from '@liscio/ui';

import { Checkbox, CheckboxProps } from '../Checkbox';

export interface CheckboxListOption {
  value: string;
  label: string;
}

export interface CheckboxListProps {
  options?: CheckboxListOption[];
  name?: string;
  onChange?: (values: string[]) => void;
  value?: string[];
  defaultValue?: string[];
  containerProps?: StackProps;
  rowProps?: BoxProps;
  checkboxProps?: CheckboxProps;
}

export const CheckboxList = ({
  options = [],
  onChange = () => {},
  value: controlledValue,
  defaultValue = [],
  // spread props
  containerProps,
  rowProps,
  checkboxProps,
}: CheckboxListProps) => {
  const [localValue, setLocalValue] = useState(defaultValue);
  const resolvedValue = useMemo(
    () => controlledValue || localValue,
    [controlledValue, localValue]
  );
  const handleOptionClick = useCallback(
    (value: string) => () => {
      if (resolvedValue.includes(value)) {
        const filteredValue = resolvedValue.filter((v) => v !== value);
        onChange(filteredValue);
        setLocalValue(filteredValue);
      } else {
        const addedValue = [...resolvedValue, value];
        onChange(addedValue);
        setLocalValue(addedValue);
      }
    },
    [resolvedValue, onChange, setLocalValue]
  );

  return (
    <Stack {...containerProps}>
      {options.map(({ label, value }) => (
        <Box key={value} {...rowProps}>
          <Checkbox
            {...checkboxProps}
            label={label}
            onClick={handleOptionClick(value)}
            checked={resolvedValue.includes(value)}
          />
        </Box>
      ))}
    </Stack>
  );
};
