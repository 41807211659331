import * as yup from 'yup';

import { UploadFormType } from '@liscio/api';

import { MAX_FILE_SIZE_IN_MB } from 'constants/settings';
import { checkFileSize } from 'fetch-utils/documents/documentsCallsUtils';

export const getValidationSchema = (type?: UploadFormType) =>
  yup.object({
    file_name: yup
      .string()
      .test('required-name-in-edit', 'Required', (value) =>
        type === UploadFormType.Edit ? Boolean(value) : true
      ),
    participants: yup.array().test({
      name: 'has-at-least-one-participant',
      message: 'At least one participant required',
      test: (value) => {
        if (type === UploadFormType.NewFile) {
          return value?.length! > 0;
        }

        return true;
      },
    }),
    files: yup
      .mixed()
      .test({
        name: 'has-at-least-one-file',
        message: `No file selected`,
        test: (value) => {
          const castValue = value as File[];
          const doc_ids = yup.ref<number[]>('doc_ids');

          return (
            ((doc_ids as typeof doc_ids) && Array<number>).length > 0 ||
            Boolean(
              castValue?.length > 0 &&
                castValue.every((file) => file instanceof File)
            )
          );
        },
      })
      .test({
        name: 'is-valid-size',
        message: `This file is too large. Files must be smaller than ${MAX_FILE_SIZE_IN_MB}MB`,
        test: (value) => checkFileSize((value as File[])[0]),
      }),
  });

export default getValidationSchema;
