import { initApiClient, getCommonInterceptors } from '@liscio/common';

import { config } from 'config';

const apiClient = initApiClient({
  baseURL: config.api.url,
  ...getCommonInterceptors('/authenticate/logout'),
});
apiClient.client.defaults.headers['x-app-version'] = config.version;
apiClient.client.defaults.headers['x-app-platform'] = config.platform;
apiClient.client.defaults.headers['x-app-name'] = config.appIdentifier;

export default apiClient;
