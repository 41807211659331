import React, { useCallback } from 'react';

import { styled, Box, GlobalLoader } from '@liscio/ui';
import { useCpaStore } from '@liscio/common';
import { useNavigate, useParams } from 'react-router-dom';

import { ConfirmMfaForm } from '../LoginView/components';
import { ErrorDialog } from 'components';
import { useMagicLinkLogin } from 'fetch-utils/users/auth-hooks';

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.common.white,
  paddingTop: '48px',
  paddingBottom: '16px',
}));

export const MagicLinkLoginView = () => {
  const navigate = useNavigate();
  const backToLogin = useCallback(
    () => navigate('/authenticate/login', { replace: true }),
    [navigate]
  );
  const { link } = useParams<{ link: string }>();
  const company_name = useCpaStore(({ queryCompany }) => queryCompany);
  const { isLoading, error, mfaRequired, email, loginWithPasscode } =
    useMagicLinkLogin({ link, company_name });

  return (
    <Wrapper>
      {mfaRequired && (
        <ConfirmMfaForm
          email={email}
          loginWithPasscode={loginWithPasscode}
          error={error}
        />
      )}
      <GlobalLoader open={isLoading} />
      {!mfaRequired && (
        <ErrorDialog title="Invalid Link" error={error} onClose={backToLogin} />
      )}
    </Wrapper>
  );
};
