import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { v4 as uuid } from 'uuid';

export const OAUTH_NONCE = 'oauth_nonce';

/**
 * Create OAuth none token and save to secure storage
 */
export const createNonce = async () => {
  const nonce = uuid();
  await SecureStoragePlugin.set({ key: OAUTH_NONCE, value: nonce });
  return nonce;
};

/**
 * Verify a nonce token with one saved to secure storage
 */
export const verifyNonce = async (nonce: string) => {
  const { value: storedNonce } = await SecureStoragePlugin.get({
    key: OAUTH_NONCE,
  });
  if (!storedNonce || nonce !== storedNonce) {
    throw new Error('Unable to verify OAuth nonce!');
  }
  try {
    await SecureStoragePlugin.remove({ key: OAUTH_NONCE });
  } catch (err) {
    // silently fail
  }
};
