import React, { useCallback, useState } from 'react';

import { List as MUIList, ListItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { InvoiceStatuses, BaseInvoice } from '@liscio/api';
import { getScrollValue } from '@liscio/utils';

import { ListItemButton } from 'components';
import {
  PayInvoiceHandlerV2 as PayInvoiceHandler,
  DetailsHeader,
} from 'modules/invoices/components';
import {
  INVOICES_DOMAIN,
  INVOICES_PATHS,
} from 'modules/invoices/route-constants';

export interface ListProps {
  invoices: Array<BaseInvoice>;
  shouldShowPayButton?: boolean;
  tab: string;
  goBackUrl?: string;
}

export const List: React.FC<ListProps> = ({
  invoices,
  shouldShowPayButton = false,
  tab,
  goBackUrl,
}) => {
  const [invoiceToPayId, setInvoiceToPayId] = useState<string | null>(null);

  const navigate = useNavigate();
  const onClick = useCallback(
    (invoiceId: number) => {
      navigate(
        `/${INVOICES_DOMAIN}${INVOICES_PATHS.details}/${encodeURIComponent(
          invoiceId
        )}`,
        {
          state: {
            tab,
            scroll: getScrollValue(),
            goBackUrl,
          },
        }
      );
    },
    [navigate, tab, goBackUrl]
  );

  return (
    <>
      <MUIList>
        {invoices.map((invoice) => {
          return (
            <ListItem key={invoice.id} disablePadding>
              <ListItemButton
                data-testid={`invoice_${invoice.id}`}
                onClick={() => onClick(invoice.id)}
              >
                <DetailsHeader
                  forAccount={invoice.account_name}
                  invoiceAmount={invoice.invoice_amount}
                  invoiceDueDateString={invoice.invoice_due_date}
                  invoiceNumber={invoice.invoice_number}
                  isRecurring={invoice.is_recurring}
                  nextChargeDateString={invoice.next_charge_date}
                  status={invoice.status}
                  subject={invoice.description}
                  recurringEndDateString={invoice.end_date}
                  actionButtonLabel={invoice.is_recurring ? 'Authorize' : 'Pay'}
                  showActionButton={
                    shouldShowPayButton &&
                    invoice.invoice_status !== InvoiceStatuses.PAID
                  }
                  onActionButtonClick={() => {
                    setInvoiceToPayId(String(invoice.id));
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </MUIList>
      {invoiceToPayId && (
        <PayInvoiceHandler
          invoiceId={invoiceToPayId || ''}
          closeFunction={() => {
            setInvoiceToPayId(null);
          }}
        />
      )}
    </>
  );
};

export default List;
