import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import { WORKFLOWS_PAGE_TITLES } from './route-constants';
import { WorkflowView } from './views';

export const ModuleRoutes = () => (
  <>
    <Helmet title={WORKFLOWS_PAGE_TITLES.list} />
    <Routes>
      <Route path={'/:id'} Component={WorkflowView} />
    </Routes>
  </>
);

export default ModuleRoutes;
