import React, { useCallback, useEffect, useMemo } from 'react';

import { InputAdornment } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';

import { useBiometrics } from '@liscio/common';
import { Box, Button, Stack, Typography } from '@liscio/ui';

import { BiometricsLoginTrigger, BiometricsToggle } from './Biometrics';
import { getValidationSchema } from './helpers';
import { RememberMeToggle, useRememberMe } from './RememberMe';
import { Link } from 'components';
import { ErrorDialog } from 'components/ErrorDialog';
import { config } from 'config';
import { useYupValidationResolver } from 'custom-hooks';
import { useForgotPassword } from 'fetch-utils/users/auth-hooks';
import { UseAuthPayload } from 'fetch-utils/users/use-auth';
import {
  AuthPasswordInput,
  AuthTextField,
} from 'modules/authenticate/components/FormFields';
import {
  GoogleOAuthLogin,
  OutlookOAuthLogin,
} from 'modules/authenticate/components/OAuth';

interface LoginForm {
  email: string;
  password: string;
  // Controls enabling remember me
  enableRememberMe: boolean;
  // Controls enabling biometrics in native
  enableBiometrics: boolean;
}

export interface SignInFormProps {
  auth: UseAuthPayload;
}

export const SignInForm = ({ auth }: SignInFormProps) => {
  const { login, isLoggingIn, loginError, explicitLogout } = auth;
  const {
    saveBiometricCredentials,
    performBiometricVerification,
    isBiometricLoginEnabled,
  } = useBiometrics({ webAppUrl: config.webApp.url });
  const { email: rememberMeEmail, rememberEmail } = useRememberMe();
  const { setForgotPasswordEmail } = useForgotPassword();
  const validationSchema = useMemo(getValidationSchema, []);
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
      enableRememberMe: false,
      enableBiometrics: false,
    },
    resolver,
  });

  // Update/reset email field when remember me loads
  const updateRememberMeEmailValue = useCallback(
    (email: string) => reset({ email }),
    [reset]
  );

  // Form submit login
  const onSubmit: SubmitHandler<LoginForm> = async ({
    email,
    password,
    enableRememberMe,
    enableBiometrics,
  }) => {
    await login({
      email,
      password,
      time_zone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    // Remember me
    if (enableRememberMe || rememberMeEmail) {
      await rememberEmail(email);
    }

    // Biometrics
    if (enableBiometrics || isBiometricLoginEnabled) {
      await saveBiometricCredentials({ email, password });
    }
  };

  // Biometric login
  const biometricLogin = useCallback(async () => {
    const credentials = await performBiometricVerification();

    if (credentials) {
      const { email, password } = credentials;
      await login({
        email,
        password,
        time_zone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    }
  }, [performBiometricVerification, login]);

  // Do biometric login on initial load
  useEffect(() => {
    // Don't do a biometric auto-login after explicit logout
    if (isBiometricLoginEnabled && !explicitLogout) {
      biometricLogin();
    }
  }, [isBiometricLoginEnabled, explicitLogout, biometricLogin]);

  // Forgot Password Capture
  const captureForgotPasswordEmail = useCallback(() => {
    const email = getValues('email');
    setForgotPasswordEmail(email);
  }, [getValues, setForgotPasswordEmail]);

  return (
    <>
      <Typography variant="h2" color="white" textAlign="center" mb={4}>
        Sign In
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
          <AuthTextField
            {...register('email')}
            fullWidth
            type="email"
            placeholder="Email"
            error={Boolean(errors?.email)}
            helperText={errors?.email?.message}
            disabled={isLoggingIn}
            InputProps={{
              endAdornment: isBiometricLoginEnabled ? (
                <InputAdornment
                  position="end"
                  style={{ color: 'inherit', marginRight: 0 }}
                >
                  <BiometricsLoginTrigger biometricLogin={biometricLogin} />
                </InputAdornment>
              ) : undefined,
            }}
          />
          <AuthPasswordInput
            {...register('password')}
            fullWidth
            placeholder="Password"
            error={Boolean(errors?.password)}
            helperText={errors?.password?.message}
            disabled={isLoggingIn}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={isLoggingIn}
            size="large"
          >
            Sign In
          </Button>
          <Box display="flex" flexDirection="column" mt={4}>
            <GoogleOAuthLogin />
          </Box>
          <Box display="flex" flexDirection="column">
            <OutlookOAuthLogin />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <RememberMeToggle
              name="enableRememberMe"
              resetEmailField={updateRememberMeEmailValue}
              control={control}
            />
            <BiometricsToggle name="enableBiometrics" control={control} />
          </Box>
        </Stack>
      </form>
      <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
        <Link
          onClick={captureForgotPasswordEmail}
          to="/authenticate/forgot-password"
          underline="none"
          color={(theme) => theme.palette.text.secondary}
        >
          Forgot your password?
        </Link>
      </Box>
      <ErrorDialog title="Sign In Error" error={loginError} />
    </>
  );
};
