import React from 'react';

import { useFormContext } from 'react-hook-form';

import { SortRadio } from './components';

export interface SortingFormProps {
  options: RichOption[];
  sortOrderName?: string;
  sortFieldName?: string;
}

export const SortingForm: React.FC<SortingFormProps> = ({
  options,
  sortOrderName = 'sort_by',
  sortFieldName = 'field',
}) => {
  const { setValue, watch } = useFormContext();
  const sortOrder = watch(sortOrderName);
  const sortField = watch(sortFieldName);

  const handleChange = (newSortingOptionValue: string) => {
    const isTheSameOptionValue = sortField === newSortingOptionValue;
    if (!isTheSameOptionValue) {
      setValue(sortFieldName, newSortingOptionValue);
      setValue(sortOrderName, 'asc');
    } else {
      const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
      setValue(sortOrderName, newSortOrder);
    }
  };

  return (
    <>
      {options?.map((sortingOption) => {
        const isFieldSelected = sortField === sortingOption.value;
        return (
          <SortRadio
            key={sortingOption.value}
            label={sortingOption.label}
            onChange={() => handleChange(sortingOption.value as string)}
            value={isFieldSelected ? sortOrder : 'none'}
          />
        );
      })}
    </>
  );
};

export default SortingForm;
