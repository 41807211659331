export interface WAIQuestionData {
  workflowId: number;
  data: { text: string };
  type: 'question';
}

// TODO: Verify this inerface as it is a result of
// merging two different type declarations together
export interface WorkflowActionItem {
  id: string;
  workflowId: number;
  text: string;
  data: any;
  type: string;
}

export interface WorkflowAIRPayload {
  workflowActionItemResponse: {
    workflowActionRequestId: string;
    contactId?: number;
    responseData: ResponseData;
  };
}

export interface WorkflowActionRequest {
  id: string;
  contactId?: number;
  workflowActionItemId: string;
  workflowRequestId: string;
  updatedAt: string;
  createdAt: string;
  text: string;
}

export interface SendWorkflowAnswerResponse {}

export enum Status {
  draft = 0,
  saved = 1,
  complete = 2,
  final = 3,
}

export interface Workflow {
  workflowRequest: {
    workflowRequestId: string;
    workflowTitle: string;
    workflowDescription: string;
    workflowActionRequests: {
      workflowActionRequest: WorkflowActionRequest;
      workflowActionItem: WorkflowActionItem;
      workflowActionItemResponse: WorkflowAIR;
    }[];
  };
  status: Status;
}

export interface QuestionResponseData {
  content: string;
  questionId: string;
}

// we will have additional response data types in the future
export type ResponseData = QuestionResponseData;
export interface WorkflowAIR {
  question_id: number;
  question_text: string;
  response_id: number;
  response_text: string;
  created_at: string;
}

export interface WorkflowAnswerTable {
  response: string;
}

export interface WorkflowForm {
  workflowRequestId: string;
  [key: string]: string | number | boolean | null;
}

export enum ResponseType {
  Boolean = 'answer_boolean',
  Text = 'answer_text',
  Upload = 'uploaded_document',
}

export enum WorkflowItemType {
  Boolean = 'WfiAnswerBooleanQuestion',
  Text = 'WfiAnswerTextQuestion',
  Upload = 'WfiUploadDocument',
}
