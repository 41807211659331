import { createContext, useMemo } from 'react';

import { WebsocketServiceInstance, websocket } from './WebsocketService';

export interface WebsocketContextType {
  websocket?: WebsocketServiceInstance;
}

export const WebsocketContext = createContext<WebsocketContextType>({});

/**
 * Note: This context is kind of moot since our websocket service
 * now only exposes a single instance, but this will still likely
 * prove useful for keeping things contained within React.
 */
const WebsocketProvider = ({ children }: { children?: React.ReactNode }) => {
  const ctx = useMemo(() => ({ websocket }), []);
  return (
    <WebsocketContext.Provider value={ctx}>
      {children}
    </WebsocketContext.Provider>
  );
};

export default WebsocketProvider;
