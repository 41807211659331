import { NavMenuItem } from '@liscio/common';
import { Box, Icon, List, useViewportSize } from '@liscio/ui';

import { UserProfileNavMenuItem } from './UserProfileNavMenuItem';
import SwitchCpaButton from 'components/SwitchCpaButton/SwitchCpaButton';

export const SecondaryNavigationMenu = () => {
  const { isMobile } = useViewportSize();
  return (
    <Box pb={2}>
      {!isMobile && <SwitchCpaButton />}
      <List>
        <UserProfileNavMenuItem />
        {/* Mobile Logout */}
        {isMobile && (
          <NavMenuItem
            icon={<Icon icon="logout" />}
            label="Logout"
            to="/authenticate/logout"
          />
        )}
      </List>
    </Box>
  );
};
