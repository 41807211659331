import { useContext, useState } from 'react';

import { FormControl, FormLabel } from '@mui/material';
import { useTheme, Box, Typography } from '@liscio/ui';

import {
  WorkflowRequestItemFragment,
  WorkflowResponseItem,
  WorkflowResponseItemFragment,
} from '@liscio/api/graphql';

import {
  composeConditionalItem,
  composeRequestItem,
} from './displayItemComposer';
import { WorkflowViewContext } from '../../context/WorkflowViewContext';
import { RequestItemBoolean } from '../RequestItemBoolean/RequestItemBoolean';
import { RequestItemText } from '../RequestItemText/RequestItemText';
import { RequestItemUpload } from '../RequestItemUpload/RequestItemUpload';
import { ResponseType } from 'fetch-utils/workflow/workflows.types';

export interface RequestItemDisplayProps {
  prompt?: string;
  responseItemId: string;
  notApplicable: boolean;
}

interface RequestItemProps {
  requestItem: WorkflowRequestItemFragment;
  responseItem?: WorkflowResponseItemFragment | null;
  index: number;
}

export function RequestItem({
  requestItem,
  responseItem,
  index,
}: RequestItemProps) {
  const theme = useTheme();
  const { conditionalQuestionsMap } = useContext(WorkflowViewContext);
  const [radioValue, setRadioValue] = useState(
    requestItem?.responseItem?.booleanValue
  );

  const responseItemId = responseItem?.id;

  let itemContent: React.ReactElement | null = null;
  let conditionalItems: WorkflowRequestItemFragment[] = [];

  // determine whether the current RequestItem has a related conditional question
  const hasConditionalQuestion = Object.keys(
    conditionalQuestionsMap || []
  ).includes(responseItem?.workflowItem?.id);

  // if there is a conditional question prepare it for rendering (just like we
  // would a standard request item)
  // ONLY works for boolean response types
  if (hasConditionalQuestion) {
    const itemMatchValue =
      conditionalQuestionsMap![responseItem?.workflowItem?.id].matchValue;

    const matchValueToBoolOrNull = () => {
      if (itemMatchValue === 'true') return true;
      if (itemMatchValue === 'false' || itemMatchValue === null) return false;
      // intentionally returning null in case matchValue is not set
      return null;
    };

    const matchValue: boolean | null = matchValueToBoolOrNull();

    if (matchValue === radioValue) {
      conditionalItems =
        conditionalQuestionsMap![responseItem?.workflowItem?.id].requestItems;
    }
  }

  itemContent = composeRequestItem({
    item: { ...requestItem, id: responseItemId },
    onUpdate: (val) => setRadioValue(val),
  });

  switch (requestItem.responseType) {
    case ResponseType.Boolean:
      itemContent = (
        <RequestItemBoolean
          prompt={requestItem.prompt}
          responseItemId={responseItemId}
          value={requestItem?.responseItem?.booleanValue}
          notApplicable={requestItem?.responseItem?.notApplicable || false}
          onUpdate={(val) => setRadioValue(val)}
        />
      );
      break;
    case ResponseType.Upload:
      itemContent = (
        <RequestItemUpload
          responseItemId={responseItem?.id}
          value={
            requestItem?.responseItem
              ?.liscioObjects as WorkflowResponseItem['liscioObjects']
          }
          notApplicable={requestItem?.responseItem?.notApplicable || false}
        />
      );
      break;
    case ResponseType.Text:
      itemContent = (
        <RequestItemText
          prompt={requestItem.prompt}
          responseItemId={responseItemId}
          value={requestItem.responseItem?.textValue}
          notApplicable={requestItem?.responseItem?.notApplicable || false}
        />
      );
      break;
    default:
      return null;
  }

  return (
    <>
      <FormControl>
        <FormLabel>
          <Box
            display="flex"
            paddingBottom="12px"
            color={theme.palette.text.primary}
          >
            <Typography fontWeight="bold" paddingLeft="5px" paddingRight="5px">
              {index}.
            </Typography>
            <Typography variant="body1">{requestItem.prompt}</Typography>
          </Box>
        </FormLabel>
        {itemContent}
      </FormControl>
      {hasConditionalQuestion &&
        conditionalItems &&
        conditionalItems.map((item: any, itemIndex: number) => (
          <FormControl key={item.id}>
            <FormLabel>
              <Box
                display="flex"
                paddingBottom="12px"
                color={theme.palette.text.primary}
              >
                <Typography
                  fontWeight="bold"
                  paddingLeft="5px"
                  paddingRight="5px"
                >
                  {index}.{itemIndex + 1}
                </Typography>
                <Typography variant="body1">{item.prompt}</Typography>
              </Box>
            </FormLabel>
            {composeConditionalItem(item)}
          </FormControl>
        ))}
    </>
  );
}
