import React, { useRef, useEffect, useCallback, useState } from 'react';

import { IOSStyle } from './components';

export interface PullToRefreshProps {
  bodyNodeRef: React.RefObject<HTMLElement | null | undefined>;
  reloadFunction: () => void;
}

const RELOAD_THRESHOLD = 100;
const MARGIN = 20;

export const PullToRefresh: React.FC<PullToRefreshProps> = ({
  bodyNodeRef,
  reloadFunction,
}) => {
  const [top, _setTop] = useState<number>(0);
  const topRef = useRef(top);
  const setTop = (newValue: number) => {
    _setTop(newValue);
    topRef.current = newValue;
  };
  const touchstartYRef = useRef(0);
  const touchstartScrollPositionRef = useRef(0);

  const onTouchStart = useCallback(
    (e: TouchEvent) => {
      touchstartYRef.current = e.touches[0].clientY;
      touchstartScrollPositionRef.current =
        bodyNodeRef?.current?.scrollTop || 0;
    },
    [bodyNodeRef]
  );

  const onTouchMove = useCallback(
    (e: TouchEvent) => {
      const scrollTop = bodyNodeRef?.current?.scrollTop || 0;
      const touchY = e.touches[0].clientY;
      const touchDiff =
        touchY - touchstartYRef.current - touchstartScrollPositionRef.current;
      if (touchDiff > 0 && scrollTop === 0) {
        if (touchDiff < RELOAD_THRESHOLD + MARGIN) {
          setTop(Math.floor(touchDiff));
        }
      }
      e.stopPropagation();
    },
    [bodyNodeRef]
  );

  const onTouchEnd = useCallback(() => {
    if (topRef.current >= RELOAD_THRESHOLD) {
      reloadFunction();
    }
    setTop(0);
    touchstartYRef.current = 0;
    touchstartScrollPositionRef.current = 0;
  }, [reloadFunction]);

  useEffect(() => {
    const refCopy = bodyNodeRef.current;
    if (refCopy) {
      refCopy.addEventListener('touchstart', onTouchStart);
      refCopy.addEventListener('touchmove', onTouchMove);
      refCopy.addEventListener('touchend', onTouchEnd);
    }

    return () => {
      if (refCopy) {
        refCopy.removeEventListener('touchstart', onTouchStart);
        refCopy.removeEventListener('touchmove', onTouchMove);
        refCopy.removeEventListener('touchend', onTouchEnd);
      }
    };
  }, [bodyNodeRef, onTouchEnd, onTouchMove, onTouchStart]);

  return <IOSStyle touchDiff={top} reloadThreshold={RELOAD_THRESHOLD} />;
  // I leave it in case we want to use Android someday or introduce conditional component
  // return <AndroidStyle touchDiff={top} />;
};

export default PullToRefresh;
