import React, { useState, useCallback } from 'react';

import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

import {
  PaymentMethods,
  ClosePaymentDialogReasons,
  TaskDetails,
} from '@liscio/api';
import { Button, Stack, Typography } from '@liscio/ui';
import { parseAmount } from '@liscio/utils';

import { calculateSelectPaymentMethodState } from './helpers';
import { RadioInline } from 'components';
import { Label, Row } from 'components/detailedInformation';
import { PayInvoiceHandlerStates } from 'modules/invoices/components/PayInvoiceHandlerV2/PayInvoiceHandlerV2';

export interface SelectPaymentMethodStateProps {
  invoice: void | TaskDetails | undefined;
  setCurrentState: React.Dispatch<
    React.SetStateAction<PayInvoiceHandlerStates>
  >;
  closeFunction(closeReason: ClosePaymentDialogReasons): void;
}

export const SelectPaymentMethodState: React.FC<
  SelectPaymentMethodStateProps
> = ({ invoice, setCurrentState, closeFunction }) => {
  const [choice, setChoice] = useState<PaymentMethods | null>(null);

  const invoiceNumber = invoice?.invoice_number;
  const isRecurring = invoice?.is_recurring;
  const amount = invoice?.invoice_amount;

  const handleChoice = useCallback(() => {
    const newState = calculateSelectPaymentMethodState({ invoice, choice });
    setCurrentState(newState);
  }, [choice, invoice, setCurrentState]);

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        Select your preferred payment method:
      </DialogTitle>
      <DialogContent>
        <Stack gap="8px">
          {!isRecurring && (
            <Row>
              <Label>Invoice No:</Label>
              <Typography>{invoiceNumber}</Typography>
            </Row>
          )}
          <Row>
            <Label>Amount:</Label>
            <Typography>{parseAmount(amount || '')}</Typography>
          </Row>
        </Stack>
        <Stack direction="row" mt="8px" gap="8px">
          <RadioInline
            label="ACH"
            onChange={() => setChoice(PaymentMethods.ACH)}
            checked={choice === PaymentMethods.ACH}
          />
          <RadioInline
            label="Credit"
            onChange={() => setChoice(PaymentMethods.CREDIT_CARD)}
            checked={choice === PaymentMethods.CREDIT_CARD}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            closeFunction(ClosePaymentDialogReasons.CANCEL);
          }}
          disabled={false}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleChoice}
          autoFocus
          disabled={!choice}
        >
          Continue
        </Button>
      </DialogActions>
    </>
  );
};

export default SelectPaymentMethodState;
