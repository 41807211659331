import { useCallback, useState } from 'react';

import { Capacitor } from '@capacitor/core';
import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { useDropzone } from 'react-dropzone';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Box, GlobalLoader } from '@liscio/ui';

import { BrowserScannerOverlay, VideoAccessErrorOverlay } from './components';
import useBrowserScanner from './components/document-scanner-hooks/useBrowserScanner';

interface ScannerAndInputProps {
  disabled: boolean;
}

const Wrapper = styled(Box)(() => ({
  width: '50%',
  margin: '0 auto',
  textAlign: 'center',
}));

export const MarginBox = styled(Box)(() => ({
  margin: '25px 0px 25px 0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const InputBox = styled(MarginBox)(({ theme }) => ({
  width: '100%',
  padding: '8px',
  fontSize: theme?.components?.MuiButton.styleOverrides.sizeMedium.fontSize,
  border: `solid ${theme.palette.common.blueGray[300]} 2px`,
  color: theme.palette.common.cyan[500],
  fontWeight: 700,
}));

const ScannerBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showScanner',
})(({ showScanner, theme }: { showScanner: boolean; theme?: Theme }) => ({
  height: '100vh',
  width: '100vw',
  position: 'fixed',
  left: 0,
  bottom: 0,
  zIndex: showScanner ? theme!.zIndex.drawer + 2 : -10,
  opacity: showScanner ? '1' : '0',
}));

export const CONTAINER_ID = 'document-scanner-view';

const ScannerAndInput: React.FC<ScannerAndInputProps> = ({ disabled }) => {
  const [isCloningFiles, setIsCloningFiles] = useState(false);
  const {
    handleOpenScannerClick,
    handleCancelClick,
    handleConfirmClick,
    showScanner,
    initializingCamera,
    newPagePreview,
    pages,
    hasVideoAccessError,
    revokeVideoAccessError,
  } = useBrowserScanner(CONTAINER_ID);

  const { setValue } = useFormContext();
  const { append: addFilesToForm } = useFieldArray({ name: 'files' });

  const handleFileSelection = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        setIsCloningFiles(true);
        // When using a file from google drive, it seems we must clone it _immediately_,
        // otherwise the delay between the user selection and upload
        // request disturbs chrome, per https://www.reddit.com/r/learnjavascript/ . . .
        // . . . comments/16hgnzq/neterr_upload_file_changed_even_after_recreating/
        const clonedFiles = acceptedFiles.map(async (file) => {
          if (typeof file.arrayBuffer === 'function') {
            const buffer = await file.arrayBuffer();
            const clone = new File([buffer], file.name, { type: file.type });

            return clone;
          }

          return file;
        });
        const clones = await Promise.all(clonedFiles);
        setIsCloningFiles(false);
        addFilesToForm([...clones]);
      }
    },
    [addFilesToForm]
  );

  function handleClearClick() {
    setValue('files', []);
  }

  const derivedDisabled = disabled || initializingCamera || showScanner;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileSelection,
  });

  return (
    <Wrapper>
      <GlobalLoader open={Boolean(initializingCamera || isCloningFiles)} />
      <InputBox {...getRootProps()} role="button">
        Browse Files for Upload
        <input
          data-testid="file-upload-input"
          {...getInputProps()}
          disabled={derivedDisabled}
        />
      </InputBox>
      {!Capacitor.isNativePlatform() && (
        <MarginBox>
          <Button
            variant="outlined"
            onClick={handleOpenScannerClick}
            disabled={disabled}
          >
            Open Document Scanner
          </Button>
        </MarginBox>
      )}
      <Button onClick={handleClearClick}>Clear Files</Button>
      <BrowserScannerOverlay
        showOverlay={Boolean(!initializingCamera) && Boolean(showScanner)}
        handleCancelClick={handleCancelClick}
        handleConfirmClick={handleConfirmClick}
        howManyPages={pages.length}
        newPagePreview={newPagePreview}
      />
      <ScannerBox showScanner={Boolean(showScanner)} id={CONTAINER_ID} />
      <VideoAccessErrorOverlay
        hasVideoAccessError={hasVideoAccessError}
        revokeVideoAccessError={revokeVideoAccessError}
      />
    </Wrapper>
  );
};

export default ScannerAndInput;
