import { useMemo } from 'react';

import { BiometryType } from '@capgo/capacitor-native-biometric';

import { useAuthV5ProcessContext } from '@liscio/common';
import { Icon, InputAdornment, IconButton, FaceIDIcon } from '@liscio/ui';

export const BiometricsAdornment = () => {
  const { isBiometricLoginEnabled, biometryType, biometricLogin } =
    useAuthV5ProcessContext();

  const biometricIcon = useMemo(() => {
    switch (biometryType) {
      case BiometryType.FACE_ID:
      case BiometryType.FACE_AUTHENTICATION:
        return <FaceIDIcon fontSize="large" />;
      case BiometryType.TOUCH_ID:
      case BiometryType.FINGERPRINT:
      case BiometryType.MULTIPLE:
        return <Icon icon="fingerprint" fontSize="large" />;
    }
  }, [biometryType]);

  if (!isBiometricLoginEnabled) {
    return undefined;
  }

  return (
    <InputAdornment position="end" style={{ color: 'inherit', marginRight: 0 }}>
      <IconButton onClick={biometricLogin} edge="end" color="inherit">
        {biometricIcon}
      </IconButton>
    </InputAdornment>
  );
};

export default BiometricsAdornment;
