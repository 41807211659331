import React from 'react';

import { Interweave } from 'interweave';

import { BasicMessage } from '@liscio/api';
import { styled, Box, Typography } from '@liscio/ui';

import { getParticipants } from './helpers';

export interface PrimaryProps {
  message: BasicMessage;
  shouldDisplayAsUnread: boolean;
}

const Date = styled(Typography)(({ theme }) => ({
  flexShrink: 0,
  fontSize: '13px',
  color: theme.palette.common.blueGray[500],
}));

const Content = styled(Box)(({ theme }) => ({
  color: theme.palette.common.blueGray[500],
}));

export const Primary: React.FC<PrimaryProps> = ({
  message,
  shouldDisplayAsUnread,
}) => {
  const participants = getParticipants(message);
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" textAlign="start">
        <Typography variant={shouldDisplayAsUnread ? 'body2' : 'body1'}>
          {participants}
        </Typography>
        <Date>{message.tym}</Date>
      </Box>
      <Box>
        <Content>{message.title}</Content>
      </Box>
      <Content>
        <Interweave
          content={
            message.content_limit && message.content !== null
              ? message.content_limit
                  .replace(/html/gi, 'div')
                  .replace(/!doctype/gi, 'div></div')
              : ''
          }
        />
      </Content>
    </Box>
  );
};

export default Primary;
