import { UploaderType } from '@liscio/api';

export const UPLOADER_TYPE_OPTIONS = [
  { label: 'Uploaded by Client', value: UploaderType.Client },
  { label: 'Uploaded by Firm', value: UploaderType.Firm },
];

export const UPLOADER_TYPE_FIRM_OPTIONS = [
  { label: 'Uploaded by Client', value: UploaderType.Client },
  { label: 'Uploaded by Firm', value: UploaderType.Firm },
  { label: 'Firm Only Files', value: UploaderType.Private },
];

export default UPLOADER_TYPE_OPTIONS;
