import React from 'react';

import { Button, ButtonProps, styled, Icon } from '@liscio/ui';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderBottom: `solid 1px ${theme.palette.common.blueGray[200]}`,
}));

export const AddAttachmentButton: React.FC<ButtonProps> = (props) => {
  return (
    <StyledButton fullWidth {...props}>
      <Icon icon="attachFile" />
      Add Attachment
    </StyledButton>
  );
};

export default AddAttachmentButton;
