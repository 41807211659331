import { useCallback } from 'react';

import { NavMenuItem } from '@liscio/common';
import {
  Typography,
  useViewportSize,
  Menu,
  MenuItem,
  styled,
  useMenuAnchor,
  Avatar,
  Icon,
} from '@liscio/ui';

import { FirmAdminUserMenu } from './FirmAdminUserMenu';
import { useProfileData } from 'fetch-utils/users/user-hooks';

const StyledMenu = styled(Menu)({
  [`.MuiMenu-paper`]: {
    minWidth: '200px',
  },
  [`.MuiMenuItem-root`]: {
    padding: '16px',
  },
});
const Username = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  width: '132px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('md')]: {
    width: '152px',
  },
}));

/**
 * User Profile secondary nav item.
 * Provides client user with secondary nav menu with the following items in desktop:
 * - Profile
 * - Logout
 * Direct link to profile page in mobile
 */
export const UserProfileNavMenuItem = () => {
  const { avatar, name, first_name, last_name, isFirmAdmin } = useProfileData();
  const initials = `${first_name?.[0] || last_name?.[0]}`;

  const { isMobile } = useViewportSize();
  const { anchorEl, open, onOpen, onClose } = useMenuAnchor();
  const onMenuClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!isMobile) {
        // Only open the menu on non-mobile viewports
        onOpen(event);
      }
    },
    [isMobile, onOpen]
  );

  return (
    <>
      <NavMenuItem
        id="user-menu-button"
        onClick={onMenuClick}
        icon={
          <Avatar
            sx={{ width: '24px', height: '24px', border: 'none' }}
            alt={name}
            src={avatar}
          >
            {initials}
          </Avatar>
        }
        to={isMobile ? '/profile/details' : undefined}
        label={<Username>{name}</Username>}
      />
      <StyledMenu
        id="user-menu"
        aria-labelledby="user-menu-button"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {isFirmAdmin && <FirmAdminUserMenu open={open} />}
        <MenuItem
          to="/profile/details"
          selected={false}
          onClick={onClose}
          iconBefore={
            <Avatar
              sx={{ width: '24px', height: '24px', border: 'none' }}
              alt={name}
              src={avatar}
            >
              {initials}
            </Avatar>
          }
          label="Profile"
        />
        <MenuItem
          to="/authenticate/logout"
          iconBefore={<Icon icon="logout" />}
          selected={false}
          onClick={onClose}
          label="Logout"
        />
      </StyledMenu>
    </>
  );
};
