import React, { useCallback, useState } from 'react';

import { Link } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Box, Button, GlobalLoader, Stack, Typography } from '@liscio/ui';

import { ConfirmMfaFormData, mfaFormValidationSchema } from './helpers';
import { RequestEmailedCode } from './RequestEmailedCode';
import { ErrorDialog } from 'components/ErrorDialog';
import { useYupValidationResolver } from 'custom-hooks';
import { AuthTextField } from 'modules/authenticate/components/FormFields';

export interface ConfirmMfaFormProps {
  email?: string;
  loginWithPasscode: (passcode: string) => void;
  onBackToLogin?: () => void;
  error?: any;
}

/**
 * Form to handle verifying 2 factor auth code from Authenticator
 */
export const ConfirmMfaForm = ({
  email,
  loginWithPasscode,
  onBackToLogin,
  error: passedError,
}: ConfirmMfaFormProps) => {
  const {
    mutateAsync,
    isLoading,
    error: mutateError,
  } = useMutation({
    mutationFn: async (passcode: string) => loginWithPasscode(passcode),
  });
  const error = passedError || mutateError;

  const validate = useYupValidationResolver(mfaFormValidationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfirmMfaFormData>({
    resolver: validate,
  });

  const onSubmit: SubmitHandler<ConfirmMfaFormData> = useCallback(
    async (data) => {
      await mutateAsync(data.passcode);
    },
    [mutateAsync]
  );

  // Request mfa code emailed
  const [needsHelp, setNeedsHelp] = useState(false);
  const openNeedsHelp = useCallback(() => setNeedsHelp(true), [setNeedsHelp]);
  const closeNeedsHelp = useCallback(() => setNeedsHelp(false), [setNeedsHelp]);

  if (needsHelp) {
    return <RequestEmailedCode onClose={closeNeedsHelp} email={email} />;
  }

  return (
    <>
      <Typography variant="h4" textAlign="center" mb={4}>
        Enter Passcode
      </Typography>
      <Typography variant="body1" textAlign="center" mb={4}>
        Enter the code on your mobile passcode application
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
          <AuthTextField
            {...register('passcode')}
            fullWidth
            placeholder="Enter Passcode"
            error={Boolean(errors.passcode)}
            helperText={errors?.passcode?.message}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={isLoading}
            size="large"
          >
            Verify Code
          </Button>
        </Stack>
      </form>
      <ErrorDialog title="Verification Error" error={error} />
      <GlobalLoader open={isLoading} />
      {email && (
        <Box mt={8}>
          <Typography variant="body1" textAlign="center" mb={4}>
            <Link onClick={openNeedsHelp}>Need help logging in?</Link>
          </Typography>
        </Box>
      )}
      <Box mt={4}>
        <Typography variant="body1" textAlign="center" mb={4}>
          Back to <Link onClick={onBackToLogin}>Login</Link>
        </Typography>
      </Box>
    </>
  );
};
