import { useQuery } from '@tanstack/react-query';

import apiClient from 'fetch-utils/api-client';

const { getWouldBeTaskOwners } = apiClient.owners;

export const useTaskOwners = () => {
  return useQuery({
    queryKey: ['taskOwners'],
    queryFn: getWouldBeTaskOwners,
  });
};
