import { BasicMessage } from '@liscio/api';

export const getParticipants: (message: BasicMessage) => string = (message) => {
  if (message.all_senders && message.all_senders.length > 0) {
    return message.all_senders.join(', ');
  }
  if (message.participants.length > 0) {
    return message.participants
      .map((participant) => participant?.contact_name)
      .join(', ');
  }
  if (message.sender) {
    return message.sender;
  }
  return '';
};

export default getParticipants;
