export const CONTACTS_DOMAIN = 'contacts';
export const CONTACTS_DOMAIN_URL = `/${CONTACTS_DOMAIN}/*`;

export const CONTACTS_PATHS = {
  list: '/list',
  details: {
    root: '/details',
    tabs: {
      overview: 'overview',
      files: 'files',
      accounts: 'accounts',
      tasks: 'tasks',
      messages: 'messages',
    },
  },
};

export const CONTACTS_PAGE_TITLES = {
  list: 'Contacts',
  details: 'Contact Details',
  tasks: 'Contact Tasks',
};
