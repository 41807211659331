import React from 'react';

import { styled, Box, Typography } from '@liscio/ui';
import { Helmet } from 'react-helmet-async';

import { ForgotPasswordForm } from './components';
import { Copyright, Logo } from '../LoginView/components';
import { Link } from 'components';
import { AUTHENTICATE_PAGE_TITLES } from 'modules/authenticate/route-constants';

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.common.white,
  paddingTop: '48px',
  paddingBottom: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const ForgotPasswordView = () => {
  return (
    <>
      <Helmet title={AUTHENTICATE_PAGE_TITLES.forgotPassword} />
      <Wrapper>
        <Logo />
        <Box>
          <Typography variant="h4" textAlign="center" mb={4}>
            Forgot Password?
          </Typography>
          <ForgotPasswordForm />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Link
            to="/authenticate/login"
            underline="none"
            color={(theme) => theme.palette.text.secondary}
          >
            Back to signin
          </Link>
        </Box>
        <Copyright />
      </Wrapper>
    </>
  );
};
