import React from 'react';

import { Typography, styled } from '@liscio/ui';

import { AppHeaderWrapper } from './AppHeaderWrapper';

export interface AppHeaderProps {
  title: string;
  hideBorderBottom?: boolean;
}

const Title = styled(Typography)({
  fontSize: '18px',
  fontWeight: '600',
  lineHeight: '24px',
});

export const AppHeader: React.FC<AppHeaderProps> = ({
  title,
  hideBorderBottom,
}) => {
  return (
    <AppHeaderWrapper hideBorderBottom={hideBorderBottom}>
      <Title>{title}</Title>
    </AppHeaderWrapper>
  );
};

export default AppHeader;
