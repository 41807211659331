import React from 'react';

import { OAuthConfig as OAuthConfigInterface } from '@liscio/api';
import { useOAuthV5Process } from '@liscio/common';
import { Button, styled, useTheme, GoogleGIcon } from '@liscio/ui';

import { config } from 'config';

const PROVIDER = 'google';

const GoogleButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.brandColors.white,
  color: '#4285f4',
  border: '1px solid #4285f4',
}));

export const GoogleOAuthV5Login = () => {
  const oAuthConfig: OAuthConfigInterface = config.oauth[PROVIDER];

  const theme = useTheme();
  const { initiateOAuth } = useOAuthV5Process({
    provider: PROVIDER,
    oAuthConfig,
  });

  return (
    <GoogleButton
      type="button"
      onClick={initiateOAuth}
      variant="contained"
      sx={{
        backgroundColor: theme.palette.common.white,
      }}
      startIcon={<GoogleGIcon fontSize="small" />}
      size="large"
    >
      Login with Google
    </GoogleButton>
  );
};
