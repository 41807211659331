import React from 'react';

import { Chip } from '@liscio/ui';

interface TagListProps {
  tags?: string[];
}

export default function TagList({ tags }: TagListProps) {
  if (!tags || tags.length < 1) return null;

  return (
    <>
      {tags?.map((tag) => (
        <Chip key={tag} label={tag} />
      ))}
    </>
  );
}
