import React from 'react';

import { Skeleton } from '@mui/material';

import { DefaultElementProps, Slot, MainSlot } from '@liscio/common';
import { IconButton, Typography, Icon } from '@liscio/ui';

export const DefaultState: React.FC<DefaultElementProps> = ({
  title,
  onSecondaryActionClick,
  onNavigationClick,
  hideSecondaryActionButton,
}) => (
  <>
    <Slot>
      <IconButton
        aria-label="Back"
        size="large"
        onClick={() => onNavigationClick && onNavigationClick('go:back')}
      >
        <Icon icon="chevronLeft" />
      </IconButton>
    </Slot>
    <MainSlot>
      {title && <Typography variant="h2">{title}</Typography>}
      {!title && <Skeleton width={180} />}
    </MainSlot>
    <Slot>
      {!hideSecondaryActionButton && (
        <IconButton
          aria-label="Search"
          size="large"
          onClick={() => onNavigationClick && onNavigationClick('go:archive')}
        >
          <Icon icon="archive" fontSize="small" />
        </IconButton>
      )}
    </Slot>
  </>
);

export default DefaultState;
