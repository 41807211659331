export const FILES_DOMAIN = 'files';
export const FILES_DOMAIN_URL = `/${FILES_DOMAIN}/*`;

export const FILES_PATHS = {
  list: '/list',
  details: '/details',
  new: '/new',
};

export const FILES_PAGE_TITLES = {
  list: 'Files',
  details: 'File Details',
  new: 'Upload New File',
};
