import React from 'react';

import { useTheme, Icon, Stack, Typography } from '@liscio/ui';
import { pluralLabel } from '@liscio/utils';

export interface CommentsCounterProps {
  numberOfComments?: number | null;
}

export const CommentsCount: React.FC<CommentsCounterProps> = ({
  numberOfComments,
}) => {
  const theme = useTheme();
  const label = pluralLabel(
    numberOfComments || 0,
    'NEW COMMENT',
    'NEW COMMENTS'
  );
  return (
    <Stack direction="row" spacing={2} alignItems="center" pt={1}>
      <Icon
        icon="comment"
        fontSize="small"
        htmlColor={theme.palette.grey[600]}
      />
      <Typography variant="caption">{`${numberOfComments} ${label}`}</Typography>
    </Stack>
  );
};

export default CommentsCount;
