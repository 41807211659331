import React from 'react';

import { Button, Typography, Icon } from '@liscio/ui';

import { Overlay } from '@liscio/common';
import {
  ScreenWrapper,
  BodyWrapper,
  FooterWrapper,
} from 'components/screenComposition';

export interface VideoAccessErrorOverlayProps {
  hasVideoAccessError?: boolean;
  revokeVideoAccessError?: () => void;
}

export const VideoAccessErrorOverlay: React.FC<
  VideoAccessErrorOverlayProps
> = ({ hasVideoAccessError, revokeVideoAccessError }) => {
  return (
    <Overlay open={hasVideoAccessError}>
      <ScreenWrapper>
        <BodyWrapper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '16px',
          }}
        >
          <Icon icon="videoSlash" sx={{ width: '100px', height: '100px' }} />
          <Typography variant="h2" sx={{ fontSize: '32px' }}>
            Allow Liscio App access to your camera.
          </Typography>
          <Typography>
            App needs access to your camera to scan documents
          </Typography>
        </BodyWrapper>
        <FooterWrapper>
          <Button
            variant="contained"
            onClick={revokeVideoAccessError}
            fullWidth
          >
            OK
          </Button>
        </FooterWrapper>
      </ScreenWrapper>
    </Overlay>
  );
};

export default VideoAccessErrorOverlay;
