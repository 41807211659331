import { ThreadResponse } from '@liscio/api';

export const hasUnreadMessage: (thread: ThreadResponse) => boolean = (
  thread
) => {
  if (typeof thread.message?.some === 'function')
    return thread.message?.some((message) => !message.read);

  return false;
};

export default hasUnreadMessage;
