import { Chip as MUIChip } from '@mui/material';

import { styled } from '@liscio/ui';

const Chip = styled(MUIChip)({
  padding: '4px 0',
  margin: '4px',
  height: 'auto',
  label: {
    padding: '4px',
  },
});

export default Chip;
