import React from 'react';

import { useController, Control } from 'react-hook-form';

import Checkbox, { CheckboxProps } from './Checkbox';

export interface RHFCheckboxProps extends CheckboxProps {
  name: string;
  control: Control<any>;
}

/**
 * react-hook-form ready checkbox
 * NOTE: If we want to take advantage of RHF error focusing
 * we'll need to forwardRef's down the chain to the mui checkbox
 */
export const RHFCheckbox: React.FC<RHFCheckboxProps> = ({
  control,
  name,
  ...rest
}) => {
  const { field } = useController({ name, control });

  return (
    <Checkbox
      {...rest}
      name={name}
      value={field.value || false}
      checked={field.value || false}
      onChange={field.onChange}
    />
  );
};
