import { UploaderType } from '@liscio/api';

import {
  UPLOADER_TYPE_OPTIONS,
  UPLOADER_TYPE_FIRM_OPTIONS,
} from 'constants/uploaderTypeOptions';

export const getUploaderTypeOptions: (props: {
  isFirmUser: boolean;
  isLoading: boolean;
}) => Array<{
  label: string;
  value: UploaderType;
}> = ({ isFirmUser, isLoading }) => {
  if (isLoading) {
    return [];
  }

  if (isFirmUser) {
    return UPLOADER_TYPE_FIRM_OPTIONS;
  }

  return UPLOADER_TYPE_OPTIONS;
};

export default getUploaderTypeOptions;
