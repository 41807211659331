import React, { ReactNode } from 'react';

import { styled, Stack } from '@liscio/ui';

import { CstMemberEntity } from '@liscio/api';

import { UserInfo } from 'components';

const StyledStack = styled(Stack)({
  padding: '15px',
  textAlign: 'right',
});

function isCstMemberEntity(
  value: number | string | void | object | ReactNode
): value is CstMemberEntity[] {
  if (!Array.isArray(value)) {
    return false;
  }

  if (value.length === 0) {
    return true;
  }

  if (value[0]?.hasOwnProperty('label') && value[0]?.hasOwnProperty('avatar')) {
    return true;
  }
  return true;
}

const usersCollectionRenderer: (keyValues: {
  [key: string]: number | string | void | object | ReactNode;
}) => ReactNode = (keyValues) => {
  const { users } = keyValues;

  if (!isCstMemberEntity(users)) {
    console.error('Wrong renderer, null will be returned');
    return null;
  }

  return (
    <StyledStack direction="row" flexWrap="wrap" gap={3}>
      {users.map((user) => (
        <UserInfo
          key={user.value}
          initials={user.initials}
          name={user.label}
          avatar={user.avatar}
        />
      ))}
    </StyledStack>
  );
};

export default usersCollectionRenderer;
