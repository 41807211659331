import React, { ReactNode } from 'react';

import { styled, Stack } from '@liscio/ui';

import { KeyValue } from '../components';

const KeyValueStack = styled(Stack)({
  padding: '15px',
});

const defaultRenderer: (keyValues: {
  [key: string]: number | string | void | object | ReactNode;
}) => ReactNode = (keyValues) => (
  <KeyValueStack direction="column" spacing={5}>
    {Object.entries(keyValues).map(([key, value]) => {
      if (
        value !== null &&
        typeof value === 'object' &&
        !React.isValidElement(value)
      ) {
        console.error('Wrong renderer, null will be returned');
        return null;
      }
      return <KeyValue key={key} itemKey={key} value={value} />;
    })}
  </KeyValueStack>
);

export default defaultRenderer;
