import React from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { GlobalLoader } from '@liscio/ui';

import {
  useHomeData,
  useTermsAndConditions,
} from 'fetch-utils/users/user-hooks';
import {
  AUTHENTICATE_DOMAIN,
  AUTHENTICATE_PATHS,
} from 'modules/authenticate/route-constants';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from 'modules/dashboard/route-constants';
import { PROFILE_DOMAIN, PROFILE_PATHS } from 'modules/profile/route-constants';

export type AcceptedTermsAndConditionsRouteProps = {};

/**
 * AcceptedTermsAndConditionsRoute allows for inspecting terms & conditions acceptance state
 * and redirecting as necessary.
 */
export const AcceptedTermsAndConditionsRoute: React.FC<
  AcceptedTermsAndConditionsRouteProps
> = () => {
  const location = useLocation();
  const { data: homeData, isLoading: isLoadingHomeData } = useHomeData();
  const hasAgreedToTerm = homeData?.data?.has_agreed_to_term?.has_agreed;
  const acceptedTermVersion =
    homeData?.data?.has_agreed_to_term?.agreed_terms_version;

  const {
    data: termsAndConditions,
    isLoading: isLoadingTermsAndConditions,
    error,
  } = useTermsAndConditions();
  const hasTermsAndConditionsLoadingError = Boolean(error);

  if (isLoadingHomeData) {
    return <GlobalLoader open />;
  }

  // The user is using the app and has not accepted T&C
  if (
    (hasAgreedToTerm === false ||
      (!isLoadingTermsAndConditions &&
        !hasTermsAndConditionsLoadingError &&
        termsAndConditions?.data?.version_number !== acceptedTermVersion)) &&
    location.pathname !==
      `/${PROFILE_DOMAIN}${PROFILE_PATHS.termsAndConditionsAcceptance}`
  ) {
    return (
      <Navigate
        to={`/${PROFILE_DOMAIN}${PROFILE_PATHS.termsAndConditionsAcceptance}`}
        replace
      />
    );
  }

  // The user has accepted T&C and is using the accept T&C page
  if (
    hasAgreedToTerm &&
    !isLoadingTermsAndConditions &&
    !hasTermsAndConditionsLoadingError &&
    termsAndConditions?.data?.version_number === acceptedTermVersion &&
    location.pathname ===
      `/${PROFILE_DOMAIN}${PROFILE_PATHS.termsAndConditionsAcceptance}`
  ) {
    return (
      <Navigate to={`/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`} replace />
    );
  }

  // If we can't load the terms and conditions we should logout the user
  if (hasTermsAndConditionsLoadingError) {
    return (
      <Navigate to={`/${AUTHENTICATE_DOMAIN}${AUTHENTICATE_PATHS.logout}`} />
    );
  }

  // Render Route
  return <Outlet />;
};

export default AcceptedTermsAndConditionsRoute;
