import { useContext, useEffect } from 'react';

import {
  AppBar,
  FormControlLabel,
  LinearProgress,
  Switch,
  Toolbar,
} from '@mui/material';

import {
  Box,
  Button,
  Divider,
  Icon,
  Stack,
  Typography,
  useTheme,
} from '@liscio/ui';

import { useLocation, useNavigate } from 'react-router-dom';

import { WorkflowRequestFragment } from '@liscio/api/graphql';
import { useRequestItemNav } from '@liscio/common';

import { WorkflowViewContext } from '../../context/WorkflowViewContext';
import { createConditionalHashMap } from '../../utils/conditionalUtils';
import { RequestItem } from '../RequestItem/RequestItem';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from 'modules/dashboard/route-constants';

type ActionListProps = {
  workflowRequest: WorkflowRequestFragment;
  canSubmit?: boolean;
  onNextClicked?: () => void;
  onSubmit?: () => void;
  refetchWorkflowData?: () => void;
};

export const ActionList = ({
  workflowRequest,
  canSubmit = true,
  onNextClicked,
  onSubmit,
  refetchWorkflowData = () => {}, // noop if not provided
}: ActionListProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    currentItem,
    currentIndex,
    startingIndex,
    endingIndex,
    next,
    prev,
    setShowUnansweredOnly,
  } = useRequestItemNav(workflowRequest);
  const { setConditionalQuestionsMap, isDirty } =
    useContext(WorkflowViewContext);
  const location = useLocation();

  // create a hash of any conditional questions and add to the context
  useEffect(() => {
    if (workflowRequest) {
      const conditionalQuestions = createConditionalHashMap(workflowRequest);
      setConditionalQuestionsMap(conditionalQuestions);
    }
  }, [workflowRequest, setConditionalQuestionsMap]);

  // exit if there is no workflowRequest
  if (!workflowRequest) return null;

  // set the count for linear progess and account for any conditional questions
  const requestCount =
    workflowRequest?.requestSections?.reduce(
      (requestCount, section) =>
        requestCount +
        (section.requestItems?.length! -
          section?.requestItems!.filter(
            (item) => item.responseItem?.workflowItem?.conditions?.length! > 0
          ).length || 0),
      0
    ) || 0;

  const handleClickSaveAndExit = () => {
    // navigate complains when we pass a type of string | -1
    if (location.state) {
      navigate(-1);
    } else {
      navigate(`/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`);
    }
  };

  return (
    <>
      <Box display="flex" alignItems={'center'}>
        <LinearProgress
          variant="determinate"
          value={(currentIndex / requestCount) * 100}
          sx={{ flexGrow: 1 }}
        />
        <Box paddingLeft="10px">
          {currentIndex}/{requestCount}
        </Box>
      </Box>
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          control={
            <Switch onChange={(e) => setShowUnansweredOnly(e.target.checked)} />
          }
          disableTypography
          label="Show unanswered items only"
        />
        {!isDirty && (
          <>
            <Typography color="text.secondary" fontWeight={700}>
              Saved
            </Typography>
            <Icon icon="check" color="success" />
          </>
        )}
      </Stack>

      <Typography variant="h4" paddingTop="20px" paddingBottom="10px">
        {workflowRequest?.title}
      </Typography>
      <Typography variant="subtitle1" color={theme.palette.text.secondary}>
        {workflowRequest?.workflow?.description}
      </Typography>
      <Divider sx={{ paddingBottom: '20px' }} />
      <Box
        display="flex"
        justifyContent="center"
        flexDirection={'column'}
        marginBottom="70px"
        flexGrow={1}
      >
        {currentItem && (
          <RequestItem
            key={currentItem.item.id}
            requestItem={currentItem.item}
            index={currentItem.step}
          ></RequestItem>
        )}
      </Box>
      <AppBar
        position="fixed"
        sx={{
          top: 'auto',
          bottom: 0,
          borderTop: '1px solid rgba(0, 0, 0, 0.12);',
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            disabled={currentIndex === startingIndex}
            onClick={() => prev()}
            variant="outlined"
          >
            Previous
          </Button>
          <Stack direction="row" gap={1}>
            <Button onClick={handleClickSaveAndExit} variant="outlined">
              Save and Exit
            </Button>
            {currentIndex !== requestCount && (
              <Button
                disabled={currentIndex === endingIndex}
                onClick={() => {
                  refetchWorkflowData();
                  next();
                }}
                variant="contained"
              >
                Continue
              </Button>
            )}
            {currentIndex === requestCount && canSubmit && (
              <Button variant="contained" onClick={onSubmit}>
                Submit
              </Button>
            )}
            {currentIndex === requestCount && !canSubmit && onNextClicked && (
              <Button variant="contained" onClick={onNextClicked}>
                Next
              </Button>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
};
