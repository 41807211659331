import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useCpaStore } from '@liscio/common';

/**
 * Capture company_name query parameter and store to state
 */
export const COMPANY_NAME_PARAM = 'company_name';
export const useCaptureCompanyName = () => {
  const [searchParams] = useSearchParams();
  const companyNameParam = searchParams.get(COMPANY_NAME_PARAM);
  const setQueryCompany = useCpaStore(({ setQueryCompany }) => setQueryCompany);

  useEffect(() => {
    if (companyNameParam) {
      setQueryCompany(companyNameParam);
      // NOTE: We might want to store this in secure storage between sessions
      // We can revisit that when we get to custom firm theming
    }
  }, [companyNameParam, setQueryCompany]);
};
