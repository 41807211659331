import React from 'react';

import { Capacitor } from '@capacitor/core';

import { styled, Box } from '@liscio/ui';

import liscioLogoSrc from './assets/liscio_logo_medium.png';

export interface LogoProps {
  companyLogoUrl?: string | null;
}

const Wrapper = styled(Box)({
  paddingBottom: '24px',
  textAlign: 'center',
  img: {
    maxWidth: '80px',
    width: '80%',
  },
});

export const Logo: React.FC<LogoProps> = ({ companyLogoUrl }) => {
  const pt = Capacitor.getPlatform() === 'ios' ? '62px' : '24px';
  return (
    <Wrapper sx={{ pt }}>
      <img src={companyLogoUrl || liscioLogoSrc} alt="logo" />
    </Wrapper>
  );
};

export default Logo;
