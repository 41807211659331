import {
  INVOICES_DOMAIN,
  INVOICES_PATHS,
} from 'modules/invoices/route-constants';
import {
  MESSAGES_DOMAIN,
  MESSAGES_PATHS,
} from 'modules/messages/route-constants';
import {
  NOTIFICATIONS_DOMAIN,
  NOTIFICATIONS_PATHS,
} from 'modules/notifications/route-constants';
import { TASKS_DOMAIN, TASKS_PATHS } from 'modules/tasks/route-constants';
import { WORKFLOWS_PUBLIC_DOMAIN } from 'modules/workflow/route-constants';

export const getNotificationRedirectUrl = (
  type: string,
  elementId: number | string
) => {
  const normalizedType = type.toLowerCase();

  if (normalizedType === 'bill') {
    return `/${INVOICES_DOMAIN}${INVOICES_PATHS.details}/${elementId}`;
  }

  if (normalizedType === 'message') {
    return `/${MESSAGES_DOMAIN}${MESSAGES_PATHS.details}/${elementId}`;
  }

  if (normalizedType === 'task') {
    return `/${TASKS_DOMAIN}${TASKS_PATHS.details}/${elementId}`;
  }

  if (normalizedType === 'request') {
    return `/${WORKFLOWS_PUBLIC_DOMAIN}/${elementId}`;
  }

  return `/${NOTIFICATIONS_DOMAIN}${NOTIFICATIONS_PATHS.list}`;
};

export default getNotificationRedirectUrl;
