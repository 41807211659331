import { useMemo, useState } from 'react';

// helper to create RichOptions from strings or numbers
export const enrichOptions = (options: (string | number)[]): RichOption[] =>
  options.map((option) => ({ label: String(option), value: option }));

/**
 * Helper for managing select option search filtering
 */
export const defaultFilterFunc = <T extends RichOption = RichOption>(
  search: string,
  options: T[]
) =>
  options.filter((option) =>
    String(option.label).toLowerCase().includes(search.toLowerCase())
  );
export const useSelectSearchFilter = <T extends RichOption = RichOption>(
  options: T[],
  filterFn: (search: string, options: T[]) => T[] = defaultFilterFunc
) => {
  const [searchString, onSearchChange] = useState('');
  const filteredOptions = useMemo(
    () => filterFn(searchString, options),
    [filterFn, searchString, options]
  );

  return {
    searchString,
    onSearchChange,
    options: filteredOptions,
  };
};
