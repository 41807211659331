import { LiscioAppLayout } from '@liscio/common';

import { PrimaryNavigationMenu } from './PrimaryNavigationMenu';
import { SecondaryNavigationMenu } from './SecondaryNavigationMenu';

/**
 * Application layout for authenticated users
 */
export const AppLayout = () => (
  <LiscioAppLayout
    primaryNav={<PrimaryNavigationMenu />}
    secondaryNav={<SecondaryNavigationMenu />}
  />
);
