import React from 'react';

import { Interweave as DefaultInterweave } from 'interweave';

import { styled, Box } from '@liscio/ui';

export interface InterweaveProps {
  content: string;
}

const Wrapper = styled(Box)(({ theme }) => ({
  '& img': {
    maxWidth: '90vw',
    height: 'auto',
  },
  '& a': {
    color: theme.palette.common.cyan[500],
  },
}));

export const Interweave: React.FC<InterweaveProps> = ({ content }) => {
  return (
    <Wrapper>
      <DefaultInterweave content={content} />
    </Wrapper>
  );
};

export default Interweave;
