export const TASK_TYPES = [
  { label: 'Request Information', value: 'request_info' },
  { label: 'Get a Signature', value: 'get_signature' },
  { label: 'To Do', value: 'to_do' },
  { label: 'Meeting', value: 'virtual_meeting' },
  { label: 'Manage To Go Items', value: 'manage_to_go_items' },
  { label: 'eDoc', value: 'edoc' },
];

export default TASK_TYPES;
