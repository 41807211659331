import React from 'react';

import { LDProvider } from 'launchdarkly-react-client-sdk';

import { config } from 'config';

interface LaunchDarklyProviderProps {
  children: React.ReactNode;
}

export default function LaunchDarklyProvider({
  children,
}: LaunchDarklyProviderProps) {
  if (!config.launchDarkly.isEnabled) {
    return <>{children}</>;
  }

  const initialContext = {
    kind: 'multi',
    app: {
      key: '@liscio/client',
    },
    platform: {
      key: config.platform,
    },
  };

  return (
    <LDProvider
      clientSideID={config.launchDarkly.clientId}
      options={{ streaming: true }}
      context={initialContext}
    >
      {children}
    </LDProvider>
  );
}
