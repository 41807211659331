import {
  usePushNotifications,
  usePushNotificationsCleanup,
} from '@liscio/common';

import { useAuth } from 'fetch-utils/users/use-auth';

/**
 * Simple component for usePushNotifications hook
 * NOTE: Leaving this as a component in case we decide to render
 * toasts/modals/etc on push notification while app is open
 */
export const PushNotifications = () => {
  const { isLoggedIn } = useAuth();

  usePushNotifications({ isLoggedIn });
  usePushNotificationsCleanup();

  return null;
};
