import {
  AccountAsTaskData,
  GetTaskAccountSearchResponse,
  AccountsOfContactOnTheBasisOfUserIdResponse,
} from '@liscio/api';

import { Recipient } from 'components/ContactSelector/ContactSelector';

interface Args {
  fixedToContactId?: string;
  selectedRecipient?: Recipient | null;
  accountSearchString: string;
  accountsAsTaskData: void | null | AccountAsTaskData[];
  searchedAccounts: void | GetTaskAccountSearchResponse;
  loggedUserAccounts: void | AccountsOfContactOnTheBasisOfUserIdResponse;
  isCustomer: boolean;
}

const createAccountFilter =
  (searchString: string) => (account: { label: string }) => {
    const normalizedLabel = account.label.toLowerCase();
    const normalizedSearchString = searchString.toLowerCase();
    return normalizedLabel.includes(normalizedSearchString);
  };

export const mungeAccounts: (args: Args) => any = ({
  fixedToContactId,
  selectedRecipient,
  accountSearchString,
  searchedAccounts,
  accountsAsTaskData,
  loggedUserAccounts,
  isCustomer,
}) => {
  if (fixedToContactId) return selectedRecipient?.accounts;

  if (selectedRecipient && selectedRecipient.type === 'contact') {
    return accountSearchString
      ? selectedRecipient.accounts?.filter(
          createAccountFilter(accountSearchString)
        ) || []
      : selectedRecipient.accounts || [];
  }

  if (isCustomer) {
    const accounts = loggedUserAccounts?.data || [];
    return accountSearchString
      ? accounts.filter(createAccountFilter(accountSearchString))
      : accounts;
  }

  if (accountSearchString) {
    return searchedAccounts || [];
  }
  return accountsAsTaskData || [];
};

export default mungeAccounts;
