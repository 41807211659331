import { NavMenuItem } from '@liscio/common';
import {
  Icon,
  Menu,
  useMenuAnchor,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@liscio/ui';

import MoreMenuItem from './components/MoreMenuItem/MoreMenuItem';

export const AddNewNavMenuItem = () => {
  const { anchorEl, open, onOpen, onClose } = useMenuAnchor();

  return (
    <>
      <NavMenuItem
        data-testid="sidebar__add_new"
        icon={<Icon icon="plusSquare" />}
        label="Add New"
        onClick={onOpen}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'center',
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        sx={{ ml: 1 }}
      >
        <MenuItem href="/bulkdownload">
          <ListItemIcon>
            <Icon icon="download" />
          </ListItemIcon>
          <ListItemText>Invite to Download App</ListItemText>
        </MenuItem>
        <MenuItem href="/bulksignature">
          <ListItemIcon>
            <Icon icon="signatureReq" />
          </ListItemIcon>
          <ListItemText>Get Signature</ListItemText>
        </MenuItem>
        <MoreMenuItem label="Task" icon={<Icon icon="tasks" />}>
          <MenuItem href="/bulkmessage">
            <ListItemText>Request Information</ListItemText>
          </MenuItem>
          <MenuItem href="/bulkmessage">
            <ListItemText>Meeting</ListItemText>
          </MenuItem>
          <MenuItem href="/task/new?type=request_info">
            <ListItemText>Get a Signature</ListItemText>
          </MenuItem>
          <MenuItem href="/task/new?type=to_do">
            <ListItemText>To Do</ListItemText>
          </MenuItem>
          <MenuItem href="/task/new?type=manage_to_go_items">
            <ListItemText>Manage To Go Items</ListItemText>
          </MenuItem>
        </MoreMenuItem>
        <MenuItem href="/bulkinvite">
          <ListItemIcon>
            <Icon icon="share" />
          </ListItemIcon>
          <ListItemText>Invite to Liscio</ListItemText>
        </MenuItem>
        <MenuItem href="/bulkesign">
          <ListItemIcon>
            <Icon icon="edoc" />
          </ListItemIcon>
          <ListItemText>Send eDocs</ListItemText>
        </MenuItem>
        <MenuItem href="/bulkreassign">
          <ListItemIcon>
            <Icon icon="reassignTask" />
          </ListItemIcon>
          <ListItemText>Reassign Task Owner</ListItemText>
        </MenuItem>
        <MoreMenuItem label="Invoice" icon={<Icon icon="billing" />}>
          <MenuItem href="/bulkmessage">
            <ListItemText>New Invoice</ListItemText>
          </MenuItem>
          <MenuItem href="/bulkmessage">
            <ListItemText>Recurring Charge</ListItemText>
          </MenuItem>
        </MoreMenuItem>
      </Menu>
    </>
  );
};
