import { styled, Box } from '@liscio/ui';

export interface SeparatorProps {}

const Separator = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  height: '20px',
}));

export default Separator;
