import React, { useEffect, useState } from 'react';

import { FormHelperText, FormControl } from '@mui/material';
import { styled, Box } from '@liscio/ui';

import ContactSelector, {
  RecipientAccount,
} from 'components/ContactSelector/ContactSelector';
import UserChip from 'components/UserChip/UserChip';
import { useAccountOverview } from 'fetch-utils/accounts/accounts-hooks';
import { useContactDetails } from 'fetch-utils/contacts/contacts-hooks';

export interface ContactsSelectorProps {
  placeholder?: string;
  value?: Array<RecipientAccount>;
  error?: boolean;
  helperText?: string;
  onAddElement(newValue: RecipientAccount): void;
  onRemoveElement(value: RecipientAccount, index: number): void;
  fixedToAccountId?: string;
  fixedToContactId?: string;
}

const Wrapper = styled(Box)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '0.9375rem',
  borderStyle: 'solid',
  borderWidth: '1px',
  width: '100%',
  display: 'flex',
  rowGap: '4px',
  columnGap: '4px',
  padding: '14.5px 14px',
  minHeight: '54.56px', // strange...
  color: theme.palette.grey[400],
  flexWrap: 'wrap',
}));
const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
}));
const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const ContactsSelector: React.FC<ContactsSelectorProps> = ({
  placeholder,
  value = [],
  onAddElement,
  onRemoveElement,
  error,
  helperText,
  fixedToAccountId,
  fixedToContactId,
}) => {
  const [isContactSelectorOpen, setIsContactSelectorOpen] = useState(false);
  const onContactSelectorCancel = () => setIsContactSelectorOpen(false);
  const [selectedRecipient, setSelectedRecipient] = useState<
    RichOption | undefined
  >();
  const [selectedAccount, setSelectedAccount] = useState<
    RichOption | undefined
  >();
  const { data: fixedContact } = useContactDetails(fixedToContactId);
  const { data: fixedAccount } = useAccountOverview(fixedToAccountId);

  useEffect(() => {
    if (fixedToContactId && !selectedRecipient && fixedContact) {
      const { contact, accounts } = fixedContact?.data[0] || {};

      setSelectedRecipient({
        value: contact.id,
        label: `${contact.first_name} ${contact.first_name}`,
        accounts: accounts,
      });
    }
  }, [fixedToContactId, selectedRecipient, fixedContact]);

  useEffect(() => {
    if (fixedToAccountId && !selectedAccount && fixedAccount) {
      setSelectedAccount({
        value: fixedAccount?.account.id,
        label: fixedAccount?.account.name,
      });
    }
  }, [fixedToAccountId, selectedAccount, fixedAccount]);

  return (
    <>
      <StyledFormControl>
        <Wrapper
          role="button"
          sx={{
            borderRadius: '8px',
            borderColor: (theme) =>
              error ? theme.palette.error.main : theme.palette.grey[300],
          }}
          onClick={() => setIsContactSelectorOpen(true)}
        >
          {value.length === 0 && placeholder}
          {value.map((recipientAccount, index) => (
            <UserChip
              key={`${recipientAccount.recipient?.value}_${recipientAccount.account?.value}`}
              onRemoveClick={() => onRemoveElement(recipientAccount, index)}
              userName={recipientAccount.recipient?.label}
              accountName={recipientAccount.account?.label}
            />
          ))}
        </Wrapper>
        {error && (
          <StyledFormHelperText variant="filled">
            {helperText}
          </StyledFormHelperText>
        )}
      </StyledFormControl>
      <ContactSelector
        fixedToAccountId={fixedToAccountId}
        fixedToContactId={fixedToContactId}
        open={isContactSelectorOpen}
        selectedRecipient={selectedRecipient}
        onCancel={onContactSelectorCancel}
        onChange={onAddElement}
      />
    </>
  );
};

export default ContactsSelector;
