import React from 'react';

import { Radio as MUIRadio } from '@mui/material';

import { Typography, styled } from '@liscio/ui';

export interface RadioInlineProps {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: () => void;
}

const StyledLabel = styled('label')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const RadioInline: React.FC<RadioInlineProps> = ({
  label,
  checked,
  disabled,
  onChange,
}) => {
  return (
    <StyledLabel>
      <MUIRadio checked={checked} onClick={onChange} disabled={disabled} />
      <Typography>{label}</Typography>
    </StyledLabel>
  );
};

export default RadioInline;
