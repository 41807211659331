import React from 'react';

import { DetailsState, DetailsStateProps } from '@liscio/common';
import { IconButton, Icon } from '@liscio/ui';

export interface StateElementProps extends DetailsStateProps {}

export const StateElement: React.FC<StateElementProps> = ({
  onNavigationClick,
  onSecondaryActionClick,
  children,
  title,
}) => {
  const secondaryActionChildren = (
    <IconButton
      aria-label="Navigation"
      size="large"
      onClick={onSecondaryActionClick}
    >
      <Icon icon="ellipsis" />
    </IconButton>
  );
  return (
    <DetailsState
      onNavigationClick={onNavigationClick}
      title={title}
      children={children}
      secondaryActionChildren={secondaryActionChildren}
    />
  );
};

export default StateElement;
