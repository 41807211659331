import { DocumentDetail } from '@liscio/api';

// https://stackoverflow.com/questions/1575271/range-of-years-in-javascript-for-a-select-box
const currentYear = new Date().getFullYear();

const makeListOfYears = (start: number, stop: number, step: number) =>
  Array.from(
    {
      length: (stop - start) / step + 1,
    },
    (_, index) => start + index * step
  );

export const RECENT_YEARS: RichOption[] = makeListOfYears(
  currentYear,
  currentYear - 15,
  -1
).map((year) => ({ label: String(year), value: String(year) }));

export const MONTHS: RichOption[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map((month) => ({ label: month, value: month }));

export const makeFilter = (filterBy: string) => (option: RichOption) =>
  filterBy ? option.label.toLowerCase().includes(filterBy.toLowerCase()) : true;

export function makeFormValuesFromDocumentDetails(
  id: number,
  fileDetails: DocumentDetail
) {
  return {
    doc_ids: [id],
    year: fileDetails.upload_file_year || '',
    month: fileDetails.upload_file_month || '',
    // the tags form expects values in this format
    tags: fileDetails.tags?.map<RichOption>((tag: string) => ({
      value: tag,
      label: tag,
      key: tag,
    })),
    file_name: fileDetails.doc_name || '',
  };
}
