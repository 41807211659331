import * as yup from 'yup';

import { PASSWORD_REQUIRED_CHARACTERS_REGEX } from 'constants/validation';

export const validationSchema = yup.object({
  password: yup
    .string()
    .matches(
      PASSWORD_REQUIRED_CHARACTERS_REGEX,
      'Must Contain 12 Characters, One Uppercase, One Lowercase, One Number and one special case Character: @$!%*#?&'
    )
    .required('Required'),
  password_confirmation: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});
