import { TextField, Theme } from '@mui/material';
import { styled } from '@liscio/ui';

import { PasswordInput } from 'components';

// Custom styled inputß for auth module views
export const authInputStyle = ({ theme }: { theme: Theme }) => ({
  '& .MuiInputBase-root, & .MuiInputBase-input': {
    backgroundColor: theme.brandColors.medium,
    // This property is set to black in our desktop theme, which is difficult to selectively override
    // Seems like there's no reason to get clever about it when we will eventually port over client login styles
    color: `${theme.palette.common.white} !important`,
  },
});

export const AuthTextField = styled(TextField)(authInputStyle);
export const AuthPasswordInput = styled(PasswordInput)(authInputStyle);
