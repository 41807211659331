import React, { useEffect } from 'react';

import { TaskDetails } from '@liscio/api';
import { GlobalLoader } from '@liscio/ui';

import { calculateInitialLoadingState } from './helpers';
import { PayInvoiceHandlerStates } from 'modules/invoices/components/PayInvoiceHandlerV2/PayInvoiceHandlerV2';

export interface InitialLoadingStateProps {
  invoice: void | TaskDetails | undefined;
  setCurrentState: React.Dispatch<
    React.SetStateAction<PayInvoiceHandlerStates>
  >;
}

export const InitialLoadingState: React.FC<InitialLoadingStateProps> = ({
  invoice,
  setCurrentState,
}) => {
  useEffect(() => {
    const newState = calculateInitialLoadingState({ invoice });
    setCurrentState(newState);
  }, [invoice, setCurrentState]);

  return <GlobalLoader open />;
};

export default InitialLoadingState;
