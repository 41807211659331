import React from 'react';

import { BoxProps } from '@mui/material';

import { styled, Box } from '@liscio/ui';

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.common?.neutrals?.[50],
  minHeight: '100vh',
  '@supports (height: -webkit-fill-available)': {
    minHeight: '-webkit-fill-available',
  },
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  // Change position to fit mobile views in desktop layout
  [theme.breakpoints.up('md')]: {
    position: 'relative',
    minHeight: 'calc(100vh - 50px)', // The desktop view has a footer that is 50 pixels high
  },
}));

interface ScreenWrapperProps extends BoxProps {
  dataTestId?: string;
}

export const ScreenWrapper: React.FC<ScreenWrapperProps> = ({
  children,
  dataTestId = 'ScreenWrapper',
  ...props
}) => {
  return (
    <StyledBox {...props} data-testid={dataTestId}>
      {children}
    </StyledBox>
  );
};

export default ScreenWrapper;
