import React from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { Loading, Checkbox } from 'components';
import { useHomeData } from 'fetch-utils/users/user-hooks';

export interface TagsFilterFormProps {}

export const TagsFilterForm: React.FC<TagsFilterFormProps> = () => {
  const { data: homeBlob, isLoading } = useHomeData();
  const tags: RichOption[] =
    homeBlob?.data?.pref_type_options?.upload_tags || [];
  const { watch } = useFormContext();
  const { append, remove } = useFieldArray({ name: 'tags' });
  const tagsInForm: string[] = watch('tags') || [];

  const getTagIndexInForm = (tag: RichOption) =>
    tagsInForm.findIndex((tagInForm) => tag.value === tagInForm);

  const handleChange = (clickedTag: RichOption) => {
    if (clickedTag) {
      const tagIndexInForm = getTagIndexInForm(clickedTag);
      if (tagIndexInForm > -1) {
        remove(tagIndexInForm);
      } else {
        append(clickedTag.value);
      }
    }
  };

  if (isLoading) {
    return <Loading mt={1} numberOfElements={1} />;
  }

  return (
    <>
      {tags?.map((tag: RichOption) => {
        if (!tag) return null;

        const tagIndexInForm = getTagIndexInForm(tag);
        return (
          <Checkbox
            key={tag.value}
            label={tag.label}
            onChange={() => handleChange(tag)}
            checked={tagIndexInForm > -1}
          />
        );
      })}
    </>
  );
};

export default TagsFilterForm;
