import React from 'react';

import { useNavigate } from 'react-router-dom';

import Header from '../Header/Header';
import { Loading } from 'components';
import { useGetUnpaidInvoicesAndUnauthorizedParents } from 'fetch-utils/invoices/invoices-hooks';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from 'modules/dashboard/route-constants';
import {
  INVOICES_DOMAIN,
  INVOICES_PATHS,
} from 'modules/invoices/route-constants';
import { List } from 'modules/invoices/views/ListView/components';
import { AVAILABLE_TABS } from 'modules/invoices/views/ListView/components/Tabs/Tabs';

export interface InvoicesProps {}

const DEFAULT_TAB = AVAILABLE_TABS.INVOICES;

export const Invoices: React.FC<InvoicesProps> = () => {
  const navigate = useNavigate();

  const { data, isLoading: areInvoicesLoading } =
    useGetUnpaidInvoicesAndUnauthorizedParents();
  const count = data?.invoices_count;
  const invoices = data?.invoices || [];

  if (areInvoicesLoading) {
    return <Loading numberOfElements={1} />;
  }

  if (count === 0 && !areInvoicesLoading) {
    return null;
  }

  return (
    <>
      <Header
        title="Invoices"
        isLoading={areInvoicesLoading}
        numberOfItems={count}
        onSeeAllClick={() => {
          navigate(`/${INVOICES_DOMAIN}${INVOICES_PATHS.list}`);
        }}
      />
      <List
        invoices={invoices.slice(0, 3)}
        tab={DEFAULT_TAB}
        shouldShowPayButton={false}
        goBackUrl={`/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`}
      />
    </>
  );
};

export default Invoices;
