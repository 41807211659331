import React, { ReactNode } from 'react';

import { Card as MUICard, CardHeader, CardContent } from '@mui/material';

import { styled } from '@liscio/ui';

export interface CardProps {
  title: string;
  children: ReactNode;
  actionButton?: ReactNode;
}

const StyledCard = styled(MUICard)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[200]}`,
  borderRadius: '4px',
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.common.blueGray[50],
  '& .MuiTypography-h5': {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '24px',
  },
}));

const StyledCardContent = styled(CardContent)({
  padding: 0,
  '&:last-child': {
    paddingBottom: 0,
  },
});

export const Card: React.FC<CardProps> = ({
  title,
  actionButton,
  children,
}) => {
  return (
    <StyledCard>
      <StyledCardHeader title={title} action={actionButton} />
      <StyledCardContent>{children}</StyledCardContent>
    </StyledCard>
  );
};

export default Card;
