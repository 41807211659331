import React from 'react';

import { Skeleton } from '@mui/material';

import { Stack } from '@liscio/ui';

export interface LoadingProps {
  numberOfElements?: number;
  mt?: number;
}

export const Loading: React.FC<LoadingProps> = ({
  numberOfElements = 2,
  mt = 8,
}) => (
  <Stack gap={2} alignItems="center" mt={mt}>
    {Array(numberOfElements)
      .fill('')
      .map((_, index) => (
        <Skeleton key={index} height={60} width="80%" />
      ))}
  </Stack>
);

export default Loading;
