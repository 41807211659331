import React, { ReactNode, useMemo } from 'react';

import {
  SpeedDial as MUISpeedDial,
  SpeedDialAction,
  SxProps,
} from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

export interface SpeedDialOption {
  Icon?: ReactNode;
  label: string;
  value: string;
}

export interface SpeedDialProps {
  options: Array<SpeedDialOption>;
  onNavigationClick?(key?: string): void;
  sx?: SxProps;
}

const DefaultSx: SxProps = {
  position: 'fixed',
  bottom: '16px',
  left: 'calc(50% - 28px)',
};

export const SpeedDial: React.FC<SpeedDialProps> = ({
  options,
  onNavigationClick,
  sx,
}) => {
  const open = options?.length === 1 ? false : undefined;
  const finalSx = useMemo(
    () => ({
      ...DefaultSx,
      ...sx,
    }),
    [sx]
  );

  const handleOptionClick = (option: string) =>
    onNavigationClick && onNavigationClick(`go:${option}`);
  const onClick =
    options?.length === 1
      ? () => handleOptionClick(options[0].value)
      : undefined;

  return (
    <MUISpeedDial
      ariaLabel="SpeedDial"
      icon={<SpeedDialIcon />}
      onClick={onClick}
      open={open}
      sx={finalSx}
    >
      {options?.length > 1 &&
        options.map((option) => (
          <SpeedDialAction
            key={option.value}
            icon={option.Icon}
            tooltipOpen
            tooltipTitle={option.label}
            onClick={() => handleOptionClick(option.value)}
          />
        ))}
    </MUISpeedDial>
  );
};

export default SpeedDial;
