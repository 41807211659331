import React from 'react';

import { IconButton, Typography, useTheme, styled, Icon } from '@liscio/ui';

export interface SortRadioProps {
  label: string;
  value?: 'asc' | 'desc' | 'none';
  onChange: () => void;
}

const StyledLabel = styled('label')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '40.5px',
});

export const SortRadio: React.FC<SortRadioProps> = ({
  label,
  value,
  onChange,
}) => {
  const theme = useTheme();
  const defaultColor = theme.palette.grey[500];
  const activeColor = theme.palette.primary.main;

  function handleOnChange(e: React.MouseEvent) {
    e.preventDefault();
    onChange();
  }

  return (
    <StyledLabel>
      <Typography>{label}</Typography>
      <IconButton onClick={handleOnChange}>
        {value === 'none' && <Icon icon="circle" htmlColor={defaultColor} />}
        {value === 'asc' && (
          <Icon icon="circleArrowUp" htmlColor={activeColor} />
        )}
        {value === 'desc' && (
          <Icon icon="circleArrowDown" htmlColor={activeColor} />
        )}
      </IconButton>
    </StyledLabel>
  );
};

export default SortRadio;
