import { NestedMenuItem, MenuItem, Icon } from '@liscio/ui';

type FirmAdminUserMenuProps = {
  open: boolean;
};

export const FirmAdminUserMenu = ({ open }: FirmAdminUserMenuProps) => (
  <>
    <NestedMenuItem
      label="Admin"
      parentMenuOpen={open}
      leftIcon={<Icon icon="admin" />}
      slotProps={{ paper: { sx: { minWidth: '200px' } } }}
    >
      <MenuItem sx={{ padding: '16px' }} to="/admin/users">
        Users
      </MenuItem>
      <MenuItem sx={{ padding: '16px' }} to="/admin/todo">
        Preferences
      </MenuItem>
      <MenuItem sx={{ padding: '16px' }}>Templates</MenuItem>
      <MenuItem sx={{ padding: '16px' }} to="/admin/todo">
        Integrations
      </MenuItem>
      <MenuItem sx={{ padding: '16px' }} to="/admin/todo">
        Admin SMS
      </MenuItem>
      <MenuItem sx={{ padding: '16px' }} to="/admin/billing">
        System Billing
      </MenuItem>
    </NestedMenuItem>
  </>
);
