import React from 'react';

import { styled, Box, Typography } from '@liscio/ui';

import { Avatar } from 'components';

export interface UserInfoProps {
  initials: string;
  name: string;
  avatar?: string;
  accountName?: string;
}

const Wrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export const UserInfo: React.FC<UserInfoProps> = ({
  initials,
  name,
  avatar,
  accountName,
}) => {
  return (
    <Wrapper>
      <Avatar src={avatar} sx={{ width: 24, height: 24, fontSize: '12px' }}>
        {!avatar ? initials : null}
      </Avatar>
      <Box sx={{ paddingLeft: '15px' }}>
        <Typography>{name}</Typography>
        {accountName && <GreyText>{accountName}</GreyText>}
      </Box>
    </Wrapper>
  );
};

export default UserInfo;
