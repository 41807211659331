import React from 'react';

import { useInitializedFlags } from '@liscio/common';
import { GlobalLoader } from '@liscio/ui';

import { OAuthRedirectV5View } from './OAuthRedirectV5View/OAuthRedirectV5View';
import { OAuthRedirectView } from './OAuthRedirectView/OAuthRedirectView';

export const OAuthRedirectFlaggedView = () => {
  const {
    isInitialized,
    flags: { v5Login },
  } = useInitializedFlags();

  if (!isInitialized) {
    return <GlobalLoader open />;
  }

  if (v5Login) {
    return <OAuthRedirectV5View />;
  }

  return <OAuthRedirectView />;
};

export default OAuthRedirectFlaggedView;
