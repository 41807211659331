import React from 'react';

import { GlobalLoader } from '@liscio/ui';

export type LazyWrapperProps = {
  children?: React.ReactNode;
  fallback?: React.ReactNode;
};

/**
 * Simple Suspense wrapper with default fallback
 */
export const LazyWrapper = ({
  children,
  fallback = <GlobalLoader open />,
}: LazyWrapperProps) => (
  <React.Suspense fallback={fallback}>{children}</React.Suspense>
);
