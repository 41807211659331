import React, { useRef } from 'react';

import { BoxProps } from '@mui/material';

import { styled, Box } from '@liscio/ui';

import { PullToRefresh } from './components';

export const StyledBox = styled(Box)({
  flexGrow: 1,
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingBottom: '8px',
  position: 'relative',
  overscrollBehavior: 'none',
});

interface BodyWrapperProps extends BoxProps {
  reloadFunction?: () => void;
  id?: string;
}

export const BodyWrapper: React.FC<BodyWrapperProps> = ({
  children,
  reloadFunction,
  id = 'body-wrapper',
  ...props
}) => {
  const bodyWrapperRef = useRef<HTMLElement | null>();

  return (
    <StyledBox ref={bodyWrapperRef} id={id} {...props}>
      {reloadFunction && (
        <PullToRefresh
          bodyNodeRef={bodyWrapperRef}
          reloadFunction={reloadFunction}
        />
      )}
      {children}
    </StyledBox>
  );
};

export default BodyWrapper;
