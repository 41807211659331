/**
 * Error parser for custom error messaging.
 * Note: In the absence of an error you should return void to prevent unnecesary error dialogs
 */
export type ErrorParser = (
  error: any,
  defaultMessage?: React.ReactNode
) => React.ReactNode | undefined;

/**
 * Default error parser for displaying async error messages
 * Looks for a message on the error object or returns default message;
 */
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong during submission.';
export const defaultErrorParser: ErrorParser = (
  error,
  defaultMessage = DEFAULT_ERROR_MESSAGE
) => {
  if (error) {
    if (typeof error?.message === 'string') return error.message;

    return defaultMessage;
  }
};
