import React from 'react';

import { useNavigate } from 'react-router-dom';

import { GlobalLoader } from '@liscio/ui';

import { ConfirmationDialog } from 'components';
import {
  useArchiveDocument,
  useDeleteDocument,
} from 'fetch-utils/documents/documents-hooks';
import { FILES_DOMAIN, FILES_PATHS } from 'modules/files/route-constants';

export interface ConfirmationDialogProps {
  documentId: number;
  file?: File;
  messageId?: string;
  open: boolean;
  tab?: string;
  closeFunction(): void;
  onSuccess?: (id: number) => void;
}

export const RemoveFileFromMessageDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  tab,
  file,
  documentId,
  onSuccess,
  messageId,
  closeFunction,
}) => {
  const navigate = useNavigate();
  const handleSuccess = (res: Response & { document_ids: number[] }) => {
    if (typeof onSuccess === 'function') {
      onSuccess(documentId);
    } else {
      navigate(`/${FILES_DOMAIN}${FILES_PATHS.list}`, { state: { tab } });
    }

    closeFunction();
  };

  const { mutate: deleteDocument, isLoading: isDocumentDeleting } =
    useDeleteDocument({
      onSuccess: handleSuccess,
    });
  const { mutate: archiveDocument, isLoading: isDocumentArchiving } =
    useArchiveDocument({
      onSuccess: handleSuccess,
    });

  const onConfirm = async () => {
    closeFunction();

    if (messageId) {
      deleteDocument({
        documentId,
        messageId,
      });
    } else {
      archiveDocument({
        mode: 'ARCHIVE',
        documentId,
      });
    }
  };

  return (
    <>
      <ConfirmationDialog
        open={open}
        onConfirm={onConfirm}
        onCancel={closeFunction}
        title={'Confirm delete this file'}
        content={`Are you sure you want to delete ${file?.name}?`}
        confirmLabel={'Delete'}
      />
      <GlobalLoader open={isDocumentDeleting || isDocumentArchiving} />
    </>
  );
};

export default RemoveFileFromMessageDialog;
