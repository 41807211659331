import React from 'react';

import { DocumentRecipient } from '@liscio/api';

import UserInfo from 'components/UserInfo/UserInfo';

interface FileDetailRecipientProps {
  recipient: DocumentRecipient;
}

export default function FileDetailRecipient({
  recipient,
}: FileDetailRecipientProps) {
  if (!recipient) return null;

  const { initials, avatar, account_name, name } = recipient;
  return (
    <UserInfo
      initials={initials}
      avatar={avatar}
      accountName={account_name}
      name={name}
    />
  );
}
