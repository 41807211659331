import { useRef as ze, useEffect as re, useState as Ke, useCallback as gt } from "react";
const Gn = (n) => {
  if (n.length < 12)
    throw new Error("Password must contain at least 12 characters");
  if (!/[A-Z]/.test(n))
    throw new Error(
      "Password must contain at least 1 uppercase character  (A..Z)"
    );
  if (!/\d/.test(n))
    throw new Error(
      "Password must contain at least 1 number character  (0..9)"
    );
  if (!/[!@#$%^&*()_+\-={}|[\];:,./<>?~`]/.test(n))
    throw new Error(
      "Password must contain at least 1 special character  (!@#$%^&*()_+-={}|[];:,./<>?~`)"
    );
  const a = n.match(
    /[^A-Za-z\d!@#$%^&*()_+\-={}|[\];:,./<>?~`]/g
  );
  if (a != null && a.length)
    throw new Error(
      `Password contains invalid characters ${a.map((t) => `(${t})`).join(", ")}`
    );
  return !0;
}, Pe = "body-wrapper", Vn = () => {
  var n;
  return ((n = document.getElementById(Pe)) == null ? void 0 : n.scrollTop) || 0;
}, zn = (n) => {
  const a = document.getElementById(Pe);
  a && (a.scrollTop = n);
}, Kn = (n, a = Pe) => {
  const t = document.getElementById(a);
  if (t && t.scrollTo && n) {
    const r = n.offsetHeight, e = n.offsetTop;
    t.scrollTo({
      top: e + r,
      behavior: "smooth"
    });
  }
};
function Zn(n) {
  n.preventDefault(), n.stopPropagation(), n.nativeEvent.stopImmediatePropagation();
}
const Jn = (n = "localStorage") => {
  const a = n === "sessionStorage" ? sessionStorage : localStorage;
  return {
    setItem: (o, i) => {
      a.setItem(o, JSON.stringify(i));
    },
    getItem: (o) => {
      try {
        const i = a.getItem(o);
        if (i)
          return JSON.parse(i);
      } catch {
      }
    },
    removeItem: (o) => {
      a.removeItem(o);
    },
    clear: a.clear
  };
}, ea = (n, {
  decimalPlaces: a = 2,
  sizeTerms: t = ["bytes", "kb", "mb", "gb", "tb", "pb"],
  threshold: r = 1e3
} = {}) => {
  if (n === 0)
    return "";
  const e = Math.min(
    Math.floor(Math.log(n) / Math.log(r)),
    t.length - 1
  );
  return `${parseFloat(
    (n / Math.pow(r, e)).toFixed(a)
  )} ${t[e]}`;
}, yt = "Something went wrong during submission.", ta = (n, a = yt) => {
  var t, r;
  if (n)
    return typeof (n == null ? void 0 : n.message) == "string" ? n.message : typeof (n == null ? void 0 : n.error) == "string" ? n.error : typeof ((r = (t = n == null ? void 0 : n.errors) == null ? void 0 : t[0]) == null ? void 0 : r.message) == "string" ? n.errors[0].message : a;
};
function pt(n) {
  return n ? n.charAt(0).toUpperCase() + n.slice(1) : "";
}
function ra(n) {
  return n ? pt(n).slice(0, n.length - 1) : "";
}
const na = (n = "") => n.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;"), aa = (n) => n.replace(/(\r\n|\r|\n)/g, "<br/>"), ia = (n) => n.split(/[\s+]/).map(
  (t) => `${(t[0] || "").toUpperCase()}${t.slice(1).toLowerCase()}`
).join("");
function oa(n) {
  return typeof n != "number" ? "" : `${(n / 1024).toFixed(2)} KB`;
}
const ua = (n) => {
  const a = n.split("."), t = a.length > 1 ? a.pop() : void 0, r = a.join(".");
  return {
    fileName: n,
    baseName: r,
    extension: t
  };
}, bt = (n, a = "") => {
  const t = Number(n);
  return !Number.isFinite(t) || n === "" || n === null || n === void 0 ? a : t.toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });
}, sa = (n, a) => {
  const r = parseInt(n) / 100;
  return bt(r, a);
};
function B(n) {
  "@babel/helpers - typeof";
  return B = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(a) {
    return typeof a;
  } : function(a) {
    return a && typeof Symbol == "function" && a.constructor === Symbol && a !== Symbol.prototype ? "symbol" : typeof a;
  }, B(n);
}
function I(n) {
  if (n === null || n === !0 || n === !1)
    return NaN;
  var a = Number(n);
  return isNaN(a) ? a : a < 0 ? Math.ceil(a) : Math.floor(a);
}
function D(n, a) {
  if (a.length < n)
    throw new TypeError(n + " argument" + (n > 1 ? "s" : "") + " required, but only " + a.length + " present");
}
function p(n) {
  D(1, arguments);
  var a = Object.prototype.toString.call(n);
  return n instanceof Date || B(n) === "object" && a === "[object Date]" ? new Date(n.getTime()) : typeof n == "number" || a === "[object Number]" ? new Date(n) : ((typeof n == "string" || a === "[object String]") && typeof console < "u" && (console.warn("Starting with v2.0.0-beta.1 date-fns doesn't accept strings as date arguments. Please use `parseISO` to parse strings. See: https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#string-arguments"), console.warn(new Error().stack)), /* @__PURE__ */ new Date(NaN));
}
function Tt(n, a) {
  D(2, arguments);
  var t = p(n).getTime(), r = I(a);
  return new Date(t + r);
}
var Dt = {};
function J() {
  return Dt;
}
function me(n) {
  var a = new Date(Date.UTC(n.getFullYear(), n.getMonth(), n.getDate(), n.getHours(), n.getMinutes(), n.getSeconds(), n.getMilliseconds()));
  return a.setUTCFullYear(n.getFullYear()), n.getTime() - a.getTime();
}
function he(n, a) {
  D(2, arguments);
  var t = p(n), r = p(a), e = t.getTime() - r.getTime();
  return e < 0 ? -1 : e > 0 ? 1 : e;
}
var Mt = 6e4, Ct = 36e5, Pt = 1e3;
function xt(n) {
  return D(1, arguments), n instanceof Date || B(n) === "object" && Object.prototype.toString.call(n) === "[object Date]";
}
function Ot(n) {
  if (D(1, arguments), !xt(n) && typeof n != "number")
    return !1;
  var a = p(n);
  return !isNaN(Number(a));
}
function kt(n, a) {
  D(2, arguments);
  var t = p(n), r = p(a), e = t.getFullYear() - r.getFullYear(), o = t.getMonth() - r.getMonth();
  return e * 12 + o;
}
function Et(n, a) {
  return D(2, arguments), p(n).getTime() - p(a).getTime();
}
var $e = {
  ceil: Math.ceil,
  round: Math.round,
  floor: Math.floor,
  trunc: function(a) {
    return a < 0 ? Math.ceil(a) : Math.floor(a);
  }
  // Math.trunc is not supported by IE
}, Ut = "trunc";
function _t(n) {
  return n ? $e[n] : $e[Ut];
}
function Yt(n) {
  D(1, arguments);
  var a = p(n);
  return a.setHours(23, 59, 59, 999), a;
}
function Wt(n) {
  D(1, arguments);
  var a = p(n), t = a.getMonth();
  return a.setFullYear(a.getFullYear(), t + 1, 0), a.setHours(23, 59, 59, 999), a;
}
function Nt(n) {
  D(1, arguments);
  var a = p(n);
  return Yt(a).getTime() === Wt(a).getTime();
}
function It(n, a) {
  D(2, arguments);
  var t = p(n), r = p(a), e = he(t, r), o = Math.abs(kt(t, r)), i;
  if (o < 1)
    i = 0;
  else {
    t.getMonth() === 1 && t.getDate() > 27 && t.setDate(30), t.setMonth(t.getMonth() - e * o);
    var u = he(t, r) === -e;
    Nt(p(n)) && o === 1 && he(n, r) === 1 && (u = !1), i = e * (o - Number(u));
  }
  return i === 0 ? 0 : i;
}
function Ht(n, a, t) {
  D(2, arguments);
  var r = Et(n, a) / 1e3;
  return _t(t == null ? void 0 : t.roundingMethod)(r);
}
function Ze(n, a) {
  D(2, arguments);
  var t = I(a);
  return Tt(n, -t);
}
var At = 864e5;
function Lt(n) {
  D(1, arguments);
  var a = p(n), t = a.getTime();
  a.setUTCMonth(0, 1), a.setUTCHours(0, 0, 0, 0);
  var r = a.getTime(), e = t - r;
  return Math.floor(e / At) + 1;
}
function ne(n) {
  D(1, arguments);
  var a = 1, t = p(n), r = t.getUTCDay(), e = (r < a ? 7 : 0) + r - a;
  return t.setUTCDate(t.getUTCDate() - e), t.setUTCHours(0, 0, 0, 0), t;
}
function Je(n) {
  D(1, arguments);
  var a = p(n), t = a.getUTCFullYear(), r = /* @__PURE__ */ new Date(0);
  r.setUTCFullYear(t + 1, 0, 4), r.setUTCHours(0, 0, 0, 0);
  var e = ne(r), o = /* @__PURE__ */ new Date(0);
  o.setUTCFullYear(t, 0, 4), o.setUTCHours(0, 0, 0, 0);
  var i = ne(o);
  return a.getTime() >= e.getTime() ? t + 1 : a.getTime() >= i.getTime() ? t : t - 1;
}
function $t(n) {
  D(1, arguments);
  var a = Je(n), t = /* @__PURE__ */ new Date(0);
  t.setUTCFullYear(a, 0, 4), t.setUTCHours(0, 0, 0, 0);
  var r = ne(t);
  return r;
}
var Rt = 6048e5;
function et(n) {
  D(1, arguments);
  var a = p(n), t = ne(a).getTime() - $t(a).getTime();
  return Math.round(t / Rt) + 1;
}
function Z(n, a) {
  var t, r, e, o, i, u, s, f;
  D(1, arguments);
  var v = J(), d = I((t = (r = (e = (o = a == null ? void 0 : a.weekStartsOn) !== null && o !== void 0 ? o : a == null || (i = a.locale) === null || i === void 0 || (u = i.options) === null || u === void 0 ? void 0 : u.weekStartsOn) !== null && e !== void 0 ? e : v.weekStartsOn) !== null && r !== void 0 ? r : (s = v.locale) === null || s === void 0 || (f = s.options) === null || f === void 0 ? void 0 : f.weekStartsOn) !== null && t !== void 0 ? t : 0);
  if (!(d >= 0 && d <= 6))
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  var M = p(n), h = M.getUTCDay(), C = (h < d ? 7 : 0) + h - d;
  return M.setUTCDate(M.getUTCDate() - C), M.setUTCHours(0, 0, 0, 0), M;
}
function xe(n, a) {
  var t, r, e, o, i, u, s, f;
  D(1, arguments);
  var v = p(n), d = v.getUTCFullYear(), M = J(), h = I((t = (r = (e = (o = a == null ? void 0 : a.firstWeekContainsDate) !== null && o !== void 0 ? o : a == null || (i = a.locale) === null || i === void 0 || (u = i.options) === null || u === void 0 ? void 0 : u.firstWeekContainsDate) !== null && e !== void 0 ? e : M.firstWeekContainsDate) !== null && r !== void 0 ? r : (s = M.locale) === null || s === void 0 || (f = s.options) === null || f === void 0 ? void 0 : f.firstWeekContainsDate) !== null && t !== void 0 ? t : 1);
  if (!(h >= 1 && h <= 7))
    throw new RangeError("firstWeekContainsDate must be between 1 and 7 inclusively");
  var C = /* @__PURE__ */ new Date(0);
  C.setUTCFullYear(d + 1, 0, h), C.setUTCHours(0, 0, 0, 0);
  var W = Z(C, a), Y = /* @__PURE__ */ new Date(0);
  Y.setUTCFullYear(d, 0, h), Y.setUTCHours(0, 0, 0, 0);
  var H = Z(Y, a);
  return v.getTime() >= W.getTime() ? d + 1 : v.getTime() >= H.getTime() ? d : d - 1;
}
function qt(n, a) {
  var t, r, e, o, i, u, s, f;
  D(1, arguments);
  var v = J(), d = I((t = (r = (e = (o = a == null ? void 0 : a.firstWeekContainsDate) !== null && o !== void 0 ? o : a == null || (i = a.locale) === null || i === void 0 || (u = i.options) === null || u === void 0 ? void 0 : u.firstWeekContainsDate) !== null && e !== void 0 ? e : v.firstWeekContainsDate) !== null && r !== void 0 ? r : (s = v.locale) === null || s === void 0 || (f = s.options) === null || f === void 0 ? void 0 : f.firstWeekContainsDate) !== null && t !== void 0 ? t : 1), M = xe(n, a), h = /* @__PURE__ */ new Date(0);
  h.setUTCFullYear(M, 0, d), h.setUTCHours(0, 0, 0, 0);
  var C = Z(h, a);
  return C;
}
var St = 6048e5;
function tt(n, a) {
  D(1, arguments);
  var t = p(n), r = Z(t, a).getTime() - qt(t, a).getTime();
  return Math.round(r / St) + 1;
}
function T(n, a) {
  for (var t = n < 0 ? "-" : "", r = Math.abs(n).toString(); r.length < a; )
    r = "0" + r;
  return t + r;
}
var Ft = {
  // Year
  y: function(a, t) {
    var r = a.getUTCFullYear(), e = r > 0 ? r : 1 - r;
    return T(t === "yy" ? e % 100 : e, t.length);
  },
  // Month
  M: function(a, t) {
    var r = a.getUTCMonth();
    return t === "M" ? String(r + 1) : T(r + 1, 2);
  },
  // Day of the month
  d: function(a, t) {
    return T(a.getUTCDate(), t.length);
  },
  // AM or PM
  a: function(a, t) {
    var r = a.getUTCHours() / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return r.toUpperCase();
      case "aaa":
        return r;
      case "aaaaa":
        return r[0];
      case "aaaa":
      default:
        return r === "am" ? "a.m." : "p.m.";
    }
  },
  // Hour [1-12]
  h: function(a, t) {
    return T(a.getUTCHours() % 12 || 12, t.length);
  },
  // Hour [0-23]
  H: function(a, t) {
    return T(a.getUTCHours(), t.length);
  },
  // Minute
  m: function(a, t) {
    return T(a.getUTCMinutes(), t.length);
  },
  // Second
  s: function(a, t) {
    return T(a.getUTCSeconds(), t.length);
  },
  // Fraction of second
  S: function(a, t) {
    var r = t.length, e = a.getUTCMilliseconds(), o = Math.floor(e * Math.pow(10, r - 3));
    return T(o, t.length);
  }
};
const G = Ft;
var ee = {
  am: "am",
  pm: "pm",
  midnight: "midnight",
  noon: "noon",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  night: "night"
}, Qt = {
  // Era
  G: function(a, t, r) {
    var e = a.getUTCFullYear() > 0 ? 1 : 0;
    switch (t) {
      case "G":
      case "GG":
      case "GGG":
        return r.era(e, {
          width: "abbreviated"
        });
      case "GGGGG":
        return r.era(e, {
          width: "narrow"
        });
      case "GGGG":
      default:
        return r.era(e, {
          width: "wide"
        });
    }
  },
  // Year
  y: function(a, t, r) {
    if (t === "yo") {
      var e = a.getUTCFullYear(), o = e > 0 ? e : 1 - e;
      return r.ordinalNumber(o, {
        unit: "year"
      });
    }
    return G.y(a, t);
  },
  // Local week-numbering year
  Y: function(a, t, r, e) {
    var o = xe(a, e), i = o > 0 ? o : 1 - o;
    if (t === "YY") {
      var u = i % 100;
      return T(u, 2);
    }
    return t === "Yo" ? r.ordinalNumber(i, {
      unit: "year"
    }) : T(i, t.length);
  },
  // ISO week-numbering year
  R: function(a, t) {
    var r = Je(a);
    return T(r, t.length);
  },
  // Extended year. This is a single number designating the year of this calendar system.
  // The main difference between `y` and `u` localizers are B.C. years:
  // | Year | `y` | `u` |
  // |------|-----|-----|
  // | AC 1 |   1 |   1 |
  // | BC 1 |   1 |   0 |
  // | BC 2 |   2 |  -1 |
  // Also `yy` always returns the last two digits of a year,
  // while `uu` pads single digit years to 2 characters and returns other years unchanged.
  u: function(a, t) {
    var r = a.getUTCFullYear();
    return T(r, t.length);
  },
  // Quarter
  Q: function(a, t, r) {
    var e = Math.ceil((a.getUTCMonth() + 1) / 3);
    switch (t) {
      case "Q":
        return String(e);
      case "QQ":
        return T(e, 2);
      case "Qo":
        return r.ordinalNumber(e, {
          unit: "quarter"
        });
      case "QQQ":
        return r.quarter(e, {
          width: "abbreviated",
          context: "formatting"
        });
      case "QQQQQ":
        return r.quarter(e, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return r.quarter(e, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone quarter
  q: function(a, t, r) {
    var e = Math.ceil((a.getUTCMonth() + 1) / 3);
    switch (t) {
      case "q":
        return String(e);
      case "qq":
        return T(e, 2);
      case "qo":
        return r.ordinalNumber(e, {
          unit: "quarter"
        });
      case "qqq":
        return r.quarter(e, {
          width: "abbreviated",
          context: "standalone"
        });
      case "qqqqq":
        return r.quarter(e, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return r.quarter(e, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Month
  M: function(a, t, r) {
    var e = a.getUTCMonth();
    switch (t) {
      case "M":
      case "MM":
        return G.M(a, t);
      case "Mo":
        return r.ordinalNumber(e + 1, {
          unit: "month"
        });
      case "MMM":
        return r.month(e, {
          width: "abbreviated",
          context: "formatting"
        });
      case "MMMMM":
        return r.month(e, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return r.month(e, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone month
  L: function(a, t, r) {
    var e = a.getUTCMonth();
    switch (t) {
      case "L":
        return String(e + 1);
      case "LL":
        return T(e + 1, 2);
      case "Lo":
        return r.ordinalNumber(e + 1, {
          unit: "month"
        });
      case "LLL":
        return r.month(e, {
          width: "abbreviated",
          context: "standalone"
        });
      case "LLLLL":
        return r.month(e, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return r.month(e, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Local week of year
  w: function(a, t, r, e) {
    var o = tt(a, e);
    return t === "wo" ? r.ordinalNumber(o, {
      unit: "week"
    }) : T(o, t.length);
  },
  // ISO week of year
  I: function(a, t, r) {
    var e = et(a);
    return t === "Io" ? r.ordinalNumber(e, {
      unit: "week"
    }) : T(e, t.length);
  },
  // Day of the month
  d: function(a, t, r) {
    return t === "do" ? r.ordinalNumber(a.getUTCDate(), {
      unit: "date"
    }) : G.d(a, t);
  },
  // Day of year
  D: function(a, t, r) {
    var e = Lt(a);
    return t === "Do" ? r.ordinalNumber(e, {
      unit: "dayOfYear"
    }) : T(e, t.length);
  },
  // Day of week
  E: function(a, t, r) {
    var e = a.getUTCDay();
    switch (t) {
      case "E":
      case "EE":
      case "EEE":
        return r.day(e, {
          width: "abbreviated",
          context: "formatting"
        });
      case "EEEEE":
        return r.day(e, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return r.day(e, {
          width: "short",
          context: "formatting"
        });
      case "EEEE":
      default:
        return r.day(e, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Local day of week
  e: function(a, t, r, e) {
    var o = a.getUTCDay(), i = (o - e.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "e":
        return String(i);
      case "ee":
        return T(i, 2);
      case "eo":
        return r.ordinalNumber(i, {
          unit: "day"
        });
      case "eee":
        return r.day(o, {
          width: "abbreviated",
          context: "formatting"
        });
      case "eeeee":
        return r.day(o, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return r.day(o, {
          width: "short",
          context: "formatting"
        });
      case "eeee":
      default:
        return r.day(o, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone local day of week
  c: function(a, t, r, e) {
    var o = a.getUTCDay(), i = (o - e.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "c":
        return String(i);
      case "cc":
        return T(i, t.length);
      case "co":
        return r.ordinalNumber(i, {
          unit: "day"
        });
      case "ccc":
        return r.day(o, {
          width: "abbreviated",
          context: "standalone"
        });
      case "ccccc":
        return r.day(o, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return r.day(o, {
          width: "short",
          context: "standalone"
        });
      case "cccc":
      default:
        return r.day(o, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // ISO day of week
  i: function(a, t, r) {
    var e = a.getUTCDay(), o = e === 0 ? 7 : e;
    switch (t) {
      case "i":
        return String(o);
      case "ii":
        return T(o, t.length);
      case "io":
        return r.ordinalNumber(o, {
          unit: "day"
        });
      case "iii":
        return r.day(e, {
          width: "abbreviated",
          context: "formatting"
        });
      case "iiiii":
        return r.day(e, {
          width: "narrow",
          context: "formatting"
        });
      case "iiiiii":
        return r.day(e, {
          width: "short",
          context: "formatting"
        });
      case "iiii":
      default:
        return r.day(e, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM or PM
  a: function(a, t, r) {
    var e = a.getUTCHours(), o = e / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return r.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        });
      case "aaa":
        return r.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "aaaaa":
        return r.dayPeriod(o, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return r.dayPeriod(o, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM, PM, midnight, noon
  b: function(a, t, r) {
    var e = a.getUTCHours(), o;
    switch (e === 12 ? o = ee.noon : e === 0 ? o = ee.midnight : o = e / 12 >= 1 ? "pm" : "am", t) {
      case "b":
      case "bb":
        return r.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        });
      case "bbb":
        return r.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "bbbbb":
        return r.dayPeriod(o, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return r.dayPeriod(o, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // in the morning, in the afternoon, in the evening, at night
  B: function(a, t, r) {
    var e = a.getUTCHours(), o;
    switch (e >= 17 ? o = ee.evening : e >= 12 ? o = ee.afternoon : e >= 4 ? o = ee.morning : o = ee.night, t) {
      case "B":
      case "BB":
      case "BBB":
        return r.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        });
      case "BBBBB":
        return r.dayPeriod(o, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return r.dayPeriod(o, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Hour [1-12]
  h: function(a, t, r) {
    if (t === "ho") {
      var e = a.getUTCHours() % 12;
      return e === 0 && (e = 12), r.ordinalNumber(e, {
        unit: "hour"
      });
    }
    return G.h(a, t);
  },
  // Hour [0-23]
  H: function(a, t, r) {
    return t === "Ho" ? r.ordinalNumber(a.getUTCHours(), {
      unit: "hour"
    }) : G.H(a, t);
  },
  // Hour [0-11]
  K: function(a, t, r) {
    var e = a.getUTCHours() % 12;
    return t === "Ko" ? r.ordinalNumber(e, {
      unit: "hour"
    }) : T(e, t.length);
  },
  // Hour [1-24]
  k: function(a, t, r) {
    var e = a.getUTCHours();
    return e === 0 && (e = 24), t === "ko" ? r.ordinalNumber(e, {
      unit: "hour"
    }) : T(e, t.length);
  },
  // Minute
  m: function(a, t, r) {
    return t === "mo" ? r.ordinalNumber(a.getUTCMinutes(), {
      unit: "minute"
    }) : G.m(a, t);
  },
  // Second
  s: function(a, t, r) {
    return t === "so" ? r.ordinalNumber(a.getUTCSeconds(), {
      unit: "second"
    }) : G.s(a, t);
  },
  // Fraction of second
  S: function(a, t) {
    return G.S(a, t);
  },
  // Timezone (ISO-8601. If offset is 0, output is always `'Z'`)
  X: function(a, t, r, e) {
    var o = e._originalDate || a, i = o.getTimezoneOffset();
    if (i === 0)
      return "Z";
    switch (t) {
      case "X":
        return qe(i);
      case "XXXX":
      case "XX":
        return K(i);
      case "XXXXX":
      case "XXX":
      default:
        return K(i, ":");
    }
  },
  // Timezone (ISO-8601. If offset is 0, output is `'+00:00'` or equivalent)
  x: function(a, t, r, e) {
    var o = e._originalDate || a, i = o.getTimezoneOffset();
    switch (t) {
      case "x":
        return qe(i);
      case "xxxx":
      case "xx":
        return K(i);
      case "xxxxx":
      case "xxx":
      default:
        return K(i, ":");
    }
  },
  // Timezone (GMT)
  O: function(a, t, r, e) {
    var o = e._originalDate || a, i = o.getTimezoneOffset();
    switch (t) {
      case "O":
      case "OO":
      case "OOO":
        return "GMT" + Re(i, ":");
      case "OOOO":
      default:
        return "GMT" + K(i, ":");
    }
  },
  // Timezone (specific non-location)
  z: function(a, t, r, e) {
    var o = e._originalDate || a, i = o.getTimezoneOffset();
    switch (t) {
      case "z":
      case "zz":
      case "zzz":
        return "GMT" + Re(i, ":");
      case "zzzz":
      default:
        return "GMT" + K(i, ":");
    }
  },
  // Seconds timestamp
  t: function(a, t, r, e) {
    var o = e._originalDate || a, i = Math.floor(o.getTime() / 1e3);
    return T(i, t.length);
  },
  // Milliseconds timestamp
  T: function(a, t, r, e) {
    var o = e._originalDate || a, i = o.getTime();
    return T(i, t.length);
  }
};
function Re(n, a) {
  var t = n > 0 ? "-" : "+", r = Math.abs(n), e = Math.floor(r / 60), o = r % 60;
  if (o === 0)
    return t + String(e);
  var i = a || "";
  return t + String(e) + i + T(o, 2);
}
function qe(n, a) {
  if (n % 60 === 0) {
    var t = n > 0 ? "-" : "+";
    return t + T(Math.abs(n) / 60, 2);
  }
  return K(n, a);
}
function K(n, a) {
  var t = a || "", r = n > 0 ? "-" : "+", e = Math.abs(n), o = T(Math.floor(e / 60), 2), i = T(e % 60, 2);
  return r + o + t + i;
}
const Bt = Qt;
var Se = function(a, t) {
  switch (a) {
    case "P":
      return t.date({
        width: "short"
      });
    case "PP":
      return t.date({
        width: "medium"
      });
    case "PPP":
      return t.date({
        width: "long"
      });
    case "PPPP":
    default:
      return t.date({
        width: "full"
      });
  }
}, rt = function(a, t) {
  switch (a) {
    case "p":
      return t.time({
        width: "short"
      });
    case "pp":
      return t.time({
        width: "medium"
      });
    case "ppp":
      return t.time({
        width: "long"
      });
    case "pppp":
    default:
      return t.time({
        width: "full"
      });
  }
}, Xt = function(a, t) {
  var r = a.match(/(P+)(p+)?/) || [], e = r[1], o = r[2];
  if (!o)
    return Se(a, t);
  var i;
  switch (e) {
    case "P":
      i = t.dateTime({
        width: "short"
      });
      break;
    case "PP":
      i = t.dateTime({
        width: "medium"
      });
      break;
    case "PPP":
      i = t.dateTime({
        width: "long"
      });
      break;
    case "PPPP":
    default:
      i = t.dateTime({
        width: "full"
      });
      break;
  }
  return i.replace("{{date}}", Se(e, t)).replace("{{time}}", rt(o, t));
}, jt = {
  p: rt,
  P: Xt
};
const Me = jt;
var Gt = ["D", "DD"], Vt = ["YY", "YYYY"];
function nt(n) {
  return Gt.indexOf(n) !== -1;
}
function at(n) {
  return Vt.indexOf(n) !== -1;
}
function we(n, a, t) {
  if (n === "YYYY")
    throw new RangeError("Use `yyyy` instead of `YYYY` (in `".concat(a, "`) for formatting years to the input `").concat(t, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
  if (n === "YY")
    throw new RangeError("Use `yy` instead of `YY` (in `".concat(a, "`) for formatting years to the input `").concat(t, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
  if (n === "D")
    throw new RangeError("Use `d` instead of `D` (in `".concat(a, "`) for formatting days of the month to the input `").concat(t, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
  if (n === "DD")
    throw new RangeError("Use `dd` instead of `DD` (in `".concat(a, "`) for formatting days of the month to the input `").concat(t, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
}
var zt = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "1 second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "1 hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "1 day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "1 year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years"
  }
}, Kt = function(a, t, r) {
  var e, o = zt[a];
  return typeof o == "string" ? e = o : t === 1 ? e = o.one : e = o.other.replace("{{count}}", t.toString()), r != null && r.addSuffix ? r.comparison && r.comparison > 0 ? "in " + e : e + " ago" : e;
};
const it = Kt;
function te(n) {
  return function() {
    var a = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, t = a.width ? String(a.width) : n.defaultWidth, r = n.formats[t] || n.formats[n.defaultWidth];
    return r;
  };
}
var Zt = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
}, Jt = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
}, er = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
}, tr = {
  date: te({
    formats: Zt,
    defaultWidth: "full"
  }),
  time: te({
    formats: Jt,
    defaultWidth: "full"
  }),
  dateTime: te({
    formats: er,
    defaultWidth: "full"
  })
};
const rr = tr;
var nr = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P"
}, ar = function(a, t, r, e) {
  return nr[a];
};
const ot = ar;
function le(n) {
  return function(a, t) {
    var r = t != null && t.context ? String(t.context) : "standalone", e;
    if (r === "formatting" && n.formattingValues) {
      var o = n.defaultFormattingWidth || n.defaultWidth, i = t != null && t.width ? String(t.width) : o;
      e = n.formattingValues[i] || n.formattingValues[o];
    } else {
      var u = n.defaultWidth, s = t != null && t.width ? String(t.width) : n.defaultWidth;
      e = n.values[s] || n.values[u];
    }
    var f = n.argumentCallback ? n.argumentCallback(a) : a;
    return e[f];
  };
}
var ir = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
}, or = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
}, ur = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  wide: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
}, sr = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
}, lr = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
}, cr = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
}, fr = function(a, t) {
  var r = Number(a), e = r % 100;
  if (e > 20 || e < 10)
    switch (e % 10) {
      case 1:
        return r + "st";
      case 2:
        return r + "nd";
      case 3:
        return r + "rd";
    }
  return r + "th";
}, dr = {
  ordinalNumber: fr,
  era: le({
    values: ir,
    defaultWidth: "wide"
  }),
  quarter: le({
    values: or,
    defaultWidth: "wide",
    argumentCallback: function(a) {
      return a - 1;
    }
  }),
  month: le({
    values: ur,
    defaultWidth: "wide"
  }),
  day: le({
    values: sr,
    defaultWidth: "wide"
  }),
  dayPeriod: le({
    values: lr,
    defaultWidth: "wide",
    formattingValues: cr,
    defaultFormattingWidth: "wide"
  })
};
const ut = dr;
function ce(n) {
  return function(a) {
    var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, r = t.width, e = r && n.matchPatterns[r] || n.matchPatterns[n.defaultMatchWidth], o = a.match(e);
    if (!o)
      return null;
    var i = o[0], u = r && n.parsePatterns[r] || n.parsePatterns[n.defaultParseWidth], s = Array.isArray(u) ? hr(u, function(d) {
      return d.test(i);
    }) : vr(u, function(d) {
      return d.test(i);
    }), f;
    f = n.valueCallback ? n.valueCallback(s) : s, f = t.valueCallback ? t.valueCallback(f) : f;
    var v = a.slice(i.length);
    return {
      value: f,
      rest: v
    };
  };
}
function vr(n, a) {
  for (var t in n)
    if (n.hasOwnProperty(t) && a(n[t]))
      return t;
}
function hr(n, a) {
  for (var t = 0; t < n.length; t++)
    if (a(n[t]))
      return t;
}
function mr(n) {
  return function(a) {
    var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, r = a.match(n.matchPattern);
    if (!r)
      return null;
    var e = r[0], o = a.match(n.parsePattern);
    if (!o)
      return null;
    var i = n.valueCallback ? n.valueCallback(o[0]) : o[0];
    i = t.valueCallback ? t.valueCallback(i) : i;
    var u = a.slice(e.length);
    return {
      value: i,
      rest: u
    };
  };
}
var wr = /^(\d+)(th|st|nd|rd)?/i, gr = /\d+/i, yr = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
}, pr = {
  any: [/^b/i, /^(a|c)/i]
}, br = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
}, Tr = {
  any: [/1/i, /2/i, /3/i, /4/i]
}, Dr = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
}, Mr = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^may/i, /^jun/i, /^jul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
}, Cr = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
}, Pr = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
}, xr = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
}, Or = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
}, kr = {
  ordinalNumber: mr({
    matchPattern: wr,
    parsePattern: gr,
    valueCallback: function(a) {
      return parseInt(a, 10);
    }
  }),
  era: ce({
    matchPatterns: yr,
    defaultMatchWidth: "wide",
    parsePatterns: pr,
    defaultParseWidth: "any"
  }),
  quarter: ce({
    matchPatterns: br,
    defaultMatchWidth: "wide",
    parsePatterns: Tr,
    defaultParseWidth: "any",
    valueCallback: function(a) {
      return a + 1;
    }
  }),
  month: ce({
    matchPatterns: Dr,
    defaultMatchWidth: "wide",
    parsePatterns: Mr,
    defaultParseWidth: "any"
  }),
  day: ce({
    matchPatterns: Cr,
    defaultMatchWidth: "wide",
    parsePatterns: Pr,
    defaultParseWidth: "any"
  }),
  dayPeriod: ce({
    matchPatterns: xr,
    defaultMatchWidth: "any",
    parsePatterns: Or,
    defaultParseWidth: "any"
  })
};
const st = kr;
var Er = {
  code: "en-US",
  formatDistance: it,
  formatLong: rr,
  formatRelative: ot,
  localize: ut,
  match: st,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
const Oe = Er;
var Ur = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, _r = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, Yr = /^'([^]*?)'?$/, Wr = /''/g, Nr = /[a-zA-Z]/;
function ke(n, a, t) {
  var r, e, o, i, u, s, f, v, d, M, h, C, W, Y, H, L, R, V;
  D(2, arguments);
  var ae = String(a), N = J(), q = (r = (e = t == null ? void 0 : t.locale) !== null && e !== void 0 ? e : N.locale) !== null && r !== void 0 ? r : Oe, Q = I((o = (i = (u = (s = t == null ? void 0 : t.firstWeekContainsDate) !== null && s !== void 0 ? s : t == null || (f = t.locale) === null || f === void 0 || (v = f.options) === null || v === void 0 ? void 0 : v.firstWeekContainsDate) !== null && u !== void 0 ? u : N.firstWeekContainsDate) !== null && i !== void 0 ? i : (d = N.locale) === null || d === void 0 || (M = d.options) === null || M === void 0 ? void 0 : M.firstWeekContainsDate) !== null && o !== void 0 ? o : 1);
  if (!(Q >= 1 && Q <= 7))
    throw new RangeError("firstWeekContainsDate must be between 1 and 7 inclusively");
  var X = I((h = (C = (W = (Y = t == null ? void 0 : t.weekStartsOn) !== null && Y !== void 0 ? Y : t == null || (H = t.locale) === null || H === void 0 || (L = H.options) === null || L === void 0 ? void 0 : L.weekStartsOn) !== null && W !== void 0 ? W : N.weekStartsOn) !== null && C !== void 0 ? C : (R = N.locale) === null || R === void 0 || (V = R.options) === null || V === void 0 ? void 0 : V.weekStartsOn) !== null && h !== void 0 ? h : 0);
  if (!(X >= 0 && X <= 6))
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  if (!q.localize)
    throw new RangeError("locale must contain localize property");
  if (!q.formatLong)
    throw new RangeError("locale must contain formatLong property");
  var j = p(n);
  if (!Ot(j))
    throw new RangeError("Invalid time value");
  var ie = me(j), oe = Ze(j, ie), ue = {
    firstWeekContainsDate: Q,
    weekStartsOn: X,
    locale: q,
    _originalDate: j
  }, pe = ae.match(_r).map(function(_) {
    var A = _[0];
    if (A === "p" || A === "P") {
      var z = Me[A];
      return z(_, q.formatLong);
    }
    return _;
  }).join("").match(Ur).map(function(_) {
    if (_ === "''")
      return "'";
    var A = _[0];
    if (A === "'")
      return Ir(_);
    var z = Bt[A];
    if (z)
      return !(t != null && t.useAdditionalWeekYearTokens) && at(_) && we(_, a, String(n)), !(t != null && t.useAdditionalDayOfYearTokens) && nt(_) && we(_, a, String(n)), z(oe, _, q.localize, ue);
    if (A.match(Nr))
      throw new RangeError("Format string contains an unescaped latin alphabet character `" + A + "`");
    return _;
  }).join("");
  return pe;
}
function Ir(n) {
  var a = n.match(Yr);
  return a ? a[1].replace(Wr, "'") : n;
}
function Ee(n, a) {
  if (n == null)
    throw new TypeError("assign requires that input parameter not be null or undefined");
  for (var t in a)
    Object.prototype.hasOwnProperty.call(a, t) && (n[t] = a[t]);
  return n;
}
function Hr(n) {
  return Ee({}, n);
}
var Fe = 1440, Ar = 2520, De = 43200, Lr = 86400;
function $r(n, a, t) {
  var r, e;
  D(2, arguments);
  var o = J(), i = (r = (e = t == null ? void 0 : t.locale) !== null && e !== void 0 ? e : o.locale) !== null && r !== void 0 ? r : Oe;
  if (!i.formatDistance)
    throw new RangeError("locale must contain formatDistance property");
  var u = he(n, a);
  if (isNaN(u))
    throw new RangeError("Invalid time value");
  var s = Ee(Hr(t), {
    addSuffix: !!(t != null && t.addSuffix),
    comparison: u
  }), f, v;
  u > 0 ? (f = p(a), v = p(n)) : (f = p(n), v = p(a));
  var d = Ht(v, f), M = (me(v) - me(f)) / 1e3, h = Math.round((d - M) / 60), C;
  if (h < 2)
    return t != null && t.includeSeconds ? d < 5 ? i.formatDistance("lessThanXSeconds", 5, s) : d < 10 ? i.formatDistance("lessThanXSeconds", 10, s) : d < 20 ? i.formatDistance("lessThanXSeconds", 20, s) : d < 40 ? i.formatDistance("halfAMinute", 0, s) : d < 60 ? i.formatDistance("lessThanXMinutes", 1, s) : i.formatDistance("xMinutes", 1, s) : h === 0 ? i.formatDistance("lessThanXMinutes", 1, s) : i.formatDistance("xMinutes", h, s);
  if (h < 45)
    return i.formatDistance("xMinutes", h, s);
  if (h < 90)
    return i.formatDistance("aboutXHours", 1, s);
  if (h < Fe) {
    var W = Math.round(h / 60);
    return i.formatDistance("aboutXHours", W, s);
  } else {
    if (h < Ar)
      return i.formatDistance("xDays", 1, s);
    if (h < De) {
      var Y = Math.round(h / Fe);
      return i.formatDistance("xDays", Y, s);
    } else if (h < Lr)
      return C = Math.round(h / De), i.formatDistance("aboutXMonths", C, s);
  }
  if (C = It(v, f), C < 12) {
    var H = Math.round(h / De);
    return i.formatDistance("xMonths", H, s);
  } else {
    var L = C % 12, R = Math.floor(C / 12);
    return L < 3 ? i.formatDistance("aboutXYears", R, s) : L < 9 ? i.formatDistance("overXYears", R, s) : i.formatDistance("almostXYears", R + 1, s);
  }
}
function Qe(n, a) {
  (a == null || a > n.length) && (a = n.length);
  for (var t = 0, r = Array(a); t < a; t++)
    r[t] = n[t];
  return r;
}
function Rr(n, a) {
  if (n) {
    if (typeof n == "string")
      return Qe(n, a);
    var t = {}.toString.call(n).slice(8, -1);
    return t === "Object" && n.constructor && (t = n.constructor.name), t === "Map" || t === "Set" ? Array.from(n) : t === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? Qe(n, a) : void 0;
  }
}
function Be(n, a) {
  var t = typeof Symbol < "u" && n[Symbol.iterator] || n["@@iterator"];
  if (!t) {
    if (Array.isArray(n) || (t = Rr(n)) || a && n && typeof n.length == "number") {
      t && (n = t);
      var r = 0, e = function() {
      };
      return {
        s: e,
        n: function() {
          return r >= n.length ? {
            done: !0
          } : {
            done: !1,
            value: n[r++]
          };
        },
        e: function(f) {
          throw f;
        },
        f: e
      };
    }
    throw new TypeError(`Invalid attempt to iterate non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
  }
  var o, i = !0, u = !1;
  return {
    s: function() {
      t = t.call(n);
    },
    n: function() {
      var f = t.next();
      return i = f.done, f;
    },
    e: function(f) {
      u = !0, o = f;
    },
    f: function() {
      try {
        i || t.return == null || t.return();
      } finally {
        if (u)
          throw o;
      }
    }
  };
}
function c(n) {
  if (n === void 0)
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  return n;
}
function Ce(n, a) {
  return Ce = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function(t, r) {
    return t.__proto__ = r, t;
  }, Ce(n, a);
}
function g(n, a) {
  if (typeof a != "function" && a !== null)
    throw new TypeError("Super expression must either be null or a function");
  n.prototype = Object.create(a && a.prototype, {
    constructor: {
      value: n,
      writable: !0,
      configurable: !0
    }
  }), Object.defineProperty(n, "prototype", {
    writable: !1
  }), a && Ce(n, a);
}
function ge(n) {
  return ge = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function(a) {
    return a.__proto__ || Object.getPrototypeOf(a);
  }, ge(n);
}
function lt() {
  try {
    var n = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function() {
    }));
  } catch {
  }
  return (lt = function() {
    return !!n;
  })();
}
function qr(n, a) {
  if (a && (B(a) == "object" || typeof a == "function"))
    return a;
  if (a !== void 0)
    throw new TypeError("Derived constructors may only return object or undefined");
  return c(n);
}
function y(n) {
  var a = lt();
  return function() {
    var t, r = ge(n);
    if (a) {
      var e = ge(this).constructor;
      t = Reflect.construct(r, arguments, e);
    } else
      t = r.apply(this, arguments);
    return qr(this, t);
  };
}
function m(n, a) {
  if (!(n instanceof a))
    throw new TypeError("Cannot call a class as a function");
}
function Sr(n, a) {
  if (B(n) != "object" || !n)
    return n;
  var t = n[Symbol.toPrimitive];
  if (t !== void 0) {
    var r = t.call(n, a || "default");
    if (B(r) != "object")
      return r;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (a === "string" ? String : Number)(n);
}
function ct(n) {
  var a = Sr(n, "string");
  return B(a) == "symbol" ? a : a + "";
}
function Xe(n, a) {
  for (var t = 0; t < a.length; t++) {
    var r = a[t];
    r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(n, ct(r.key), r);
  }
}
function w(n, a, t) {
  return a && Xe(n.prototype, a), t && Xe(n, t), Object.defineProperty(n, "prototype", {
    writable: !1
  }), n;
}
function l(n, a, t) {
  return (a = ct(a)) in n ? Object.defineProperty(n, a, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : n[a] = t, n;
}
var Fr = 10, ft = /* @__PURE__ */ function() {
  function n() {
    m(this, n), l(this, "priority", void 0), l(this, "subPriority", 0);
  }
  return w(n, [{
    key: "validate",
    value: function(t, r) {
      return !0;
    }
  }]), n;
}(), Qr = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t(r, e, o, i, u) {
    var s;
    return m(this, t), s = a.call(this), s.value = r, s.validateValue = e, s.setValue = o, s.priority = i, u && (s.subPriority = u), s;
  }
  return w(t, [{
    key: "validate",
    value: function(e, o) {
      return this.validateValue(e, this.value, o);
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return this.setValue(e, o, this.value, i);
    }
  }]), t;
}(ft), Br = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", Fr), l(c(r), "subPriority", -1), r;
  }
  return w(t, [{
    key: "set",
    value: function(e, o) {
      if (o.timestampIsSet)
        return e;
      var i = /* @__PURE__ */ new Date(0);
      return i.setFullYear(e.getUTCFullYear(), e.getUTCMonth(), e.getUTCDate()), i.setHours(e.getUTCHours(), e.getUTCMinutes(), e.getUTCSeconds(), e.getUTCMilliseconds()), i;
    }
  }]), t;
}(ft), b = /* @__PURE__ */ function() {
  function n() {
    m(this, n), l(this, "incompatibleTokens", void 0), l(this, "priority", void 0), l(this, "subPriority", void 0);
  }
  return w(n, [{
    key: "run",
    value: function(t, r, e, o) {
      var i = this.parse(t, r, e, o);
      return i ? {
        setter: new Qr(i.value, this.validate, this.set, this.priority, this.subPriority),
        rest: i.rest
      } : null;
    }
  }, {
    key: "validate",
    value: function(t, r, e) {
      return !0;
    }
  }]), n;
}(), Xr = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 140), l(c(r), "incompatibleTokens", ["R", "u", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "G":
        case "GG":
        case "GGG":
          return i.era(e, {
            width: "abbreviated"
          }) || i.era(e, {
            width: "narrow"
          });
        case "GGGGG":
          return i.era(e, {
            width: "narrow"
          });
        case "GGGG":
        default:
          return i.era(e, {
            width: "wide"
          }) || i.era(e, {
            width: "abbreviated"
          }) || i.era(e, {
            width: "narrow"
          });
      }
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return o.era = i, e.setUTCFullYear(i, 0, 1), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), k = {
  month: /^(1[0-2]|0?\d)/,
  // 0 to 12
  date: /^(3[0-1]|[0-2]?\d)/,
  // 0 to 31
  dayOfYear: /^(36[0-6]|3[0-5]\d|[0-2]?\d?\d)/,
  // 0 to 366
  week: /^(5[0-3]|[0-4]?\d)/,
  // 0 to 53
  hour23h: /^(2[0-3]|[0-1]?\d)/,
  // 0 to 23
  hour24h: /^(2[0-4]|[0-1]?\d)/,
  // 0 to 24
  hour11h: /^(1[0-1]|0?\d)/,
  // 0 to 11
  hour12h: /^(1[0-2]|0?\d)/,
  // 0 to 12
  minute: /^[0-5]?\d/,
  // 0 to 59
  second: /^[0-5]?\d/,
  // 0 to 59
  singleDigit: /^\d/,
  // 0 to 9
  twoDigits: /^\d{1,2}/,
  // 0 to 99
  threeDigits: /^\d{1,3}/,
  // 0 to 999
  fourDigits: /^\d{1,4}/,
  // 0 to 9999
  anyDigitsSigned: /^-?\d+/,
  singleDigitSigned: /^-?\d/,
  // 0 to 9, -0 to -9
  twoDigitsSigned: /^-?\d{1,2}/,
  // 0 to 99, -0 to -99
  threeDigitsSigned: /^-?\d{1,3}/,
  // 0 to 999, -0 to -999
  fourDigitsSigned: /^-?\d{1,4}/
  // 0 to 9999, -0 to -9999
}, S = {
  basicOptionalMinutes: /^([+-])(\d{2})(\d{2})?|Z/,
  basic: /^([+-])(\d{2})(\d{2})|Z/,
  basicOptionalSeconds: /^([+-])(\d{2})(\d{2})((\d{2}))?|Z/,
  extended: /^([+-])(\d{2}):(\d{2})|Z/,
  extendedOptionalSeconds: /^([+-])(\d{2}):(\d{2})(:(\d{2}))?|Z/
};
function E(n, a) {
  return n && {
    value: a(n.value),
    rest: n.rest
  };
}
function x(n, a) {
  var t = a.match(n);
  return t ? {
    value: parseInt(t[0], 10),
    rest: a.slice(t[0].length)
  } : null;
}
function F(n, a) {
  var t = a.match(n);
  if (!t)
    return null;
  if (t[0] === "Z")
    return {
      value: 0,
      rest: a.slice(1)
    };
  var r = t[1] === "+" ? 1 : -1, e = t[2] ? parseInt(t[2], 10) : 0, o = t[3] ? parseInt(t[3], 10) : 0, i = t[5] ? parseInt(t[5], 10) : 0;
  return {
    value: r * (e * Ct + o * Mt + i * Pt),
    rest: a.slice(t[0].length)
  };
}
function dt(n) {
  return x(k.anyDigitsSigned, n);
}
function O(n, a) {
  switch (n) {
    case 1:
      return x(k.singleDigit, a);
    case 2:
      return x(k.twoDigits, a);
    case 3:
      return x(k.threeDigits, a);
    case 4:
      return x(k.fourDigits, a);
    default:
      return x(new RegExp("^\\d{1," + n + "}"), a);
  }
}
function ye(n, a) {
  switch (n) {
    case 1:
      return x(k.singleDigitSigned, a);
    case 2:
      return x(k.twoDigitsSigned, a);
    case 3:
      return x(k.threeDigitsSigned, a);
    case 4:
      return x(k.fourDigitsSigned, a);
    default:
      return x(new RegExp("^-?\\d{1," + n + "}"), a);
  }
}
function Ue(n) {
  switch (n) {
    case "morning":
      return 4;
    case "evening":
      return 17;
    case "pm":
    case "noon":
    case "afternoon":
      return 12;
    case "am":
    case "midnight":
    case "night":
    default:
      return 0;
  }
}
function vt(n, a) {
  var t = a > 0, r = t ? a : 1 - a, e;
  if (r <= 50)
    e = n || 100;
  else {
    var o = r + 50, i = Math.floor(o / 100) * 100, u = n >= o % 100;
    e = n + i - (u ? 100 : 0);
  }
  return t ? e : 1 - e;
}
function ht(n) {
  return n % 400 === 0 || n % 4 === 0 && n % 100 !== 0;
}
var jr = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 130), l(c(r), "incompatibleTokens", ["Y", "R", "u", "w", "I", "i", "e", "c", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      var u = function(f) {
        return {
          year: f,
          isTwoDigitYear: o === "yy"
        };
      };
      switch (o) {
        case "y":
          return E(O(4, e), u);
        case "yo":
          return E(i.ordinalNumber(e, {
            unit: "year"
          }), u);
        default:
          return E(O(o.length, e), u);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o.isTwoDigitYear || o.year > 0;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      var u = e.getUTCFullYear();
      if (i.isTwoDigitYear) {
        var s = vt(i.year, u);
        return e.setUTCFullYear(s, 0, 1), e.setUTCHours(0, 0, 0, 0), e;
      }
      var f = !("era" in o) || o.era === 1 ? i.year : 1 - i.year;
      return e.setUTCFullYear(f, 0, 1), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), Gr = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 130), l(c(r), "incompatibleTokens", ["y", "R", "u", "Q", "q", "M", "L", "I", "d", "D", "i", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      var u = function(f) {
        return {
          year: f,
          isTwoDigitYear: o === "YY"
        };
      };
      switch (o) {
        case "Y":
          return E(O(4, e), u);
        case "Yo":
          return E(i.ordinalNumber(e, {
            unit: "year"
          }), u);
        default:
          return E(O(o.length, e), u);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o.isTwoDigitYear || o.year > 0;
    }
  }, {
    key: "set",
    value: function(e, o, i, u) {
      var s = xe(e, u);
      if (i.isTwoDigitYear) {
        var f = vt(i.year, s);
        return e.setUTCFullYear(f, 0, u.firstWeekContainsDate), e.setUTCHours(0, 0, 0, 0), Z(e, u);
      }
      var v = !("era" in o) || o.era === 1 ? i.year : 1 - i.year;
      return e.setUTCFullYear(v, 0, u.firstWeekContainsDate), e.setUTCHours(0, 0, 0, 0), Z(e, u);
    }
  }]), t;
}(b), Vr = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 130), l(c(r), "incompatibleTokens", ["G", "y", "Y", "u", "Q", "q", "M", "L", "w", "d", "D", "e", "c", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o) {
      return ye(o === "R" ? 4 : o.length, e);
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      var u = /* @__PURE__ */ new Date(0);
      return u.setUTCFullYear(i, 0, 4), u.setUTCHours(0, 0, 0, 0), ne(u);
    }
  }]), t;
}(b), zr = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 130), l(c(r), "incompatibleTokens", ["G", "y", "Y", "R", "w", "I", "i", "e", "c", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o) {
      return ye(o === "u" ? 4 : o.length, e);
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCFullYear(i, 0, 1), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), Kr = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 120), l(c(r), "incompatibleTokens", ["Y", "R", "q", "M", "L", "w", "I", "d", "D", "i", "e", "c", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "Q":
        case "QQ":
          return O(o.length, e);
        case "Qo":
          return i.ordinalNumber(e, {
            unit: "quarter"
          });
        case "QQQ":
          return i.quarter(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.quarter(e, {
            width: "narrow",
            context: "formatting"
          });
        case "QQQQQ":
          return i.quarter(e, {
            width: "narrow",
            context: "formatting"
          });
        case "QQQQ":
        default:
          return i.quarter(e, {
            width: "wide",
            context: "formatting"
          }) || i.quarter(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.quarter(e, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 1 && o <= 4;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCMonth((i - 1) * 3, 1), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), Zr = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 120), l(c(r), "incompatibleTokens", ["Y", "R", "Q", "M", "L", "w", "I", "d", "D", "i", "e", "c", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "q":
        case "qq":
          return O(o.length, e);
        case "qo":
          return i.ordinalNumber(e, {
            unit: "quarter"
          });
        case "qqq":
          return i.quarter(e, {
            width: "abbreviated",
            context: "standalone"
          }) || i.quarter(e, {
            width: "narrow",
            context: "standalone"
          });
        case "qqqqq":
          return i.quarter(e, {
            width: "narrow",
            context: "standalone"
          });
        case "qqqq":
        default:
          return i.quarter(e, {
            width: "wide",
            context: "standalone"
          }) || i.quarter(e, {
            width: "abbreviated",
            context: "standalone"
          }) || i.quarter(e, {
            width: "narrow",
            context: "standalone"
          });
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 1 && o <= 4;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCMonth((i - 1) * 3, 1), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), Jr = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "incompatibleTokens", ["Y", "R", "q", "Q", "L", "w", "I", "D", "i", "e", "c", "t", "T"]), l(c(r), "priority", 110), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      var u = function(f) {
        return f - 1;
      };
      switch (o) {
        case "M":
          return E(x(k.month, e), u);
        case "MM":
          return E(O(2, e), u);
        case "Mo":
          return E(i.ordinalNumber(e, {
            unit: "month"
          }), u);
        case "MMM":
          return i.month(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.month(e, {
            width: "narrow",
            context: "formatting"
          });
        case "MMMMM":
          return i.month(e, {
            width: "narrow",
            context: "formatting"
          });
        case "MMMM":
        default:
          return i.month(e, {
            width: "wide",
            context: "formatting"
          }) || i.month(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.month(e, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 11;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCMonth(i, 1), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), en = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 110), l(c(r), "incompatibleTokens", ["Y", "R", "q", "Q", "M", "w", "I", "D", "i", "e", "c", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      var u = function(f) {
        return f - 1;
      };
      switch (o) {
        case "L":
          return E(x(k.month, e), u);
        case "LL":
          return E(O(2, e), u);
        case "Lo":
          return E(i.ordinalNumber(e, {
            unit: "month"
          }), u);
        case "LLL":
          return i.month(e, {
            width: "abbreviated",
            context: "standalone"
          }) || i.month(e, {
            width: "narrow",
            context: "standalone"
          });
        case "LLLLL":
          return i.month(e, {
            width: "narrow",
            context: "standalone"
          });
        case "LLLL":
        default:
          return i.month(e, {
            width: "wide",
            context: "standalone"
          }) || i.month(e, {
            width: "abbreviated",
            context: "standalone"
          }) || i.month(e, {
            width: "narrow",
            context: "standalone"
          });
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 11;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCMonth(i, 1), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b);
function tn(n, a, t) {
  D(2, arguments);
  var r = p(n), e = I(a), o = tt(r, t) - e;
  return r.setUTCDate(r.getUTCDate() - o * 7), r;
}
var rn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 100), l(c(r), "incompatibleTokens", ["y", "R", "u", "q", "Q", "M", "L", "I", "d", "D", "i", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "w":
          return x(k.week, e);
        case "wo":
          return i.ordinalNumber(e, {
            unit: "week"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 1 && o <= 53;
    }
  }, {
    key: "set",
    value: function(e, o, i, u) {
      return Z(tn(e, i, u), u);
    }
  }]), t;
}(b);
function nn(n, a) {
  D(2, arguments);
  var t = p(n), r = I(a), e = et(t) - r;
  return t.setUTCDate(t.getUTCDate() - e * 7), t;
}
var an = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 100), l(c(r), "incompatibleTokens", ["y", "Y", "u", "q", "Q", "M", "L", "w", "d", "D", "e", "c", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "I":
          return x(k.week, e);
        case "Io":
          return i.ordinalNumber(e, {
            unit: "week"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 1 && o <= 53;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return ne(nn(e, i));
    }
  }]), t;
}(b), on = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], un = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], sn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 90), l(c(r), "subPriority", 1), l(c(r), "incompatibleTokens", ["Y", "R", "q", "Q", "w", "I", "D", "i", "e", "c", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "d":
          return x(k.date, e);
        case "do":
          return i.ordinalNumber(e, {
            unit: "date"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      var i = e.getUTCFullYear(), u = ht(i), s = e.getUTCMonth();
      return u ? o >= 1 && o <= un[s] : o >= 1 && o <= on[s];
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCDate(i), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), ln = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 90), l(c(r), "subpriority", 1), l(c(r), "incompatibleTokens", ["Y", "R", "q", "Q", "M", "L", "w", "I", "d", "E", "i", "e", "c", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "D":
        case "DD":
          return x(k.dayOfYear, e);
        case "Do":
          return i.ordinalNumber(e, {
            unit: "date"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      var i = e.getUTCFullYear(), u = ht(i);
      return u ? o >= 1 && o <= 366 : o >= 1 && o <= 365;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCMonth(0, i), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b);
function _e(n, a, t) {
  var r, e, o, i, u, s, f, v;
  D(2, arguments);
  var d = J(), M = I((r = (e = (o = (i = t == null ? void 0 : t.weekStartsOn) !== null && i !== void 0 ? i : t == null || (u = t.locale) === null || u === void 0 || (s = u.options) === null || s === void 0 ? void 0 : s.weekStartsOn) !== null && o !== void 0 ? o : d.weekStartsOn) !== null && e !== void 0 ? e : (f = d.locale) === null || f === void 0 || (v = f.options) === null || v === void 0 ? void 0 : v.weekStartsOn) !== null && r !== void 0 ? r : 0);
  if (!(M >= 0 && M <= 6))
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  var h = p(n), C = I(a), W = h.getUTCDay(), Y = C % 7, H = (Y + 7) % 7, L = (H < M ? 7 : 0) + C - W;
  return h.setUTCDate(h.getUTCDate() + L), h;
}
var cn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 90), l(c(r), "incompatibleTokens", ["D", "i", "e", "c", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "E":
        case "EE":
        case "EEE":
          return i.day(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          });
        case "EEEEE":
          return i.day(e, {
            width: "narrow",
            context: "formatting"
          });
        case "EEEEEE":
          return i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          });
        case "EEEE":
        default:
          return i.day(e, {
            width: "wide",
            context: "formatting"
          }) || i.day(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 6;
    }
  }, {
    key: "set",
    value: function(e, o, i, u) {
      return e = _e(e, i, u), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), fn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 90), l(c(r), "incompatibleTokens", ["y", "R", "u", "q", "Q", "M", "L", "I", "d", "D", "E", "i", "c", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i, u) {
      var s = function(v) {
        var d = Math.floor((v - 1) / 7) * 7;
        return (v + u.weekStartsOn + 6) % 7 + d;
      };
      switch (o) {
        case "e":
        case "ee":
          return E(O(o.length, e), s);
        case "eo":
          return E(i.ordinalNumber(e, {
            unit: "day"
          }), s);
        case "eee":
          return i.day(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          });
        case "eeeee":
          return i.day(e, {
            width: "narrow",
            context: "formatting"
          });
        case "eeeeee":
          return i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          });
        case "eeee":
        default:
          return i.day(e, {
            width: "wide",
            context: "formatting"
          }) || i.day(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 6;
    }
  }, {
    key: "set",
    value: function(e, o, i, u) {
      return e = _e(e, i, u), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), dn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 90), l(c(r), "incompatibleTokens", ["y", "R", "u", "q", "Q", "M", "L", "I", "d", "D", "E", "i", "e", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i, u) {
      var s = function(v) {
        var d = Math.floor((v - 1) / 7) * 7;
        return (v + u.weekStartsOn + 6) % 7 + d;
      };
      switch (o) {
        case "c":
        case "cc":
          return E(O(o.length, e), s);
        case "co":
          return E(i.ordinalNumber(e, {
            unit: "day"
          }), s);
        case "ccc":
          return i.day(e, {
            width: "abbreviated",
            context: "standalone"
          }) || i.day(e, {
            width: "short",
            context: "standalone"
          }) || i.day(e, {
            width: "narrow",
            context: "standalone"
          });
        case "ccccc":
          return i.day(e, {
            width: "narrow",
            context: "standalone"
          });
        case "cccccc":
          return i.day(e, {
            width: "short",
            context: "standalone"
          }) || i.day(e, {
            width: "narrow",
            context: "standalone"
          });
        case "cccc":
        default:
          return i.day(e, {
            width: "wide",
            context: "standalone"
          }) || i.day(e, {
            width: "abbreviated",
            context: "standalone"
          }) || i.day(e, {
            width: "short",
            context: "standalone"
          }) || i.day(e, {
            width: "narrow",
            context: "standalone"
          });
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 6;
    }
  }, {
    key: "set",
    value: function(e, o, i, u) {
      return e = _e(e, i, u), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b);
function vn(n, a) {
  D(2, arguments);
  var t = I(a);
  t % 7 === 0 && (t = t - 7);
  var r = 1, e = p(n), o = e.getUTCDay(), i = t % 7, u = (i + 7) % 7, s = (u < r ? 7 : 0) + t - o;
  return e.setUTCDate(e.getUTCDate() + s), e;
}
var hn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 90), l(c(r), "incompatibleTokens", ["y", "Y", "u", "q", "Q", "M", "L", "w", "d", "D", "E", "e", "c", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      var u = function(f) {
        return f === 0 ? 7 : f;
      };
      switch (o) {
        case "i":
        case "ii":
          return O(o.length, e);
        case "io":
          return i.ordinalNumber(e, {
            unit: "day"
          });
        case "iii":
          return E(i.day(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          }), u);
        case "iiiii":
          return E(i.day(e, {
            width: "narrow",
            context: "formatting"
          }), u);
        case "iiiiii":
          return E(i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          }), u);
        case "iiii":
        default:
          return E(i.day(e, {
            width: "wide",
            context: "formatting"
          }) || i.day(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          }), u);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 1 && o <= 7;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e = vn(e, i), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), mn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 80), l(c(r), "incompatibleTokens", ["b", "B", "H", "k", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "a":
        case "aa":
        case "aaa":
          return i.dayPeriod(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
        case "aaaaa":
          return i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
        case "aaaa":
        default:
          return i.dayPeriod(e, {
            width: "wide",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCHours(Ue(i), 0, 0, 0), e;
    }
  }]), t;
}(b), wn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 80), l(c(r), "incompatibleTokens", ["a", "B", "H", "k", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "b":
        case "bb":
        case "bbb":
          return i.dayPeriod(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
        case "bbbbb":
          return i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
        case "bbbb":
        default:
          return i.dayPeriod(e, {
            width: "wide",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCHours(Ue(i), 0, 0, 0), e;
    }
  }]), t;
}(b), gn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 80), l(c(r), "incompatibleTokens", ["a", "b", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "B":
        case "BB":
        case "BBB":
          return i.dayPeriod(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
        case "BBBBB":
          return i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
        case "BBBB":
        default:
          return i.dayPeriod(e, {
            width: "wide",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCHours(Ue(i), 0, 0, 0), e;
    }
  }]), t;
}(b), yn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 70), l(c(r), "incompatibleTokens", ["H", "K", "k", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "h":
          return x(k.hour12h, e);
        case "ho":
          return i.ordinalNumber(e, {
            unit: "hour"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 1 && o <= 12;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      var u = e.getUTCHours() >= 12;
      return u && i < 12 ? e.setUTCHours(i + 12, 0, 0, 0) : !u && i === 12 ? e.setUTCHours(0, 0, 0, 0) : e.setUTCHours(i, 0, 0, 0), e;
    }
  }]), t;
}(b), pn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 70), l(c(r), "incompatibleTokens", ["a", "b", "h", "K", "k", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "H":
          return x(k.hour23h, e);
        case "Ho":
          return i.ordinalNumber(e, {
            unit: "hour"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 23;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCHours(i, 0, 0, 0), e;
    }
  }]), t;
}(b), bn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 70), l(c(r), "incompatibleTokens", ["h", "H", "k", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "K":
          return x(k.hour11h, e);
        case "Ko":
          return i.ordinalNumber(e, {
            unit: "hour"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 11;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      var u = e.getUTCHours() >= 12;
      return u && i < 12 ? e.setUTCHours(i + 12, 0, 0, 0) : e.setUTCHours(i, 0, 0, 0), e;
    }
  }]), t;
}(b), Tn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 70), l(c(r), "incompatibleTokens", ["a", "b", "h", "H", "K", "t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "k":
          return x(k.hour24h, e);
        case "ko":
          return i.ordinalNumber(e, {
            unit: "hour"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 1 && o <= 24;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      var u = i <= 24 ? i % 24 : i;
      return e.setUTCHours(u, 0, 0, 0), e;
    }
  }]), t;
}(b), Dn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 60), l(c(r), "incompatibleTokens", ["t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "m":
          return x(k.minute, e);
        case "mo":
          return i.ordinalNumber(e, {
            unit: "minute"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 59;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCMinutes(i, 0, 0), e;
    }
  }]), t;
}(b), Mn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 50), l(c(r), "incompatibleTokens", ["t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "s":
          return x(k.second, e);
        case "so":
          return i.ordinalNumber(e, {
            unit: "second"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 59;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCSeconds(i, 0), e;
    }
  }]), t;
}(b), Cn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 30), l(c(r), "incompatibleTokens", ["t", "T"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o) {
      var i = function(s) {
        return Math.floor(s * Math.pow(10, -o.length + 3));
      };
      return E(O(o.length, e), i);
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCMilliseconds(i), e;
    }
  }]), t;
}(b), Pn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 10), l(c(r), "incompatibleTokens", ["t", "T", "x"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o) {
      switch (o) {
        case "X":
          return F(S.basicOptionalMinutes, e);
        case "XX":
          return F(S.basic, e);
        case "XXXX":
          return F(S.basicOptionalSeconds, e);
        case "XXXXX":
          return F(S.extendedOptionalSeconds, e);
        case "XXX":
        default:
          return F(S.extended, e);
      }
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return o.timestampIsSet ? e : new Date(e.getTime() - i);
    }
  }]), t;
}(b), xn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 10), l(c(r), "incompatibleTokens", ["t", "T", "X"]), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o) {
      switch (o) {
        case "x":
          return F(S.basicOptionalMinutes, e);
        case "xx":
          return F(S.basic, e);
        case "xxxx":
          return F(S.basicOptionalSeconds, e);
        case "xxxxx":
          return F(S.extendedOptionalSeconds, e);
        case "xxx":
        default:
          return F(S.extended, e);
      }
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return o.timestampIsSet ? e : new Date(e.getTime() - i);
    }
  }]), t;
}(b), On = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 40), l(c(r), "incompatibleTokens", "*"), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e) {
      return dt(e);
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return [new Date(i * 1e3), {
        timestampIsSet: !0
      }];
    }
  }]), t;
}(b), kn = /* @__PURE__ */ function(n) {
  g(t, n);
  var a = y(t);
  function t() {
    var r;
    m(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return r = a.call.apply(a, [this].concat(o)), l(c(r), "priority", 20), l(c(r), "incompatibleTokens", "*"), r;
  }
  return w(t, [{
    key: "parse",
    value: function(e) {
      return dt(e);
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return [new Date(i), {
        timestampIsSet: !0
      }];
    }
  }]), t;
}(b), En = {
  G: new Xr(),
  y: new jr(),
  Y: new Gr(),
  R: new Vr(),
  u: new zr(),
  Q: new Kr(),
  q: new Zr(),
  M: new Jr(),
  L: new en(),
  w: new rn(),
  I: new an(),
  d: new sn(),
  D: new ln(),
  E: new cn(),
  e: new fn(),
  c: new dn(),
  i: new hn(),
  a: new mn(),
  b: new wn(),
  B: new gn(),
  h: new yn(),
  H: new pn(),
  K: new bn(),
  k: new Tn(),
  m: new Dn(),
  s: new Mn(),
  S: new Cn(),
  X: new Pn(),
  x: new xn(),
  t: new On(),
  T: new kn()
}, Un = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, _n = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, Yn = /^'([^]*?)'?$/, Wn = /''/g, Nn = /\S/, In = /[a-zA-Z]/;
function Hn(n, a, t, r) {
  var e, o, i, u, s, f, v, d, M, h, C, W, Y, H, L, R, V, ae;
  D(3, arguments);
  var N = String(n), q = String(a), Q = J(), X = (e = (o = r == null ? void 0 : r.locale) !== null && o !== void 0 ? o : Q.locale) !== null && e !== void 0 ? e : Oe;
  if (!X.match)
    throw new RangeError("locale must contain match property");
  var j = I((i = (u = (s = (f = r == null ? void 0 : r.firstWeekContainsDate) !== null && f !== void 0 ? f : r == null || (v = r.locale) === null || v === void 0 || (d = v.options) === null || d === void 0 ? void 0 : d.firstWeekContainsDate) !== null && s !== void 0 ? s : Q.firstWeekContainsDate) !== null && u !== void 0 ? u : (M = Q.locale) === null || M === void 0 || (h = M.options) === null || h === void 0 ? void 0 : h.firstWeekContainsDate) !== null && i !== void 0 ? i : 1);
  if (!(j >= 1 && j <= 7))
    throw new RangeError("firstWeekContainsDate must be between 1 and 7 inclusively");
  var ie = I((C = (W = (Y = (H = r == null ? void 0 : r.weekStartsOn) !== null && H !== void 0 ? H : r == null || (L = r.locale) === null || L === void 0 || (R = L.options) === null || R === void 0 ? void 0 : R.weekStartsOn) !== null && Y !== void 0 ? Y : Q.weekStartsOn) !== null && W !== void 0 ? W : (V = Q.locale) === null || V === void 0 || (ae = V.options) === null || ae === void 0 ? void 0 : ae.weekStartsOn) !== null && C !== void 0 ? C : 0);
  if (!(ie >= 0 && ie <= 6))
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  if (q === "")
    return N === "" ? p(t) : /* @__PURE__ */ new Date(NaN);
  var oe = {
    firstWeekContainsDate: j,
    weekStartsOn: ie,
    locale: X
  }, ue = [new Br()], pe = q.match(_n).map(function(U) {
    var P = U[0];
    if (P in Me) {
      var $ = Me[P];
      return $(U, X.formatLong);
    }
    return U;
  }).join("").match(Un), _ = [], A = Be(pe), z;
  try {
    var mt = function() {
      var P = z.value;
      !(r != null && r.useAdditionalWeekYearTokens) && at(P) && we(P, q, n), !(r != null && r.useAdditionalDayOfYearTokens) && nt(P) && we(P, q, n);
      var $ = P[0], ve = En[$];
      if (ve) {
        var He = ve.incompatibleTokens;
        if (Array.isArray(He)) {
          var Ae = _.find(function(Le) {
            return He.includes(Le.token) || Le.token === $;
          });
          if (Ae)
            throw new RangeError("The format string mustn't contain `".concat(Ae.fullToken, "` and `").concat(P, "` at the same time"));
        } else if (ve.incompatibleTokens === "*" && _.length > 0)
          throw new RangeError("The format string mustn't contain `".concat(P, "` and any other token at the same time"));
        _.push({
          token: $,
          fullToken: P
        });
        var Te = ve.run(N, P, X.match, oe);
        if (!Te)
          return {
            v: /* @__PURE__ */ new Date(NaN)
          };
        ue.push(Te.setter), N = Te.rest;
      } else {
        if ($.match(In))
          throw new RangeError("Format string contains an unescaped latin alphabet character `" + $ + "`");
        if (P === "''" ? P = "'" : $ === "'" && (P = An(P)), N.indexOf(P) === 0)
          N = N.slice(P.length);
        else
          return {
            v: /* @__PURE__ */ new Date(NaN)
          };
      }
    };
    for (A.s(); !(z = A.n()).done; ) {
      var Ye = mt();
      if (B(Ye) === "object")
        return Ye.v;
    }
  } catch (U) {
    A.e(U);
  } finally {
    A.f();
  }
  if (N.length > 0 && Nn.test(N))
    return /* @__PURE__ */ new Date(NaN);
  var wt = ue.map(function(U) {
    return U.priority;
  }).sort(function(U, P) {
    return P - U;
  }).filter(function(U, P, $) {
    return $.indexOf(U) === P;
  }).map(function(U) {
    return ue.filter(function(P) {
      return P.priority === U;
    }).sort(function(P, $) {
      return $.subPriority - P.subPriority;
    });
  }).map(function(U) {
    return U[0];
  }), be = p(t);
  if (isNaN(be.getTime()))
    return /* @__PURE__ */ new Date(NaN);
  var se = Ze(be, me(be)), We = {}, fe = Be(wt), Ne;
  try {
    for (fe.s(); !(Ne = fe.n()).done; ) {
      var Ie = Ne.value;
      if (!Ie.validate(se, oe))
        return /* @__PURE__ */ new Date(NaN);
      var de = Ie.set(se, We, oe);
      Array.isArray(de) ? (se = de[0], Ee(We, de[1])) : se = de;
    }
  } catch (U) {
    fe.e(U);
  } finally {
    fe.f();
  }
  return se;
}
function An(n) {
  return n.match(Yn)[1].replace(Wn, "'");
}
function la(n) {
  const a = $r(n.getTime(), /* @__PURE__ */ new Date(), {
    addSuffix: !0
  }).replace(/about/, "").trim();
  return a.match(/day|months|24 hours/) ? ke(n, "MM/dd/yyyy") : a;
}
function ca(n = "MM/dd/yyyy") {
  return ke(/* @__PURE__ */ new Date(), n);
}
var Ln = {
  full: "EEEE, d MMMM yyyy",
  long: "d MMMM yyyy",
  medium: "d MMM yyyy",
  short: "dd/MM/yyyy"
}, $n = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
}, Rn = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
}, qn = {
  date: te({
    formats: Ln,
    defaultWidth: "full"
  }),
  time: te({
    formats: $n,
    defaultWidth: "full"
  }),
  dateTime: te({
    formats: Rn,
    defaultWidth: "full"
  })
};
const Sn = qn;
var Fn = {
  code: "en-GB",
  formatDistance: it,
  formatLong: Sn,
  formatRelative: ot,
  localize: ut,
  match: st,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
const Qn = Fn, Bn = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: "MM/dd/yyyy"
}, fa = {
  ...Qn,
  formatRelative: (n) => Bn[n]
}, da = (n) => {
  (!{}.NODE_ENV || {}.NODE_ENV === "development") && console.log("Error handled by mutation", { error: n });
}, va = (n, a, t) => n === 1 ? a : t, ha = (n) => n === !0 ? "Yes" : n === !1 ? "No" : "", Xn = (n) => ({ label: n, value: n }), ma = (n) => n == null ? void 0 : n.split(",").filter((a) => !!a).map(Xn), wa = (n) => !n || !Array.isArray(n) ? [] : n == null ? void 0 : n.map((a) => a == null ? void 0 : a.value), ga = ({
  dateString: n,
  inputFormat: a = "yyyy-MM-dd",
  outputFormat: t = "MM/dd/yyyy",
  defaultPlaceholder: r = ""
}) => {
  if (!n)
    return r;
  const e = Hn(n, a, /* @__PURE__ */ new Date());
  return ke(e, t);
};
function ya(n, ...a) {
  return (t) => a.reduce(
    (r, e) => r.replace(/:[^/]+/, `${t[e]}`),
    n
  );
}
function pa(n) {
  const a = ze();
  return re(() => {
    a.current = n;
  }), a.current;
}
const je = (n) => {
  n.preventDefault();
  const a = "Leave Site? Changes you made may not be saved.";
  return n.returnValue = a, a;
}, ba = (n) => {
  re(() => (n && window.addEventListener("beforeunload", je), () => {
    window.removeEventListener("beforeunload", je);
  }), [n]);
}, Ta = (n) => {
  const [a, t] = Ke();
  return re(() => {
    n != null && n.current && t(n == null ? void 0 : n.current.getBoundingClientRect());
  }, [t, n]), a;
}, Da = (n, a, t = !0) => {
  re(() => {
    if (!a || !t)
      return;
    const r = setTimeout(n, a);
    return () => {
      clearTimeout(r);
    };
  }, [n, a, t]);
}, Ge = (n, a, t) => ({
  value: n,
  timestamp: (/* @__PURE__ */ new Date()).getTime(),
  duration: a,
  elapsedDuration: t
}), Ve = ({
  value: n,
  duration: a,
  elapsedDuration: t,
  timestamp: r
}) => {
  const e = typeof a == "function" ? a(n) : a;
  if (t) {
    const o = (/* @__PURE__ */ new Date()).getTime() - r;
    return Math.max(e - o, 0);
  }
  return e;
}, Ma = (n, {
  duration: a = 500,
  skipLapsed: t = !0,
  elapsedDuration: r = !0
} = {}) => {
  const e = ze(), [o, i] = Ke([
    Ge(n, a, r)
  ]), u = o[0], s = u == null ? void 0 : u.value, f = gt(() => {
    i((v) => {
      if (v.length === 1)
        return v;
      const d = v.slice(1);
      if (!d[1])
        return d;
      if (t)
        return d.slice(-1);
      const h = d[0], C = Ve({
        ...h,
        elapsedDuration: h.elapsedDuration && d.length === 1
      });
      return C > 0 ? (e.current = setTimeout(f, C), d) : (d.shift(), d);
    });
  }, [t]);
  return re(() => {
    i((v) => {
      const d = [...v], M = Ge(
        n,
        a,
        r
      );
      if (d[d.length - 1].value === n)
        return v;
      if (d.push(M), v.length > 1)
        return d;
      const h = Ve(u);
      return h > 0 ? (e.current = setTimeout(f, h), d) : [M];
    });
  }, [n]), re(() => () => {
    clearTimeout(e.current);
  }, []), s;
};
export {
  yt as DEFAULT_ERROR_MESSAGE,
  pt as capitalize,
  ra as capitalizeAndSingularize,
  Ge as createStateQueueItem,
  Jn as createStorageUtil,
  ta as defaultErrorParser,
  da as errorHandledByMutation,
  na as escapeHtml,
  ga as formatStringDate,
  Ve as getDeferredValueTimeout,
  ha as getIsTextingAllowedLabel,
  Vn as getScrollValue,
  wa as getValuesFromRichOptions,
  fa as locale,
  ca as makeFormattedToday,
  Xn as makeRichOption,
  ma as makeRichOptionsFromCSV,
  ya as makeRouteParamsFn,
  aa as newLinesHtml,
  bt as parseAmount,
  oa as parseBytesToKBString,
  sa as parseCentsToDollars,
  ua as parseFileName,
  va as pluralLabel,
  Zn as preventBubbling,
  ea as readableBytes,
  Kn as scrollToElement,
  zn as setScrollValue,
  la as timeAgo,
  ia as titleCase,
  ba as useBeforeUnload,
  Ta as useClientBoundingRect,
  pa as usePrevious,
  Da as useTimeout,
  Ma as useTimeoutValue,
  Gn as validatePassword
};
