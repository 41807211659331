import { ClientOrFirm, HomeResponse } from '@liscio/api';

import { useHomeData } from './users/user-hooks';

export function useClientOrFirm() {
  const { data: homeBody } = useHomeData();

  return getClientOrFirmFromHomeBody(homeBody);
}

export function getClientOrFirmFromHomeBody(homeBody: HomeResponse | void) {
  const clientOrFirm = homeBody?.data.is_employee
    ? ClientOrFirm.Firm
    : ClientOrFirm.Client;

  return clientOrFirm;
}
