import React, { ReactNode } from 'react';

import { styled, Box } from '@liscio/ui';

export interface AppHeaderWrapperProps {
  hideBorderBottom?: boolean;
  children: ReactNode;
}

const Wrapper = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.grey[50], // I think we should change this too
  backgroundColor: theme.palette.common.white,
  borderBottom: `solid 1px ${theme.brandColors.sky}`,
  paddingLeft: '26px',
  paddingRight: '15px',
  paddingTop: '8px',
  paddingBottom: '8px',
}));

export const AppHeaderWrapper: React.FC<AppHeaderWrapperProps> = ({
  hideBorderBottom,
  children,
}) => {
  return (
    <Wrapper sx={{ borderBottomWidth: hideBorderBottom ? 0 : '1px' }}>
      {children}
    </Wrapper>
  );
};

export default AppHeaderWrapper;
