import React from 'react';

import { CircularProgress } from '@mui/material';

import { styled, Box } from '@liscio/ui';

export interface IOSStyleProps {
  touchDiff: number;
  reloadThreshold: number;
}

const Wrapper = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const MARGIN = 5;

export const IOSStyle: React.FC<IOSStyleProps> = ({
  touchDiff,
  reloadThreshold,
}) => {
  return (
    <Wrapper sx={{ height: touchDiff }}>
      {touchDiff > reloadThreshold - MARGIN && (
        <CircularProgress variant="indeterminate" disableShrink size={30} />
      )}
    </Wrapper>
  );
};

export default IOSStyle;
