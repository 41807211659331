import { useState } from 'react';

import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import { workflowRequestDocument } from '@liscio/api';
import { WorkflowRequestStatus } from '@liscio/api/graphql';
import { AppBar, AttachIconState, useGraphQLClient } from '@liscio/common';
import { Box, Typography } from '@liscio/ui';

import { ActionList } from './components/ActionList/ActionList';
import { WorkflowViewProvider } from './context/WorkflowViewContext';
import { useUpdateWorkflowRequest } from 'fetch-utils/workflow/workflow-hooks';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from 'modules/dashboard/route-constants';

export const WorkflowView = () => {
  const [hasResponded, setHasResponded] = useState(false);
  const params = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { data: workflowData, refetch: refetchWorkflowData } =
    useGraphQLClient().useGraphQL(workflowRequestDocument, {
      id: params?.id ?? '',
    });
  const { mutate: workflowRequestMutation } = useUpdateWorkflowRequest();

  const handleSubmitRequest = async () => {
    workflowRequestMutation(
      {
        workflowRequestId: workflowData?.workflowRequest?.id,
        status: WorkflowRequestStatus.Submitted,
      },
      {
        onSettled: (response) => {
          if (response?.updateWorkflowRequest?.errors?.length) {
            toast.error('Error submitting request');
            return;
          }
          setHasResponded(true);
        },
      }
    );
  };

  return (
    <WorkflowViewProvider>
      <AppBar
        onNavigationClick={() =>
          navigate(`/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`)
        }
        DefaultStateElement={AttachIconState}
        hideSecondaryActionButton
        title="Requests"
        position="relative"
      />
      {hasResponded ? (
        <Typography variant="h6" textAlign="center" mt={12}>
          Thank you. Your response has been recorded.
        </Typography>
      ) : (
        <Box
          flexDirection={'column'}
          display="flex"
          padding="10px 20px"
          flexGrow={1}
        >
          {workflowData?.workflowRequest && (
            <ActionList
              refetchWorkflowData={refetchWorkflowData}
              workflowRequest={workflowData?.workflowRequest}
              onSubmit={handleSubmitRequest}
            />
          )}
        </Box>
      )}
    </WorkflowViewProvider>
  );
};

export default WorkflowView;
