import { format } from 'date-fns';

export const DEFAULT_VIEW_URL = '/dashboard/home';
export const MAX_FILE_SIZE_IN_BYTES = 629145600;
export const MAX_FILE_SIZE_IN_MB = 600;
export const MAX_TEXT_AREA_ROWS = 10;

export const COPYRIGHT_TEXT = `© ${format(
  new Date(),
  'yyyy'
)} LISCIO. All rights reserved`;

export const DESKTOP_COPYRIGHT_TEXT = `Liscio © ${format(
  new Date(),
  'yyyy'
)}  All rights reserved  Terms • Privacy Policy`;
