import { useCallback, useEffect, useRef, useState } from 'react';

import { useFieldArray } from 'react-hook-form';
import { IDocumentScannerHandle } from 'scanbot-web-sdk/@types/interfaces/i-document-scanner-handle';
import PdfGenerator, {
  PdfGenerationOptions,
} from 'scanbot-web-sdk/@types/service/pdf-generator';
import ScanbotSDK from 'scanbot-web-sdk/webpack';

import { useScanbotContext } from 'providers/ScanbotProvider';

const DEFAULT_VIDEO_CONSTRAINTS = {
  facingMode: { ideal: 'environment' },
  width: { ideal: 1920 },
  height: { ideal: 1080 },
};

export type BrowserPage = { bytes: ArrayBuffer; imgURL: string };

export interface BrowserScannerUtils {
  handleAssemblePDF: () => void;
  handleOpenScannerClick: () => void;
  pages: BrowserPage[];
  initializingCamera?: boolean;
  setShowScanner?: (toSet: boolean) => void;
  showScanner?: boolean;
  newPagePreview?: string;
  handleCancelClick?: () => void;
  handleConfirmClick?: () => void;
  hasVideoAccessError?: boolean;
  revokeVideoAccessError?: () => void;
}

export default function useBrowserScanner<T>(
  containerId: string
): BrowserScannerUtils {
  const { sdk: uncastSdk } = useScanbotContext();
  const sdk = uncastSdk as ScanbotSDK;

  const scanner = useRef<IDocumentScannerHandle>();
  const generator = useRef<PdfGenerator>();
  const [pages, setPages] = useState<{ bytes: ArrayBuffer; imgURL: string }[]>(
    []
  );
  const [newPagePreview, setNewPagePreview] = useState('');
  const [showScanner, setShowScanner] = useState(false);
  const [hasVideoAccessError, setHasVideoAccessError] = useState(false);
  const [initializingCamera, setInitializingCamera] = useState(false);
  const { append: appendFile } = useFieldArray({ name: 'files' });

  const onDocumentDetected = useCallback(
    async (result: any) => {
      scanner?.current?.disableAutoCapture();
      sdk?.utils?.flash();

      const imgURL = await sdk?.toDataUrl(result.cropped);

      setNewPagePreview(imgURL);

      setTimeout(() => {
        setNewPagePreview('');
        scanner?.current?.enableAutoCapture();
      }, 1800);

      setPages((_pages) => [
        ..._pages,
        {
          imgURL,
          bytes: result.cropped,
        },
      ]);
    },
    [sdk]
  );

  const makeDocumentScanner = useCallback(async () => {
    scanner.current = await sdk?.createDocumentScanner({
      containerId,
      videoConstraints: DEFAULT_VIDEO_CONSTRAINTS,
      onError: console.error,
      onDocumentDetected,
      autoCaptureEnabled: true,
      acceptedAngleScore: 50, // default is 75
      acceptedSizeScore: 75, // default is 80
    });
  }, [sdk, containerId, onDocumentDetected]);

  const makePDFGenerator = useCallback(async () => {
    const options = { standardPaperSize: 'A4', landscape: false, dpi: 100 };

    generator.current = await sdk?.beginPdf(options as PdfGenerationOptions);
  }, [sdk]);

  async function makePDFFromPages() {
    try {
      for (const page of pages) {
        await generator?.current?.addPage(page.bytes);
      }

      const bytes = await generator?.current?.complete();
      const name = `${Date.now()}-${Math.floor(Math.random() * 10_000)}.pdf`;

      const file = new File([bytes] as BlobPart[], name, {
        type: 'application/pdf',
      });

      return file;
    } catch (e) {
      console.error(e);
    }
  }

  async function handleAssemblePDF() {
    const newFile = await makePDFFromPages();

    appendFile(newFile);
  }

  async function handleOpenScannerClick() {
    setPages([]);
    setShowScanner(true);
  }

  useEffect(() => {
    async function createScannerAndGenerator() {
      setInitializingCamera(true);

      try {
        await makeDocumentScanner();
        await makePDFGenerator();
      } catch (e) {
        setHasVideoAccessError(true);
        setInitializingCamera(false);
        setShowScanner(false);
      }

      const svgEl = document.querySelector('#document-scanner-view svg') || {};
      if ((svgEl as HTMLElement)?.style?.cssText) {
        (svgEl as HTMLElement).style.cssText += 'left: 0';
      }

      setInitializingCamera(false);
    }

    if (showScanner && !scanner.current) createScannerAndGenerator();
  }, [
    sdk,
    makePDFGenerator,
    showScanner,
    onDocumentDetected,
    makeDocumentScanner,
  ]);

  function stopCamera() {
    scanner?.current?.disableAutoCapture();
    scanner?.current?.dispose();
    scanner.current = undefined;

    setShowScanner(false);
  }

  function handleCancelClick() {
    stopCamera();
  }

  async function handleConfirmClick() {
    handleAssemblePDF();
    stopCamera();
  }

  return {
    handleAssemblePDF,
    initializingCamera,
    handleOpenScannerClick,
    setShowScanner,
    showScanner,
    newPagePreview,
    pages,
    handleCancelClick,
    handleConfirmClick,
    hasVideoAccessError,
    revokeVideoAccessError: () => {
      setHasVideoAccessError(false);
    },
  };
}
