import React from 'react';

import { Button, Typography } from '@liscio/ui';

import { ScreenWrapper, BodyWrapper } from 'components/screenComposition';

export interface UnexpectedErrorProps {}

export const UnexpectedError: React.FC<UnexpectedErrorProps> = () => {
  const reload = () => {
    window.location.replace('/dashboard/home');
  };
  return (
    <ScreenWrapper>
      <BodyWrapper
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
        gap={6}
        p={2}
      >
        <Typography variant="h1" sx={{ fontSize: '2rem' }}>
          Oops something went wrong...
        </Typography>
        <Button variant="contained" onClick={reload}>
          Reload
        </Button>
      </BodyWrapper>
    </ScreenWrapper>
  );
};

export default UnexpectedError;
