import { useQuery } from '@tanstack/react-query';

import apiClient from 'fetch-utils/api-client';

const { getCustomer } = apiClient.customers;

export function useGetCustomer(accountId: number | string) {
  return useQuery({
    queryKey: ['customer', accountId],
    queryFn: getCustomer,
  });
}
