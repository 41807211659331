import React, { useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import { Box, Stack, Typography, Button } from '@liscio/ui';

import { BiometricsAdornment } from '../BiometricsAdornment/BiometricsAdornment';
import { BiometricsToggle } from '../BiometricsToggle/BiometricsToggle';
import { RememberMeToggle } from '../RememberMe/RememberMeToggle';
import { Link, ErrorDialog } from 'components';
import { useForgotPassword } from 'fetch-utils/users/auth-hooks';
import {
  AuthPasswordInput,
  AuthTextField,
} from 'modules/authenticate/components/FormFields';
import {
  GoogleOAuthV5Login,
  OutlookOAuthV5Login,
} from 'modules/authenticate/components/OAuth';

export interface SignInFormProps {
  isLoading?: boolean;
}

export const SignInForm = ({ isLoading = false }: SignInFormProps) => {
  const {
    control,
    register,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useFormContext();
  const { setForgotPasswordEmail } = useForgotPassword();

  // Forgot Password Capture
  const captureForgotPasswordEmail = useCallback(() => {
    const email = getValues('email');
    setForgotPasswordEmail(email);
  }, [getValues, setForgotPasswordEmail]);

  // Update/reset email field when remember me loads
  const updateRememberMeEmailValue = useCallback(
    (email: string) => reset({ email }),
    [reset]
  );

  return (
    <>
      <Typography variant="h2" color="white" textAlign="center" mb={4}>
        Sign In
      </Typography>
      <Stack gap={2}>
        <AuthTextField
          {...register('email')}
          fullWidth
          type="email"
          placeholder="Email"
          error={Boolean(errors?.email)}
          helperText={errors?.email?.message as string}
          disabled={isSubmitting || isLoading}
          InputProps={{
            endAdornment: <BiometricsAdornment />,
          }}
        />
        <AuthPasswordInput
          {...register('password')}
          fullWidth
          placeholder="Password"
          error={Boolean(errors?.password)}
          helperText={errors?.password?.message as string}
          disabled={isSubmitting || isLoading}
        />
        <Button
          variant="contained"
          type="submit"
          disabled={isSubmitting || isLoading}
          size="large"
        >
          Sign In
        </Button>
        <Box display="flex" flexDirection="column" mt={4}>
          <GoogleOAuthV5Login />
        </Box>
        <Box display="flex" flexDirection="column">
          <OutlookOAuthV5Login />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <RememberMeToggle
            name="enableRememberMe"
            resetEmailField={updateRememberMeEmailValue}
            control={control}
          />
          <BiometricsToggle control={control} name="enableBiometrics" />
        </Box>
      </Stack>

      <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
        <Link
          onClick={captureForgotPasswordEmail}
          to="/authenticate/forgot-password"
          underline="none"
          color={(theme) => theme.palette.text.secondary}
        >
          Forgot your password?
        </Link>
      </Box>
      <ErrorDialog title="Sign In Error" error={errors.root?.serverError} />
    </>
  );
};

export default SignInForm;
