import { useState } from 'react';

import { FormHelperText } from '@mui/material';
import { IconButton, styled, Stack, Icon } from '@liscio/ui';
import {
  FieldErrors,
  FieldValues,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';

import { FilePercentsMap } from '@liscio/api';
import { scrollToElement } from '@liscio/utils';

import { FileInFormRow } from './components';
import { CircularProgress, ConfirmationDialog } from 'components';
import { makeFileId } from 'fetch-utils/documents/documentsCallsUtils';

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
  textAlign: 'center',
}));

interface FilesInFormProps {
  errors: FieldErrors<FieldValues>;
  uploadProgressPercentMap: FilePercentsMap;
  cancelUpload: (file: File) => void;
}

export default function FilesInForm({
  uploadProgressPercentMap,
  cancelUpload,
}: FilesInFormProps) {
  const [fileToDeleteIndex, setFileToDeleteIndex] = useState<number>();
  const {
    formState: { errors },
    watch,
  } = useFormContext();
  const files: File[] = watch('files');
  const { remove } = useFieldArray({ name: 'files' });

  if (!files) return null;

  if (files?.length === 0) return null;

  function handleRemoveClick(i: number) {
    remove(i);
  }

  return (
    <Stack
      direction="column"
      spacing={5}
      justifyContent="center"
      alignItems="center"
    >
      {files.map((file, i) => {
        const isLastFile = files.length - 1 === i;
        const uploadProgressPercent =
          uploadProgressPercentMap[makeFileId(file)];
        const isUploading =
          uploadProgressPercent > 0 && uploadProgressPercent < 100;
        const percentValues = Object.values(uploadProgressPercentMap || {});
        const doneUploading = percentValues.every((percent) => percent === 100);
        return (
          <Stack
            direction="row"
            key={makeFileId(file)}
            sx={{ width: '100%' }}
            ref={(elementRef) => {
              if (isLastFile && elementRef) {
                scrollToElement(elementRef, 'body-wrapper-overlay');
              }
            }}
          >
            <FileInFormRow
              hideActions={
                percentValues?.length > 0 && (isUploading || doneUploading)
              }
              file={file}
              onDeleteClick={() => {
                setFileToDeleteIndex(i);
              }}
            />
            {isUploading && (
              <CircularProgress
                variant="determinate"
                value={uploadProgressPercent}
              />
            )}
            {percentValues?.length > 0 && (isUploading || doneUploading) && (
              <IconButton
                onClick={isUploading ? () => cancelUpload(file) : () => {}}
              >
                {isUploading && <Icon icon="close" color="primary" />}
                {doneUploading && <Icon icon="success" color="success" />}
              </IconButton>
            )}
          </Stack>
        );
      })}
      {errors?.files && (
        <StyledFormHelperText variant="filled">
          {errors?.files?.message as string}
        </StyledFormHelperText>
      )}
      {typeof fileToDeleteIndex === 'number' && (
        <ConfirmationDialog
          open={typeof fileToDeleteIndex === 'number'}
          onConfirm={() => {
            handleRemoveClick(fileToDeleteIndex!);
            setFileToDeleteIndex(undefined);
          }}
          onCancel={() => setFileToDeleteIndex(undefined)}
          title={'Do you want to clear this file from the form?'}
          content={files[fileToDeleteIndex!]?.name}
          confirmLabel={'Clear file'}
        />
      )}
    </Stack>
  );
}
