import { useCallback } from 'react';

import { LDContext, useLDClient } from 'launchdarkly-react-client-sdk';

import { HomeUserSessionData } from '@liscio/api';

import { config } from '../config';

export const mapHomeDataToContext = (data: HomeUserSessionData): LDContext => ({
  kind: 'multi',
  app: {
    key: 'liscio-mobile',
  },
  platform: {
    key: config.platform,
  },
  firm: {
    key: data.company_url,
    url: data.company_url,
    name: data.cpa_name,
    id: data.cpa_id,
  },
  role: {
    key: data.role,
  },
});

/**
 * Helper hook for updating launchdarkly context from home data
 */
export const useLaunchDarklyIdentify = () => {
  const ldClient = useLDClient();

  const identify = useCallback(
    async (data: HomeUserSessionData) => {
      if (ldClient) {
        const context = mapHomeDataToContext(data);
        await ldClient.identify(context);
      }
    },
    [ldClient]
  );

  return { identify };
};
