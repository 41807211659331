import { MouseEvent } from 'react';

import { Chip } from '@liscio/ui';

export interface SelectValueProps {
  placeholder?: React.ReactNode;
  value?: RichOption[] | RichOption | null;
  handleClick: (e: MouseEvent, index: number) => void;
}

const SelectValue = ({
  placeholder = 'Select',
  value,
  handleClick,
}: SelectValueProps) => {
  if (!Array.isArray(value)) return <>{value?.label || placeholder}</>;

  if (value.length === 0) return <>{placeholder}</>;

  return (
    <>
      {value.map(({ label }, index) => (
        <Chip
          key={label}
          onClick={(e: MouseEvent) => handleClick(e, index)}
          label={label}
          sx={{ margin: '5px' }}
        />
      ))}
    </>
  );
};

export default SelectValue;
