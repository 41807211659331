import React from 'react';

import { styled, Box, Stack, Typography, UnreadIndicator } from '@liscio/ui';

import { BasicTask } from '@liscio/api';

import { CommentsCount } from './components';

export interface PrimaryProps {
  task: BasicTask;
  highlightUnreadComments?: boolean;
}

const Date = styled(Typography)(({ theme }) => ({
  flexShrink: 0,
  fontSize: '1rem',
  color: theme.palette.common.blueGray[500],
}));

const AccountName = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.blueGray[500],
}));

const Content = styled(Box)(() => ({
  fontSize: '1.2rem',
}));

export const Primary: React.FC<PrimaryProps> = ({
  task,
  highlightUnreadComments = false,
}) => {
  const shouldDisplayAsUnread =
    task.comment_count > 0 && highlightUnreadComments;

  return (
    <Stack direction="row" alignItems="center" width="100%" spacing={2}>
      <UnreadIndicator isUnread={shouldDisplayAsUnread} />
      <Stack width="100%" spacing={1}>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <AccountName>
            {task.account_name || task.todo_account_name}
          </AccountName>
          <Date>{task.updated_at}</Date>
        </Stack>
        <Content sx={{ fontWeight: shouldDisplayAsUnread ? 800 : 500 }}>
          {task.subject}
        </Content>
        {task.comment_count > 0 && (
          <CommentsCount numberOfComments={task.comment_count} />
        )}
      </Stack>
    </Stack>
  );
};

export default Primary;
