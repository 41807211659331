import React from 'react';

import { SxProps } from '@mui/material';

import { Stack } from '@liscio/ui';

export interface RowProps {
  children: React.ReactNode;
  sx?: SxProps;
}

export const Row: React.FC<RowProps> = ({ children, sx }) => {
  return (
    <Stack direction="row" gap="8px" alignItems="center" sx={sx}>
      {children}
    </Stack>
  );
};

export default Row;
