import React, { useCallback, useMemo } from 'react';

import { FormControlLabel } from '@mui/material';

import { TaskType } from '@liscio/api';

import { Select } from 'components/Select';
import TASK_TYPES from 'constants/taskTypes';

// NOTE: We only allow the following two task types for mobile
const MOBILE_CREATE_TASK_TYPES = [TaskType.RequestInformation, TaskType.ToDo];
const mobileTaskOptions = TASK_TYPES.filter((option) =>
  MOBILE_CREATE_TASK_TYPES.includes(option.value as TaskType)
);

export interface TaskTypeSelectProps {
  value: string;
  onChange?: (taskType: string) => void;
}

export const TaskTypeSelect = ({ value, onChange }: TaskTypeSelectProps) => {
  const taskValue = useMemo(
    () => mobileTaskOptions.find((task) => task.value === value),
    [value]
  );
  const handleTaskChange = useCallback(
    (taskOption: RichOption) => {
      if (onChange) {
        onChange(String(taskOption.value));
      }
    },
    [onChange]
  );
  return (
    <FormControlLabel
      label="Task Type"
      labelPlacement="top"
      sx={{ display: 'flex' }}
      control={
        <Select
          title="Task Type"
          data={mobileTaskOptions}
          onChange={handleTaskChange}
          value={taskValue}
          clearable={false}
          btnProps={{
            fullWidth: true,
            variant: 'text',
            sx: { justifyContent: 'space-between' },
          }}
          width="100%"
        />
      }
    />
  );
};
