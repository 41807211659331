import { ChangeEvent, useMemo, useState } from 'react';

import {
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  debounce,
} from '@mui/material';
import toast from 'react-hot-toast';

import { Box } from '@liscio/ui';

import { useWorkflowViewContext } from '../../context/WorkflowViewContext';
import { RequestItemDisplayProps } from '../RequestItem/RequestItem';
import { useUpdateResponseItemText } from 'fetch-utils/workflow/workflow-hooks';

interface RequestItemTextProps extends RequestItemDisplayProps {
  value?: string | null;
}

export function RequestItemText({
  notApplicable: _notApplicable,
  prompt,
  responseItemId: id,
  value,
}: RequestItemTextProps) {
  const { setDirtyField } = useWorkflowViewContext();
  const { mutate: textMutation, isLoading: isMutating } =
    useUpdateResponseItemText();
  const [notApplicable, setNotApplicable] = useState(_notApplicable);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirtyField(id, true);
    debounceChange(e);
  };

  const debounceChange = useMemo(
    () =>
      debounce((e) => {
        const input = e.target.value;
        updateResponseItem(input);
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateResponseItem = (value: string) => {
    textMutation(
      {
        workflowResponseItemId: id,
        textValue: value,
      },
      {
        onSettled: () => {
          setDirtyField(id, false);
        },
      }
    );
  };

  const handleNotApplicable = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setNotApplicable(checked);
    textMutation(
      {
        notApplicable: checked,
        workflowResponseItemId: id,
      },
      {
        onError: () => {
          toast.error('Failed to update response item');
          setNotApplicable(!checked);
        },
      }
    );
  };

  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Box width="100%">
        <TextField
          hiddenLabel
          variant="outlined"
          inputProps={{ 'aria-label': `${prompt} input` }}
          InputProps={{
            sx: { backgroundColor: 'common.white' },
          }}
          fullWidth
          defaultValue={notApplicable ? '' : value}
          onChange={handleOnChange}
          disabled={notApplicable}
        />
      </Box>
      <FormGroup>
        <FormControlLabel
          label="This item does not apply to me"
          control={
            <Switch
              disabled={isMutating}
              onChange={handleNotApplicable}
              checked={notApplicable}
            />
          }
        />
      </FormGroup>
    </Box>
  );
}
