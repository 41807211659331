export const ERRORS_DOMAIN = 'errors';
export const ERRORS_DOMAIN_URL = `/${ERRORS_DOMAIN}/*`;

export const ERRORS_PATHS = {
  internetConnection: '/internet-connection',
};

export const ERRORS_PAGE_TITLES = {
  internetConnection: 'Internet connection problem',
};
