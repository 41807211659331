import React, { useCallback } from 'react';

import { List, ListItem, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { BasicTask } from '@liscio/api';
import { getScrollValue } from '@liscio/utils';

import { Primary } from './components/Primary/Primary';
import { ListItemButton } from 'components';
import { TASKS_DOMAIN, TASKS_PATHS } from 'modules/tasks/route-constants';

export interface TasksListProps<T extends BasicTask = BasicTask> {
  tasks: T[];
  // Override task click navigation
  onClick?: (task: T) => void;
  // Optional state to pass to navigate options
  navigateState?: Record<string, any>;
  // Whether or not to display unread messages dot
  highlightUnreadComments?: boolean;
}

/**
 * Basic list of tasks.
 * Clicking a task will go to the task detail view by default
 */
export const TasksList = <T extends BasicTask = BasicTask>({
  tasks,
  onClick: onClickOverride,
  highlightUnreadComments = false,
  navigateState = {},
}: TasksListProps<T>) => {
  const navigate = useNavigate();
  const onTaskClick = useCallback(
    (task: T) => {
      if (onClickOverride) {
        onClickOverride(task);
      } else {
        // Default navigate to task detail w/ passed state
        navigate(
          `/${TASKS_DOMAIN}${TASKS_PATHS.details}/${encodeURIComponent(
            task.id
          )}`,
          {
            state: {
              scroll: getScrollValue(),
              ...navigateState,
            },
          }
        );
      }
    },
    [navigate, onClickOverride, navigateState]
  );
  return (
    <List>
      {tasks.map((task) => (
        <ListItem key={task.id} disablePadding>
          <ListItemButton onClick={() => onTaskClick(task)}>
            <ListItemText>
              <Primary
                task={task}
                highlightUnreadComments={highlightUnreadComments}
              />
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
