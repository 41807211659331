import { differenceBy } from 'lodash';

import { Participant } from '@liscio/api';

export const getAdditionalParticipants: (
  participants: Array<Participant>,
  participantsInForm: Array<Participant>
) => Array<Participant> = (participants, participantsInForm) => {
  if (!participants || !participantsInForm) {
    return [];
  }

  return differenceBy(participantsInForm, participants, 'id');
};

export default getAdditionalParticipants;
