import React from 'react';

import {
  List as MUIList,
  ListItem as MUIListItem,
  ListItemButton as MUIListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from '@mui/material';

import { styled } from '@liscio/ui';

import { DrawerProps } from '../../Drawer';

type MenuProps = Pick<
  DrawerProps,
  | 'options'
  | 'onNavigationClick'
  | 'selectedOptionValue'
  | 'isLoading'
  | 'hasLoadingElement'
>;

const List = styled(MUIList)(() => ({
  flexGrow: 1,
}));
const ListItem = styled(MUIListItem)(({ theme }) => ({
  'svg path': {
    fill: theme.palette.primary.contrastText,
  },
}));
const ListItemButton = styled(MUIListItemButton)(({ theme }) => ({
  paddingTop: '12px',
  paddingBottom: '12px',
  '&.Mui-selected': {
    backgroundColor: theme.brandColors.dark,
  },
}));
const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.common.blueGray[500],
}));

export const Menu: React.FC<MenuProps> = ({
  options,
  selectedOptionValue,
  onNavigationClick,
  isLoading,
  hasLoadingElement,
}) => (
  <List>
    {options.map((option) => (
      <ListItem key={option.value} disablePadding>
        <ListItemButton
          selected={selectedOptionValue === option.value}
          onClick={() => onNavigationClick && onNavigationClick(option.value)}
          disabled={isLoading}
        >
          <ListItemIcon>{option.Icon}</ListItemIcon>
          <ListItemText>{option.label}</ListItemText>
        </ListItemButton>
      </ListItem>
    ))}
    {hasLoadingElement && (
      <ListItem sx={{ justifyContent: 'center' }}>
        <StyledSkeleton width="80%" />
      </ListItem>
    )}
  </List>
);

export default Menu;
