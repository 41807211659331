import { useEffect, useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { SMSSocketResponse, ClientOrFirm } from '@liscio/api';

import useWebsocket from './useWebsocket';
import { useHomeData } from 'fetch-utils/users/user-hooks';
import { getClientOrFirmFromHomeBody } from 'fetch-utils/utility-hooks';

export default function useSMSWebsocket(authToken?: string) {
  const subscriptionActive = useRef(false);
  const websocket = useWebsocket(authToken);
  const { data: homeData } = useHomeData();
  const queryClient = useQueryClient();

  useEffect(() => {
    const clientOrFirm = getClientOrFirmFromHomeBody(homeData);

    if (
      !subscriptionActive.current &&
      homeData &&
      clientOrFirm === ClientOrFirm.Firm
    ) {
      subscriptionActive.current = true;
      websocket.consumer.subscriptions.create(
        {
          channel: 'TextMessageChannel',
          cpa_user_id: homeData?.data?.cpa_user_id,
        },
        {
          received: (dataReceived: SMSSocketResponse) => {
            if (dataReceived) {
              queryClient.invalidateQueries(['sms-threads']);
              queryClient.invalidateQueries([
                'sms-thread-detail',
                String(dataReceived.text_message_thread_id),
              ]);
            }
          },
        }
      );
    }

    return () => websocket.consumer.disconnect();
  }, [websocket.consumer, homeData, queryClient, websocket]);
}
