import { Avatar as MUIAvatar } from '@mui/material';

import { styled } from '@liscio/ui';

/**
 * @deprecated Use from @liscio/ui
 */
export const Avatar = styled(MUIAvatar)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  borderWidth: '2px',
  borderStyle: 'solid',
}));

export default Avatar;
