import {
  WorkflowRequestItemFragment,
  WorkflowRequestQuery,
  WorkflowItemConditionFragment,
} from '@liscio/api/graphql';

export interface HashMapCondition extends WorkflowItemConditionFragment {
  requestItems: WorkflowRequestItemFragment[];
  currentValue: boolean | null | undefined;
}

export type ConditionalHashMap = Record<string, HashMapCondition>;

export const createConditionalHashMap = (
  workflowRequest: WorkflowRequestQuery['workflowRequest']
): ConditionalHashMap => {
  const conditions: ConditionalHashMap = {};

  // Create a hashmap of conditions with the parent id as the key
  workflowRequest?.workflow?.sections?.forEach((section, sidx) => {
    section?.items?.forEach((item, idx) => {
      item?.conditions?.forEach((condition) => {
        const requestItem =
          workflowRequest?.requestSections?.[sidx]?.requestItems?.[idx];
        if (!requestItem || !item.conditionalParentId) {
          return;
        }

        conditions[item.conditionalParentId] = {
          ...condition,
          currentValue: null,
          requestItems: !conditions[item.conditionalParentId]
            ? [requestItem]
            : [
                ...(conditions[item.conditionalParentId].requestItems || []),
                requestItem,
              ],
        };
      });
    });
  });

  // Update the hashmap with the current value of the parent id from the requestItems
  // only works for boolean values ATM
  workflowRequest?.requestSections?.forEach((sections) => {
    sections.requestItems?.forEach((item) => {
      const id = item?.responseItem?.workflowItem?.id;
      if (conditions[id]) {
        conditions[id].currentValue = item?.responseItem?.booleanValue;
      }
    });
  });

  return conditions;
};
