import {
  FileFiltersType,
  SearchDocumentsArgs,
  UploaderType,
} from '@liscio/api';

import { useFilters } from 'custom-hooks/useFilters';

export function adaptFiltersForContract(
  filters: FileFiltersType
): SearchDocumentsArgs {
  return {
    sortBy: filters.field!,
    sortOrder: filters.sort_by!,
    searchString: filters.keyword,
    year: filters.year,
    month: filters.month,
    tags: filters.tags!,
    fileUploadedBy: filters.file_uploaded_by as UploaderType,
    contactId: filters.contact_id,
    source: filters.source,
    showArchived: Boolean(filters.archive),
  };
}

export const defaultFileFilters: FileFiltersType = {
  sort_by: 'desc',
  field: 'created_at',
  tags: [],
  year: [],
  month: [],
  file_uploaded_by: undefined,
};
export const useFileFilters = (initialFilters = defaultFileFilters) => {
  const filterConfig = useFilters(initialFilters);
  return filterConfig;
};
