import React, { useEffect } from 'react';

import { Control } from 'react-hook-form';

import { useRememberMe } from '@liscio/common';

import { Checkbox, RHFCheckbox } from 'components';

export interface RememberMeToggleProps {
  name: string;
  resetEmailField: (email: string) => void;
  //eslint-disable-next-line
  control: Control<any>;
}

export const RememberMeToggle = ({
  name,
  resetEmailField,
  control,
}: RememberMeToggleProps) => {
  const { email, forgetEmail } = useRememberMe();

  // Reset form email value if loaded
  useEffect(() => {
    if (email) {
      resetEmailField(email);
    }
  }, [email, resetEmailField]);

  // Render controlled checkbox if remember email is set
  if (email) {
    return (
      <Checkbox
        type="inline"
        label="Remember Email"
        checked
        onClick={forgetEmail}
      />
    );
  }

  return (
    <RHFCheckbox
      name={name}
      type="inline"
      label="Remember Email"
      control={control}
    />
  );
};
