import { Capacitor } from '@capacitor/core';

import { config } from 'config';

export const getSuccessUrl: (
  baseUrl: string,
  invoiceId: string,
  redirectUrl?: string
) => string = (baseUrl, invoiceId, redirectUrl) => {
  const webAppUrl = Capacitor.isNativePlatform()
    ? config.webApp.url
    : window?.location?.origin;

  const params: Array<string> = [`invoice_id=${invoiceId}`];
  if (redirectUrl) {
    params.push(`redirect_url=${redirectUrl}`);
  }

  return `${webAppUrl}/${baseUrl}?${params.join('&')}`;
};

export default getSuccessUrl;
