import React from 'react';

import { Capacitor } from '@capacitor/core';
import { useNavigate } from 'react-router-dom';

import { Box } from '@liscio/ui';

import Header from '../Header/Header';
import { Loading, MessagesList, EmptyListPlaceholder } from 'components';
import { useInfinitiveMessages } from 'fetch-utils/messages/messages-hooks';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from 'modules/dashboard/route-constants';
import {
  MESSAGES_DOMAIN,
  MESSAGES_PATHS,
} from 'modules/messages/route-constants';
import { extractValues } from 'modules/messages/views/ListView/helpers';

export interface MessagesProps {}

const TAB = 'inbox';
const MESSAGES_LIMIT = Capacitor.isNativePlatform() ? 3 : 5;

export const Messages: React.FC<MessagesProps> = () => {
  const navigate = useNavigate();
  const { data: infinitiveMessageData, isLoading: isMessagesLoading } =
    useInfinitiveMessages(TAB, '');
  const { messages, inboxNumberOfMessages } = extractValues(
    infinitiveMessageData,
    TAB
  );
  const limitedMessages = messages ? messages.slice(0, MESSAGES_LIMIT) : [];

  return (
    <>
      <Header
        title="Inbox"
        isLoading={isMessagesLoading}
        numberOfItems={inboxNumberOfMessages}
        onSeeAllClick={() => {
          navigate(`/${MESSAGES_DOMAIN}${MESSAGES_PATHS.list}`);
        }}
      />
      <Box position="relative" minHeight="100px">
        {isMessagesLoading && <Loading numberOfElements={1} />}
        {!isMessagesLoading && limitedMessages.length > 0 && (
          <MessagesList
            messages={limitedMessages}
            highlightUnreadComments
            navigateState={{
              goBackUrl: `/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`,
            }}
          />
        )}
        {!isMessagesLoading && limitedMessages.length === 0 && (
          <EmptyListPlaceholder label="Your inbox is empty." />
        )}
      </Box>
    </>
  );
};

export default Messages;
