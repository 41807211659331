import React, { useCallback, useEffect, useMemo } from 'react';

import { styled, Box, GlobalLoader, Typography } from '@liscio/ui';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';

import { ResetPasswordForm } from './components/ResetPasswordForm/ResetPasswordForm';
import { Copyright, Logo } from '../LoginView/components';
import { ErrorDialog } from 'components';
import { useForgotPassword } from 'fetch-utils/users/auth-hooks';
import { AUTHENTICATE_PAGE_TITLES } from 'modules/authenticate/route-constants';

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.common.white,
  paddingTop: '48px',
  paddingBottom: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const ForgotPasswordResetView = () => {
  const { token } = useParams<{ token: string }>();
  const {
    validateToken: {
      mutate: validateToken,
      isLoading: isValidating,
      error: validateError,
    },
  } = useForgotPassword();
  const error = useMemo(() => {
    if (!token) return { message: 'Invalid verification token.' };
    return validateError;
  }, [token, validateError]);
  const navigate = useNavigate();
  const backToLogin = useCallback(() => {
    navigate('/authenticate/login', { replace: true });
  }, [navigate]);

  // Validate forgot password token
  // NOTE: We use the v3 validate token endpoint for both legacy v3
  // AND v5Login / v5_login password_reset tokens (for now).
  useEffect(() => {
    if (token) {
      validateToken(token);
    }
  }, [token, validateToken]);

  return (
    <>
      <Helmet title={AUTHENTICATE_PAGE_TITLES.forgotPasswordReset} />
      <Wrapper>
        <Logo />
        <Box>
          <Typography variant="h4" textAlign="center" mb={4}>
            Password Reset
          </Typography>
          <ResetPasswordForm />
        </Box>
        <Copyright />
        <GlobalLoader open={isValidating} />
        <ErrorDialog title="Invalid Link" error={error} onClose={backToLogin} />
      </Wrapper>
    </>
  );
};
