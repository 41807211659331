export const NOTIFICATIONS_DOMAIN = 'notifications';
export const NOTIFICATIONS_DOMAIN_URL = `/${NOTIFICATIONS_DOMAIN}/*`;

export const NOTIFICATIONS_PATHS = {
  list: '/list',
};

export const NOTIFICATIONS_PAGE_TITLES = {
  list: 'Notifications',
};
