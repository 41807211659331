export const INVOICES_DOMAIN = 'invoices';
export const INVOICES_DOMAIN_URL = `/${INVOICES_DOMAIN}/*`;

export const INVOICES_PATHS = {
  list: '/list',
  details: '/details',
  paymentProcessing: '/payment-processing',
  paymentSourceAdded: '/payment-source-added',
  billingHistory: '/billing-history',
};

export const INVOICES_PAGE_TITLES = {
  list: 'Billing',
  details: 'Invoice Details',
  paymentProcessing: 'Payment Processing',
  paymentSourceAdded: 'Payment Source Added',
  billingHistory: 'Billing History',
};
