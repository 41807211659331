import React from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { setAuthLoginRedirect } from '@liscio/common';
import { GlobalLoader } from '@liscio/ui';

import { useAuth } from 'fetch-utils/users/use-auth';

export type AuthRouteProps = {
  // Route to redirect to if user is not authenticated
  unauthenticatedRedirect?: string;
  // Route to redirect to if user is not authenticated (useful for public auth pages)
  authenticatedRedirect?: string;
};

/**
 * AuthRoute allows for inspecting auth state and redirecting as necessary.
 */
export const AuthRoute: React.FC<AuthRouteProps> = ({
  unauthenticatedRedirect,
  authenticatedRedirect,
}) => {
  const location = useLocation();
  const { isInitialized, isLoggedIn, isLoggingIn, isLoggingOut } = useAuth();

  // render global loader if initializing/logging in/out
  if (!isInitialized || isLoggingIn || isLoggingOut) {
    return <GlobalLoader open />;
  }

  // redirect on authenticated
  if (authenticatedRedirect && isLoggedIn) {
    return <Navigate to={authenticatedRedirect} replace />;
  }

  // redirect on unauthenticated
  if (unauthenticatedRedirect && !isLoggedIn) {
    // Save location to return to after authentication
    if (location.pathname !== '/authenticate/logout') {
      setAuthLoginRedirect(`${location.pathname}${location.search}`);
    }
    return <Navigate to={unauthenticatedRedirect} replace />;
  }

  // Render Route
  return <Outlet />;
};
