import React from 'react';

import { Skeleton } from '@mui/material';

import { Box, Button, styled, Typography } from '@liscio/ui';

import { AppHeaderWrapper } from 'components';

export interface HeaderProps {
  title: string;
  isLoading?: boolean;
  numberOfItems?: number | null;
  onSeeAllClick: () => void;
}

const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TitleWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const Title = styled(Typography)({
  fontSize: '18px',
  fontWeight: '600',
  lineHeight: '24px',
});

export const Header: React.FC<HeaderProps> = ({
  title,
  isLoading,
  numberOfItems,
  onSeeAllClick,
}) => {
  return (
    <AppHeaderWrapper>
      <Wrapper>
        <TitleWrapper>
          <Title>{title}</Title>
          <Title>|</Title>
          {isLoading && <Skeleton width="30px" />}
          {!isLoading && <Title>{numberOfItems}</Title>}
        </TitleWrapper>
        <Button onClick={onSeeAllClick}>See All</Button>
      </Wrapper>
    </AppHeaderWrapper>
  );
};

export default Header;
