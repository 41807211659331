import React, { useState } from 'react';

import { IconButton, styled, Stack, Typography, Icon } from '@liscio/ui';

import FileInFormPreview from '../FileInFormPreview/FileInFormPreview';

const ICON_GAP = { marginRight: '10px' };

interface FileInFormRowProps {
  file: File;
  hideActions?: boolean;
  onDeleteClick: () => void;
}

const RowWrapper = styled(Stack)({
  padding: '0px 20px 0px 20px',
  width: '100%',
});

export default function FileInFormRow({
  file,
  hideActions,
  onDeleteClick,
}: FileInFormRowProps) {
  const [showPreview, setShowPreview] = useState(false);
  if (!file) return null;

  const isPdf = file?.type?.includes('application/pdf');
  const isImage = file?.type?.includes('image/');

  return (
    <>
      <RowWrapper
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" alignItems="center" textAlign="left">
          {isPdf && <Icon icon="filePdf" sx={ICON_GAP} />}
          {isImage && <Icon icon="fileImage" sx={ICON_GAP} />}
          {!isPdf && !isImage && <Icon icon="file" sx={ICON_GAP} />}

          <Typography variant="body2" onClick={() => setShowPreview(true)}>
            {file.name}
          </Typography>
        </Stack>
        {!hideActions && (
          <Stack direction="row">
            <IconButton onClick={() => setShowPreview(true)}>
              <Icon icon="preview" />
            </IconButton>
            <IconButton
              data-testid="delete-file-from-form"
              onClick={onDeleteClick}
            >
              <Icon icon="delete" />
            </IconButton>
          </Stack>
        )}
      </RowWrapper>
      <FileInFormPreview
        file={file}
        open={showPreview}
        closeFunction={() => setShowPreview(false)}
      />
    </>
  );
}
