import { useCallback } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { LoginInput, LoginWithMagicLinkRequest } from '@liscio/api';
import {
  useAuthStore,
  clearAuthSession,
  setAuthSession,
  useCpaStore,
} from '@liscio/common';

import apiClient from 'fetch-utils/api-client';
import { clearApplicationCpa } from 'stores/cpa/cpa-utils';

const {
  fetchLogin,
  fetchLogout,
  changePasswordWithToken,
  loginWithOAuthCode,
  loginWithOauthTokenAndCpaId,
  requestBackupMfaCode,
  requestForgotPassword,
  requestForgotPasswordMagicLink,
  switchCpa,
  validateForgotPasswordToken,
  loginWithMagicLink,
} = apiClient.auth;

export function useLoginMutation() {
  const setAuth = useAuthStore(({ setAuth }) => setAuth);
  const client = useQueryClient();

  return useMutation({
    mutationFn: (userData: LoginInput) => {
      client.clear();
      return fetchLogin(userData);
    },
    onSuccess: async (res, variables) => {
      // Only store session when auth_token present
      if (res && res.auth_token) {
        setAuth(res);
        await setAuthSession(res);
      }
    },
  });
}

export function useLogoutMutation() {
  const clearAuth = useAuthStore(({ clearAuth }) => clearAuth);
  const setExplicitLogout = useAuthStore(
    ({ setExplicitLogout }) => setExplicitLogout
  );
  const clearCpa = useCpaStore(({ clearCpa }) => clearCpa);
  const client = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      try {
        // NOTE: There are currently errors with logout from mis-matched domains
        await fetchLogout();
      } catch (err) {}
    },
    onSuccess: async () => {
      client.clear();
      await clearAuthSession();
      clearAuth();
      // Clear cpa data
      clearCpa();
      await clearApplicationCpa();
      // For preventing biometric auto-login after logout
      setExplicitLogout();
    },
  });
}

export const useLoginWithOAuthCodeMutation = () => {
  return useMutation({
    mutationFn: loginWithOAuthCode,
  });
};

export const useLoginWithIauthTokenAndCpaId = () => {
  return useMutation({
    mutationFn: loginWithOauthTokenAndCpaId,
  });
};

export const useSwitchCpaMutation = () => {
  const client = useQueryClient();
  const setAuthToken = useAuthStore(({ setAuthToken }) => setAuthToken);

  return useMutation({
    mutationFn: switchCpa,
    onSuccess: async (data) => {
      client.clear();
      const session = setAuthToken(data.auth_token);
      await setAuthSession(session);
    },
  });
};

export const useRequestBackupMfaCodeMutation = () => {
  return useMutation({
    mutationFn: requestBackupMfaCode,
  });
};

export const useForgotPassword = () => {
  const forgotPasswordEmail = useAuthStore(
    ({ forgotPasswordEmail }) => forgotPasswordEmail
  );
  const setForgotPasswordEmail = useAuthStore(
    ({ setForgotPasswordEmail }) => setForgotPasswordEmail
  );

  const requestMutation = useMutation({
    mutationFn: requestForgotPassword,
  });
  const magicLinkMutation = useMutation({
    mutationFn: requestForgotPasswordMagicLink,
  });
  const validateTokenMutation = useMutation({
    mutationFn: validateForgotPasswordToken,
  });
  const changePasswordMutation = useMutation({
    mutationFn: changePasswordWithToken,
  });

  return {
    forgotPasswordEmail,
    setForgotPasswordEmail,
    requestForgotPassword: requestMutation,
    requestMagicLink: magicLinkMutation,
    validateToken: validateTokenMutation,
    changePassword: changePasswordMutation,
  };
};

export const useMagicLinkLogin = ({
  link = '',
  company_name = '',
}: Partial<Pick<LoginWithMagicLinkRequest, 'link' | 'company_name'>>) => {
  const setAuth = useAuthStore(({ setAuth }) => setAuth);

  const magicLinkLoginFn = useCallback(
    async (passcode?: string) => {
      const result = await loginWithMagicLink({ link, company_name, passcode });

      // Handle auth session
      if (result.auth_token) {
        const session = {
          auth_token: result.auth_token,
          email: result.email,
          two_factor_enabled: result.two_factor_enabled,
        };
        setAuth(session);
        await setAuthSession(session);
      }

      return result;
    },
    [link, company_name, setAuth]
  );

  // Handle initial magic link request
  const query = useQuery({
    queryKey: ['magicLinkLogin', link, company_name],
    queryFn: async () => magicLinkLoginFn(),
    enabled: Boolean(link),
    retry: false,
  });

  // Handle login with passcode
  const mutation = useMutation({
    mutationFn: async (passcode: string) => magicLinkLoginFn(passcode),
  });

  return {
    isLoading: query.isLoading || mutation.isLoading,
    error: query.error || mutation.error,
    loginWithPasscode: mutation.mutate,
    mfaRequired: query.data?.two_factor_enabled,
    email: query.data?.email,
  };
};
