import { BasicTask, TasksResponseBody } from '@liscio/api';

export const getTasks: (
  page: TasksResponseBody | void,
  activeTab: string
) => Array<BasicTask> = (data, activeTab) => {
  if (!data) {
    return [];
  }

  if (activeTab === 'open_tasks') {
    return data.templates[0].Open;
  }
  if (activeTab === 'pending_review') {
    return data.templates[0].Review;
  }
  if (activeTab === 'archived') {
    return data.templates[0].Archive;
  }

  return [];
};

export const extractValues: (
  data: Array<TasksResponseBody | void> | undefined,
  activeTab: string
) => {
  tasks: Array<BasicTask>;
  openNumberOfTasks?: number | null;
  reviewNumberOfTasks?: number | null;
  archiveNumberOfTasks?: number | null;
} = (data, activeTab) => {
  if (!data) {
    return {
      tasks: [],
      openNumberOfTasks: null,
      reviewNumberOfTasks: null,
      archiveNumberOfTasks: null,
    };
  }

  const tasks = data
    .map((page: TasksResponseBody | void) => {
      return getTasks(page, activeTab);
    })
    .reduce((prev: any, current: any) => {
      return [...prev, ...current];
    }, []) as Array<BasicTask>;

  return {
    tasks,
    openNumberOfTasks: data[0]?.Open_total,
    reviewNumberOfTasks: data[0]?.Review_total,
    archiveNumberOfTasks: data[0]?.Archive_total,
  };
};

export default extractValues;
