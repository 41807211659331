import React from 'react';

import { useQuery } from '@tanstack/react-query';

import { GlobalLoader } from '@liscio/ui';

import { useAuth } from 'fetch-utils/users/use-auth';

/**
 * Route to handle destroying user
 */
export const LogoutView = () => {
  const { logout } = useAuth();
  // Logout
  useQuery({
    queryFn: () => logout(),
  });

  return <GlobalLoader open />;
};
