import React from 'react';

import { DetailsState, DetailsStateProps } from '@liscio/common';
import { Button } from '@liscio/ui';

export interface StateElementProps extends DetailsStateProps {
  onClear?: () => void;
}

export const StateElement: React.FC<StateElementProps> = ({
  onNavigationClick,
  children,
  title,
  onClear,
}) => {
  const secondaryActionChildren = (
    <Button size="small" onClick={onClear}>
      Clear
    </Button>
  );
  return (
    <DetailsState
      onNavigationClick={onNavigationClick}
      title={title}
      children={children}
      secondaryActionChildren={secondaryActionChildren}
    />
  );
};

export default StateElement;
