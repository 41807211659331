import { useCallback, useMemo, useState } from 'react';

export const useFilters = <T extends {}>(initialFilters: T) => {
  const [filters, setFilters] = useState(initialFilters);

  const hasFiltersSelected = useMemo(() => {
    return Boolean(
      Object.keys(initialFilters).length !== Object.keys(filters).length ||
        Object.keys(initialFilters).find(
          (key) =>
            JSON.stringify(initialFilters[key as keyof T]) !==
            JSON.stringify(filters[key as keyof T])
        )
    );
  }, [initialFilters, filters]);
  const resetFilters = useCallback(
    () => setFilters(initialFilters),
    [setFilters, initialFilters]
  );

  return {
    filters,
    setFilters,
    hasFiltersSelected,
    resetFilters,
  };
};
