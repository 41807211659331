import React, { useState } from 'react';

import { ListItem as MUIListItem } from '@mui/material';
import { styled, Box, Typography, Icon } from '@liscio/ui';

import { Message } from '@liscio/api';

import OpenedThreadDetail from './components/OpenedThreadDetail';
import { LAST_ELEMENT_ID } from '../../useScrollToLastMessage';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  FilePreview,
} from 'components';

export interface ListItemProps {
  message: Message;
  isDefaultOpen?: boolean;
  isLastElement?: boolean;
}

const DateWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.common.blueGray[500],
  columnGap: '6px',
}));

const Date = styled(Typography)({
  flexShrink: 0,
  fontSize: '13px',
});

export const ListItem: React.FC<ListItemProps> = ({
  message,
  isDefaultOpen = false,
  isLastElement = false,
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);
  const [showFileDetailsView, setShowFileDetailsView] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState<number | undefined>();

  function handleFilePreviewClick(id: number) {
    setShowFileDetailsView(true);
    setSelectedFileId(id);
  }

  function handleFilePreviewGoBack() {
    setShowFileDetailsView(false);
    setIsOpen(true);
  }

  return (
    <MUIListItem
      disablePadding
      id={isLastElement ? LAST_ELEMENT_ID : undefined}
    >
      <ListItemButton
        onClick={() => setIsOpen((currentState) => !currentState)}
      >
        <ListItemAvatar>
          <Avatar src={message.avatar} alt="test" />
        </ListItemAvatar>
        <Box width="100%">
          <Box
            display="flex"
            justifyContent="space-between"
            textAlign="start"
            pt="6px"
          >
            <Typography sx={{ fontWeight: 500 }}>{message.sender}</Typography>
            <DateWrapper>
              <Date>{message.tym}</Date>
              {isOpen && <Icon icon="angleDown" fontSize="small" />}
              {!isOpen && <Icon icon="angleUp" fontSize="small" />}
            </DateWrapper>
          </Box>
          {isOpen && (
            <OpenedThreadDetail
              message={message}
              handleFilePreviewClick={handleFilePreviewClick}
            />
          )}
        </Box>
      </ListItemButton>
      <FilePreview
        documentId={selectedFileId || ''}
        closeFunction={handleFilePreviewGoBack}
        open={showFileDetailsView}
      />
    </MUIListItem>
  );
};

export default ListItem;
