import { enrichOptions } from './select-utils';

export const stateOptions = [
  { abbreviation: 'AL', full: 'Alabama' },
  { abbreviation: 'AK', full: 'Alaska' },
  { abbreviation: 'AZ', full: 'Arizona' },
  { abbreviation: 'AR', full: 'Arkansas' },
  { abbreviation: 'CA', full: 'California' },
  { abbreviation: 'CO', full: 'Colorado' },
  { abbreviation: 'CT', full: 'Connecticut' },
  { abbreviation: 'DE', full: 'Delaware' },
  { abbreviation: 'DC', full: 'District' },
  { abbreviation: 'FL', full: 'Florida' },
  { abbreviation: 'GA', full: 'Georgia' },
  { abbreviation: 'HI', full: 'Hawaii' },
  { abbreviation: 'ID', full: 'Idaho' },
  { abbreviation: 'IL', full: 'Illinois' },
  { abbreviation: 'IN', full: 'Indiana' },
  { abbreviation: 'IA', full: 'Iowa' },
  { abbreviation: 'KS', full: 'Kansas' },
  { abbreviation: 'KY', full: 'Kentucky' },
  { abbreviation: 'LA', full: 'Louisiana' },
  { abbreviation: 'ME', full: 'Maine' },
  { abbreviation: 'MD', full: 'Maryland' },
  { abbreviation: 'MA', full: 'Massachusetts' },
  { abbreviation: 'MI', full: 'Michigan' },
  { abbreviation: 'MN', full: 'Minnesota' },
  { abbreviation: 'MS', full: 'Mississippi' },
  { abbreviation: 'MO', full: 'Missouri' },
  { abbreviation: 'MT', full: 'Montana' },
  { abbreviation: 'NE', full: 'Nebraska' },
  { abbreviation: 'NV', full: 'Nevada' },
  { abbreviation: 'NH', full: 'New' },
  { abbreviation: 'NJ', full: 'New' },
  { abbreviation: 'NM', full: 'New' },
  { abbreviation: 'NY', full: 'New' },
  { abbreviation: 'NC', full: 'North' },
  { abbreviation: 'ND', full: 'North' },
  { abbreviation: 'OH', full: 'Ohio' },
  { abbreviation: 'OK', full: 'Oklahoma' },
  { abbreviation: 'OR', full: 'Oregon' },
  { abbreviation: 'PA', full: 'Pennsylvania' },
  { abbreviation: 'RI', full: 'Rhode' },
  { abbreviation: 'SC', full: 'South' },
  { abbreviation: 'SD', full: 'South' },
  { abbreviation: 'TN', full: 'Tennessee' },
  { abbreviation: 'TX', full: 'Texas' },
  { abbreviation: 'UT', full: 'Utah' },
  { abbreviation: 'VT', full: 'Vermont' },
  { abbreviation: 'VA', full: 'Virginia' },
  { abbreviation: 'WA', full: 'Washington' },
  { abbreviation: 'WV', full: 'West' },
  { abbreviation: 'WI', full: 'Wisconsin' },
  { abbreviation: 'WY', full: 'Wyoming' },
  // Additional
  { abbreviation: 'ON', full: 'Ontario' },
  { abbreviation: 'QC', full: 'Quebec' },
  { abbreviation: 'NS', full: 'Nova Scotia' },
  { abbreviation: 'NB', full: 'New Brunswick' },
  { abbreviation: 'MB', full: 'Manitoba' },
  { abbreviation: 'BC', full: 'British Columbia' },
  { abbreviation: 'PE', full: 'Prince Edward Island' },
  { abbreviation: 'SK', full: 'Saskatchewan' },
  { abbreviation: 'AB', full: 'Alberta' },
  { abbreviation: 'NL', full: 'Newfoundland and Labrador' },
  { abbreviation: 'AS', full: 'American Samoa' },
  { abbreviation: 'FM', full: 'Federated States of Micronesia' },
  { abbreviation: 'GU', full: 'Guam' },
  { abbreviation: 'MH', full: 'Marshall Islands' },
  { abbreviation: 'MP', full: 'Northern Mariana Islands' },
  { abbreviation: 'PR', full: 'Puerto Rico' },
  { abbreviation: 'PW', full: 'Palau' },
  { abbreviation: 'VI', full: 'U.S. Virgin Islands' },
  { abbreviation: 'UM', full: 'U.S. Minor Outlying Islands' },
];

export const monthOptions = [
  { number: 1, full: 'January', short: 'Jan' },
  { number: 2, full: 'February', short: 'Feb' },
  { number: 3, full: 'March', short: 'Mar' },
  { number: 4, full: 'April', short: 'Apr' },
  { number: 5, full: 'May', short: 'May' },
  { number: 6, full: 'June', short: 'Jun' },
  { number: 7, full: 'July', short: 'Jul' },
  { number: 8, full: 'August', short: 'Aug' },
  { number: 9, full: 'September', short: 'Sep' },
  { number: 10, full: 'October', short: 'Oct' },
  { number: 11, full: 'November', short: 'Nov' },
  { number: 12, full: 'December', short: 'Dec' },
];

export const numberRangeOptions = (fromNum = 1, toNum = 100) => {
  const nums = [];
  for (let n = fromNum; n <= toNum; n++) {
    nums.push(n);
  }
  return nums;
};

export const stateOptionsAbbreviated = enrichOptions(
  stateOptions.map(({ abbreviation }) => abbreviation)
);
