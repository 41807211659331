import React, { useMemo } from 'react';

import { FormControlLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { getUploaderTypeOptions } from './helpers';
import { Select } from 'components';
import { useProfileData } from 'fetch-utils/users/user-hooks';

export interface UploaderTypeFormProps {}

export const UploaderTypeForm: React.FC<UploaderTypeFormProps> = () => {
  const { setValue, watch } = useFormContext();
  const { isFirmUser, isLoading } = useProfileData();
  const uploaderTypeOptions = getUploaderTypeOptions({ isFirmUser, isLoading });

  const value = watch('file_uploaded_by');
  const selectedOption = useMemo(
    () => uploaderTypeOptions.find((item) => item.value === value),
    [uploaderTypeOptions, value]
  );

  return (
    <>
      <FormControlLabel
        control={
          <Select
            title="File Uploaded By"
            data={uploaderTypeOptions}
            onSearchChange={() => {}}
            onChange={(item) => setValue('file_uploaded_by', item?.value)}
          />
        }
        label=""
        labelPlacement="top"
        value={selectedOption}
      />
    </>
  );
};

export default UploaderTypeForm;
