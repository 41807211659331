import * as yup from 'yup';

import { TaskType } from '@liscio/api';

// NOTE: This may change form different task types...
export const validationSchema = yup.object({
  // employee required for to_do task
  employee: yup.object().when('task_type_key', {
    is: TaskType.ToDo,
    then: (schema) => schema.required('Please select an employee'),
  }),
  // contact required for request_info task
  contact: yup
    .object()
    .nullable()
    .when('task_type_key', {
      is: TaskType.RequestInformation,
      then: (schema) => schema.required('Please select a contact'),
    }),
  // Subject is always required
  subject: yup.string().required('Please enter a task subject'),
});
