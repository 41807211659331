import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import {
  AUTHENTICATE_PAGE_TITLES,
  AUTHENTICATE_PATHS,
} from './route-constants';
import {
  CpaSelectionView,
  ForgotPasswordResetView,
  ForgotPasswordView,
  LogoutView,
  LoginFlaggedView,
  OAuthRedirectFlaggedView,
  MagicLinkLoginFlaggedView,
} from './views';
import { AuthRoute, CpaRoute } from 'components';
import { DEFAULT_VIEW_URL } from 'constants/settings';

export const ModuleRoutes = () => {
  return (
    <>
      <Helmet title={AUTHENTICATE_PAGE_TITLES.login} />
      <Routes>
        {/* Public Routes */}
        <Route element={<AuthRoute authenticatedRedirect={DEFAULT_VIEW_URL} />}>
          <Route path={AUTHENTICATE_PATHS.login} Component={LoginFlaggedView} />
          <Route
            path={AUTHENTICATE_PATHS.oauthRedirect}
            Component={OAuthRedirectFlaggedView}
          />
          <Route
            path={AUTHENTICATE_PATHS.forgotPassword}
            Component={ForgotPasswordView}
          />
          <Route
            path={AUTHENTICATE_PATHS.forgotPasswordReset}
            Component={ForgotPasswordResetView}
          />
          <Route
            path={AUTHENTICATE_PATHS.magicLink}
            Component={MagicLinkLoginFlaggedView}
          />
        </Route>
        {/* Authenticated Routes */}
        <Route
          element={<AuthRoute unauthenticatedRedirect="/authenticate/login" />}
        >
          <Route
            element={
              <CpaRoute redirectOn="selected" redirectTo={DEFAULT_VIEW_URL} />
            }
          >
            <Route
              path={AUTHENTICATE_PATHS.cpaSelection}
              Component={CpaSelectionView}
            />
          </Route>
          <Route path={AUTHENTICATE_PATHS.logout} Component={LogoutView} />
        </Route>
      </Routes>
    </>
  );
};

export default ModuleRoutes;
