import React from 'react';

import { useInitializedFlags } from '@liscio/common';
import { GlobalLoader } from '@liscio/ui';

import LoginV5View from './LoginV5View/LoginV5View';
import LoginView from './LoginView/LoginView';

export const LoginFlaggedView = () => {
  const {
    isInitialized,
    flags: { v5Login },
  } = useInitializedFlags();

  if (!isInitialized) {
    return <GlobalLoader open />;
  }

  if (v5Login) {
    return <LoginV5View />;
  }

  return <LoginView />;
};

export default LoginFlaggedView;
