import React, { useCallback, useEffect, useRef } from 'react';

import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import { OAuthProviderType } from '@liscio/api';
import { GlobalLoader } from '@liscio/ui';

import { ErrorDialog } from 'components/ErrorDialog';
import { CpaSelection } from 'modules/authenticate/components/CpaSelection';
import { useOAuth } from 'modules/authenticate/components/OAuth';

export const OAuthRedirectView = () => {
  const navigate = useNavigate();
  const { provider } = useParams<{ provider: string }>();
  const [query] = useSearchParams();
  const code = query.get('code') || '';
  const nonce = query.get('state') || '';

  const { handleOAuthResponse, cpas, selectCpa, error } = useOAuth(
    provider as OAuthProviderType
  );
  const goBackToLogin = useCallback(
    () => navigate('/authenticate/login', { replace: true }),
    [navigate]
  );
  // NOTE: We use this ref to deal with effects called twice in React 18 Strict Mode
  const oauthInitialized = useRef(false);

  useEffect(() => {
    if (!oauthInitialized.current) {
      handleOAuthResponse({ code, nonce });
    }
    return () => {
      oauthInitialized.current = true;
    };
  }, [code, nonce, handleOAuthResponse]);

  // Render OAuth error
  if (error) {
    return (
      <ErrorDialog
        title="Sign In Error"
        error={error}
        onClose={goBackToLogin}
      />
    );
  }

  // Handle Oauth code flow multi-cpa selection
  if (cpas?.length) {
    return (
      <CpaSelection
        cpas={cpas}
        selectCpa={selectCpa}
        onCancel={goBackToLogin}
      />
    );
  }

  return <GlobalLoader open />;
};
