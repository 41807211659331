import React, { useMemo } from 'react';

import { BiometryType } from '@capgo/capacitor-native-biometric';

import { useBiometrics } from '@liscio/common';
import { IconButton, Icon, FaceIDIcon } from '@liscio/ui';

import { config } from 'config';

export interface BiometricsLoginTriggerProps {
  biometricLogin: () => any;
}

export const BiometricsLoginTrigger: React.FC<BiometricsLoginTriggerProps> = ({
  biometricLogin,
}) => {
  const { isBiometricLoginEnabled, biometryType } = useBiometrics({
    webAppUrl: config.webApp.url,
  });
  const biometricIcon = useMemo(() => {
    switch (biometryType) {
      case BiometryType.FACE_ID:
      case BiometryType.FACE_AUTHENTICATION:
        return <FaceIDIcon fontSize="large" />;
      case BiometryType.TOUCH_ID:
      case BiometryType.FINGERPRINT:
      case BiometryType.MULTIPLE:
        return <Icon icon="fingerprint" fontSize="large" />;
    }
  }, [biometryType]);

  if (isBiometricLoginEnabled) {
    return (
      <IconButton onClick={biometricLogin} edge="end" color="inherit">
        {biometricIcon}
      </IconButton>
    );
  }

  return null;
};
