import {
  useMutation,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  CancelControllers,
  FileFiltersType,
  FilePercentsMap,
  SearchDocumentsArgs,
  UploadDocumentConfig,
} from '@liscio/api';

import {
  calculateDocumentPageStats,
  mungeTags,
  mungeToRecipientTuples,
} from './documentsCallsUtils';
import apiClient from 'fetch-utils/api-client';
import { UploadDocumentFormData } from 'modules/forms/documents-form/useUploadDocumentForm';

const {
  uploadAndAttachDocument,
  getDocumentDetails,
  getDocumentInfo,
  archiveDocuments,
  updateDocument,
  uploadAndSendDocDirectly,
  getAccountDocuments,
  deleteDocument,
  getContactDocuments,
  getLiscioVaultDocuments,
} = apiClient.documents;

export interface DefaultUploadHookInterface
  extends DefaultMutationHookInterface {
  taskId?: string;
  fileId?: string;
  onUploadProgress?: (percentMap: FilePercentsMap) => void;
  setCancelController?: (controller: CancelControllers) => void;
}

export function useInfiniteContactDocuments(
  contactId: string,
  filters: SearchDocumentsArgs
) {
  return useInfiniteQuery({
    queryKey: ['files', contactId, filters],
    queryFn: async ({ pageParam = 1 }) => {
      return await getContactDocuments(contactId!, {
        page: pageParam,
        ...filters,
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      const { currentPage, totalPages } = calculateDocumentPageStats(
        lastPage!.response_hash
      );
      if (currentPage === totalPages) return undefined;

      return allPages.length + 1;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      const { currentPage } = calculateDocumentPageStats(
        firstPage!.response_hash
      );

      if (currentPage === 1) {
        return undefined;
      }
      return allPages.length - 1;
    },
  });
}

export function useInfinitiveLiscioVaultDocuments(params: SearchDocumentsArgs) {
  return useInfiniteQuery({
    queryKey: ['files', params],
    queryFn: async ({ pageParam = 1 }) => {
      return await getLiscioVaultDocuments({
        page: pageParam,
        ...params,
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      const { currentPage, totalPages } = calculateDocumentPageStats(
        lastPage!.response_hash
      );
      if (currentPage === totalPages) return undefined;

      return allPages.length + 1;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      const { currentPage } = calculateDocumentPageStats(
        firstPage!.response_hash
      );

      if (currentPage === 1) {
        return undefined;
      }
      return allPages.length - 1;
    },
  });
}

export function useDocumentDetails(id: string, enabled = true) {
  return useQuery({
    queryKey: ['fileDetails', id],
    queryFn: getDocumentDetails,
    enabled: enabled && Boolean(id),
  });
}

export function useDocumentInfo(ids: string[]) {
  return useQuery({
    queryKey: ['document-info', ...ids],
    queryFn: () => getDocumentInfo(ids),
    enabled: ids.length > 0,
  });
}

export const useAccountDocuments = (
  accountId: string,
  filters?: FileFiltersType
) => {
  return useInfiniteQuery({
    queryKey: ['files', accountId, filters],
    queryFn: async ({ pageParam = 1 }) => {
      const result = await getAccountDocuments(accountId, {
        page: pageParam,
        ...filters,
      });

      return result;
    },
    getNextPageParam: (lastPage, allPages) => {
      const { currentPage, totalPages } = calculateDocumentPageStats(
        lastPage!.response_hash
      );
      if (currentPage === totalPages) return undefined;

      return allPages.length + 1;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      const { currentPage } = calculateDocumentPageStats(
        firstPage!.response_hash
      );

      if (currentPage === 1) {
        return undefined;
      }
      return allPages.length - 1;
    },
  });
};

export function useArchiveDocument(props?: DefaultMutationHookInterface) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: archiveDocuments,
    onSuccess: (res, variables) => {
      queryClient.removeQueries({ queryKey: ['files'] });
      queryClient.removeQueries(['fileDetails', String(variables.documentId)]);
      props?.onSuccess && props.onSuccess(res);
    },
  });
}

export function useDeleteDocument(props?: DefaultMutationHookInterface) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteDocument,
    onSuccess: (res, variables) => {
      queryClient.removeQueries({ queryKey: ['files'] });
      queryClient.removeQueries(['fileDetails', String(variables.documentId)]);
      props?.onSuccess && props.onSuccess(res);
    },
  });
}

export function useUpdateDocument(props?: DefaultMutationHookInterface) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateDocument,
    onSuccess: (res) => {
      queryClient.invalidateQueries(['files']);
      queryClient.invalidateQueries(['messageThread']);
      queryClient.invalidateQueries(['fileDetails', String(res.data.id)]);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export type DocumentFormAndConfig = {
  documentsConfig: UploadDocumentConfig;
  formValues: UploadDocumentFormData;
};

export function useAttachDocument(props?: DefaultUploadHookInterface) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      documentsConfig,
      formValues: { tags, year, month },
    }: DocumentFormAndConfig) =>
      uploadAndAttachDocument(documentsConfig, {
        tags: mungeTags(tags),
        is_draft: false,
        year,
        month,
        task_id: props?.taskId ? String(props?.taskId) : '',
      }),
    onSuccess: (res) => {
      queryClient.invalidateQueries(['taskDetails', String(props?.taskId)]);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useSendNewDocument(props?: DefaultUploadHookInterface) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      documentsConfig,
      formValues: { tags, year, month, message, participants },
    }: DocumentFormAndConfig) => {
      const isMessageAltered = false;

      return uploadAndSendDocDirectly(
        documentsConfig,
        {
          tags: mungeTags(tags),
          is_draft: false,
          year,
          month,
          message,
          recipients: mungeToRecipientTuples(participants!),
        },
        isMessageAltered
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['files']);
      props?.onSuccess && props.onSuccess();
    },
  });
}
