import { useLayoutEffect, useDeferredValue } from 'react';

import {
  useInfiniteQuery,
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { CreateTaskRequest } from '@liscio/api';

import apiClient from 'fetch-utils/api-client';

const {
  getTasks,
  getTaskDetails,
  updateTaskStatus,
  signDocumentAndGeneratePdf,
  addComment,
  getContactTasks,
  reOpenTask,
  createTask,
} = apiClient.tasks;

export function useInfinitiveTasks(
  type: string,
  taskTypes: string,
  account: string,
  sortOrder: string,
  sortField: string,
  dashboardParameter?: string
) {
  return useInfiniteQuery({
    queryKey: [
      'tasks',
      type,
      taskTypes,
      account,
      sortOrder,
      sortField,
      dashboardParameter || '',
    ],
    queryFn: getTasks,
    getNextPageParam: (lastPage, allPages) => {
      if (
        lastPage?.templates[0].Archive.length === 0 &&
        lastPage?.templates[0].Draft.length === 0 &&
        lastPage?.templates[0].Open.length === 0 &&
        lastPage?.templates[0].Review.length === 0
      ) {
        return undefined;
      }
      return allPages.length + 1;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      return allPages.length - 1;
    },
  });
}

export function useTaskDetails(taskId: string, tab?: string) {
  const client = useQueryClient();

  const queryObject = useQuery({
    queryKey: ['taskDetails', taskId],
    queryFn: getTaskDetails,
  });

  const { fetchStatus } = queryObject;
  const deferredFetchStatus = useDeferredValue(fetchStatus);

  // We only want to clear the cache when the request has been sent to the server
  const hasFetchedData =
    deferredFetchStatus === 'fetching' && fetchStatus === 'idle';

  useLayoutEffect(() => {
    // At this point we cannot detect whether the task has already been read
    if (hasFetchedData) {
      tab
        ? client.refetchQueries(['tasks', tab])
        : client.refetchQueries(['tasks']);
    }
  }, [client, hasFetchedData, tab]);

  return queryObject;
}

export function useUpdateTaskStatus(props?: DefaultMutationHookInterface) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (taskId: number) => updateTaskStatus(taskId),
    onSuccess: (_, taskId) => {
      if (taskId) {
        client.refetchQueries(['taskDetails', String(taskId)]);
        client.invalidateQueries({ queryKey: ['contactTasks'] });
        client.removeQueries(['tasks', 'open_tasks']);
      }
      props?.onSuccess && props.onSuccess();
    },
  });
}

export const useReOpenTaskMutation = (taskId: string | number) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async () => reOpenTask(String(taskId)),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['taskDetails', String(taskId)] });
      client.invalidateQueries({ queryKey: ['contactTasks'] });
      client.invalidateQueries({ queryKey: ['tasks'] });
    },
  });
};

export function useSignDocumentAndGeneratePdf(
  props?: DefaultMutationHookInterface
) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({
      signedContent,
      taskId,
    }: {
      signedContent: string;
      taskId: number | string;
    }) => signDocumentAndGeneratePdf(signedContent, taskId),
    onSuccess: (_, taskId) => {
      if (taskId) {
        client.invalidateQueries(['taskDetails', String(taskId)]);
        client.removeQueries(['tasks']);
      }
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useAddComment(props?: DefaultMutationHookInterface) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({
      comment,
      taskId,
      rejectTask,
    }: {
      comment: string;
      taskId: number | string;
      rejectTask?: boolean;
    }) => addComment(comment, taskId, rejectTask),
    onSuccess: (data, { taskId }) => {
      if (taskId) {
        client.invalidateQueries(['taskDetails', String(taskId)]);
        client.refetchQueries(['tasks']);
      }
      props?.onSuccess && props.onSuccess(data);
    },
  });
}

export const useContactTasks = (contactId: string | number) => {
  return useQuery({
    queryKey: ['contactTasks', contactId],
    queryFn: async () => getContactTasks(contactId),
  });
};

export const useCreateTask = () => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (data: CreateTaskRequest) => createTask(data),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['tasks'] });
      client.invalidateQueries({ queryKey: ['contactTasks'] });
    },
  });
};
