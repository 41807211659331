import { useCallback, useEffect, useMemo } from 'react';

import { Capacitor } from '@capacitor/core';
import { useQueryClient } from '@tanstack/react-query';

import { useCpaStore } from '@liscio/common';

import { getSavedApplicationCpa, saveApplicationCpa } from './cpa-utils';
import { CpaStateItem } from './cpa.types';
import { useSwitchCpaMutation } from 'fetch-utils/users/auth-hooks';
import { useHomeData } from 'fetch-utils/users/user-hooks';

/**
 * Convenience hook for managing user Cpa(s)
 * NOTE: This hook should only be used in authorized views since it
 * utilizes home data which requires an auth token
 * TODO: Handle parsing cpa id from query parameters to select cpa
 */
export const useCpa = () => {
  const {
    isLoading,
    setIsLoading,
    isInitialized,
    setIsInitialized,
    sessionCpa,
    setSessionCpa,
    appCpa,
    setAppCpa,
    queryCompany,
    setQueryCompany,
  } = useCpaStore();
  const { data: homeData, isLoading: homeDataIsloading } = useHomeData();
  const {
    mutateAsync: switchCpaMutation,
    isLoading: isSwitchingCpa,
    error: switchCpaError,
  } = useSwitchCpaMutation();
  const queryClient = useQueryClient();

  // List of user related cpas
  const cpas = useMemo(() => {
    const cpaList: CpaStateItem[] = [];

    // Handle user session cpa
    const userData = homeData?.data;
    if (
      userData?.company_url &&
      userData?.cpa_id &&
      userData?.cpa_name &&
      userData?.cpa_logo
    ) {
      // Add to cpa list
      cpaList.push({
        id: userData.cpa_id,
        name: userData.cpa_name,
        logo: userData.cpa_logo,
        custom_liscio_url: userData.company_url,
      });
    }

    // Add home cpa_data array
    if (homeData?.cpa_data) {
      const newCpas = homeData.cpa_data.filter(
        (itm) => !cpaList.find((existingCpa) => existingCpa.id === itm.id)
      );
      cpaList.push(...newCpas);
    }

    return cpaList;
  }, [homeData]);

  // Select Application Cpa
  const selectCpa = useCallback(
    async (cpa: CpaStateItem) => {
      // Only switch cpa in backend if different from existing session cpa
      if (!sessionCpa || sessionCpa.id !== cpa.id) {
        await switchCpaMutation({ cpa_id: cpa.id! });
      }
      setAppCpa(cpa);
      await saveApplicationCpa(cpa);
    },
    [sessionCpa, setAppCpa, switchCpaMutation]
  );

  const switchCpa = useCallback(
    async (cpaId: number) => {
      const selectedCpa = cpas.find((cpa) => cpa.id === cpaId);

      if (selectedCpa) {
        try {
          await switchCpaMutation({ cpa_id: cpaId });

          const newCpa = {
            id: selectedCpa.id,
            name: selectedCpa.name,
            logo: selectedCpa.logo,
            custom_liscio_url: selectedCpa.custom_liscio_url,
          };
          setAppCpa(newCpa);
          await saveApplicationCpa(newCpa);

          if (Capacitor.isNativePlatform()) {
            // On native platforms we refresh the user session
            queryClient.invalidateQueries();
          } else {
            // On web, we redirect
            window.location.href = newCpa.custom_liscio_url || '';
          }
        } catch {}
      }
    },
    [switchCpaMutation, setAppCpa, cpas, queryClient]
  );

  // Handle loading session cpa from homeData
  useEffect(() => {
    const userSessionData = homeData?.data;
    if (
      userSessionData &&
      userSessionData.cpa_id &&
      userSessionData.company_url &&
      userSessionData.cpa_id !== sessionCpa?.id
    ) {
      setSessionCpa({
        id: userSessionData.cpa_id,
        name: userSessionData.cpa_name,
        custom_liscio_url: userSessionData.company_url,
        logo: userSessionData.cpa_logo,
      });
    }
  }, [sessionCpa, setSessionCpa, homeData?.data]);

  // App CPA initilization
  useEffect(() => {
    // NOTE: This only gets called when already authenticated
    const initCpa = async () => {
      // Set loading state to avoid multiple executions
      setIsLoading(true);

      // autoset cpa when only 1 available
      if (cpas.length === 1 && !appCpa) {
        selectCpa(cpas[0]);
      } else if (cpas.length > 1) {
        // Multiple cpas

        // Check for cpa from storage
        const savedCpa = await getSavedApplicationCpa();
        if (savedCpa && cpas.length > 1) {
          // Validate saved cpa against user cpas
          const validatedCpa = cpas.find((cpa) => cpa.id === savedCpa.id);
          if (validatedCpa) {
            selectCpa(validatedCpa);
          }
        }

        // Handle company_name query param (will overwrite stored cpa)
        if (queryCompany && cpas.length > 1) {
          // Validate query company_name against user cpas
          const validatedCpa = cpas.find(
            (cpa) =>
              cpa.custom_liscio_url?.replace(/^(https:\/\/)?/, '') ===
              queryCompany
          );
          if (validatedCpa) {
            selectCpa(validatedCpa);
          } else {
            // Clear invalid query company_name
            setQueryCompany(undefined);
          }
        }
      }

      // init complete
      setIsInitialized(true);
      setIsLoading(false);
      setQueryCompany(undefined);
    };

    if (!isInitialized && !isLoading && cpas.length && sessionCpa) {
      initCpa();
    }
  }, [
    cpas,
    sessionCpa,
    appCpa,
    selectCpa,
    queryCompany,
    setQueryCompany,
    isLoading,
    setIsLoading,
    isInitialized,
    setIsInitialized,
  ]);

  return {
    cpa: appCpa,
    cpas,
    selectCpa,
    isLoading:
      !isInitialized || isLoading || homeDataIsloading || isSwitchingCpa,
    switchCpa,
    error: switchCpaError,
  };
};
