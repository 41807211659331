import React, { useState } from 'react';

import { AppBar, Overlay } from '@liscio/common';
import { GlobalLoader } from '@liscio/ui';

import {
  StateElement,
  PDFViewer,
  DetailsDrawer,
  UnsupportedFileTypeViewer,
} from './components';
import { ScreenWrapper } from 'components/screenComposition';
import { useDocumentDetails } from 'fetch-utils/documents/documents-hooks';

export interface FilePreviewProps {
  documentId: string | number;
  open: boolean;
  closeFunction: () => void;
}

export const FilePreview: React.FC<FilePreviewProps> = ({
  documentId,
  open,
  closeFunction,
}) => {
  const [isDetailsSectionOpen, setIsDetailsSectionOpen] = useState(false);
  const { data: file, isFetching } = useDocumentDetails(String(documentId));

  return (
    <Overlay open={open}>
      <ScreenWrapper>
        <AppBar
          onNavigationClick={closeFunction}
          DefaultStateElement={StateElement}
          title={file?.doc_name}
          hideSecondaryActionButton
          onSecondaryActionClick={() => setIsDetailsSectionOpen(true)}
        />
        {file?.file_type === 'PDF' && !isFetching && (
          <PDFViewer url={file.file_download_url} />
        )}
        {file?.file_type !== 'PDF' && !isFetching && (
          <UnsupportedFileTypeViewer file={file} />
        )}
      </ScreenWrapper>
      {Boolean(file) && (
        <DetailsDrawer
          file={file}
          open={isDetailsSectionOpen}
          onClose={() => setIsDetailsSectionOpen(false)}
          closeFilePreviewFunction={closeFunction}
        />
      )}
      <GlobalLoader open={isFetching} />
    </Overlay>
  );
};

export default FilePreview;
