import React from 'react';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { config } from './config';
import reportWebVitals from './reportWebVitals';
import { UnexpectedError } from 'modules/errors/views';

import './index.css';

// TODO: DEV-3583 Hoist Sentry wrapper to @liscio/common
if (config.sentry.isEnabled) {
  Sentry.init(
    {
      dsn: config.sentry.dsn,
      release: config.version,
      environment: config.appIdentifier,
      beforeBreadcrumb: (
        breadcrumb: Sentry.Breadcrumb,
        hint
      ): Sentry.Breadcrumb | null => {
        // Called before a breadcrumb is sent, optionally filters keys from breadcrumb get request urls
        const awsLinkQueryParams = [
          'response-content-disposition',
          'Expires',
          'Signature',
          'Key-Pair-Id',
        ];

        const breadcrumbUrl = breadcrumb?.data?.url
          ? new URL(breadcrumb.data.url)
          : undefined;
        if (!breadcrumbUrl) {
          return breadcrumb;
        }

        const isAWSLink = awsLinkQueryParams.every((param) =>
          breadcrumbUrl.search.includes(param)
        );

        /* eslint-disable no-param-reassign */
        if (isAWSLink && breadcrumb?.data?.url) {
          breadcrumbUrl.search = '';
          breadcrumb.data.url = breadcrumbUrl.href;
        }
        /* eslint-enable no-param-reassign */

        // Add requestId to breadcrumb data
        if (breadcrumb.category === 'xhr') {
          const requestId =
            hint?.xhr?.__sentry_xhr_v2__?.request_headers?.['x-request-id'];
          if (requestId) {
            breadcrumb.data = {
              ...(breadcrumb.data || {}),
              requestId,
            };
          }
        }

        return breadcrumb;
      },
    },
    SentryReact.init
  );
}

const AppComponent = config.sentry.isEnabled ? (
  <SentryReact.ErrorBoundary fallback={<UnexpectedError />}>
    <App />
  </SentryReact.ErrorBoundary>
) : (
  <App />
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<React.StrictMode>{AppComponent}</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);
