import React from 'react';

import { FormProvider } from 'react-hook-form';

import { useAuthV5Process, AuthV5ProcessProvider } from '@liscio/common';
import { Box, styled } from '@liscio/ui';

import { Logo, Copyright, SignInForm, MfaForm } from './components';
import { config } from 'config';
import { CpaLoginV5Selector } from 'modules/authenticate/components';
import { useSetLoggedInUserSession } from 'modules/authenticate/hooks';

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.common.white,
  paddingTop: '48px',
  paddingBottom: '16px',
}));

export const LoginV5View = () => {
  const setLoggedUserSession = useSetLoggedInUserSession();

  const authV5ProcessState = useAuthV5Process({
    onProcessFinished: async (loggedInUser) => {
      await setLoggedUserSession(loggedInUser);
    },
    webAppUrl: config.webApp.url,
  });

  const {
    processState,
    signInCpas,
    resetProcess,
    loginFormContext,
    submitLoginFormHandler,
    selectCpa,
    isLoading,
  } = authV5ProcessState;

  if (processState === 'cpaSelector') {
    return (
      <CpaLoginV5Selector
        cpas={signInCpas}
        onCancel={resetProcess}
        onCpaSelected={selectCpa}
      />
    );
  }

  return (
    <>
      <Logo />
      <Wrapper>
        <AuthV5ProcessProvider authV5ProcessState={authV5ProcessState}>
          <FormProvider {...loginFormContext}>
            <form onSubmit={submitLoginFormHandler}>
              {processState === 'loginForm' && (
                <SignInForm isLoading={isLoading} />
              )}
              {processState === 'mfaCode' && (
                <MfaForm backToLogin={resetProcess} isLoading={isLoading} />
              )}
            </form>
          </FormProvider>
        </AuthV5ProcessProvider>
      </Wrapper>
      <Copyright />
    </>
  );
};

export default LoginV5View;
