import React from 'react';

import { CheckboxProps as MUICheckboxProps } from '@mui/material';

import { DefaultCheckbox, InlineCheckbox } from './components';

export interface CheckboxProps extends MUICheckboxProps {
  label?: string;
  type?: 'default' | 'inline';
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  type = 'default',
  ...rest
}) => {
  if (type === 'inline') {
    return <InlineCheckbox {...rest} label={label} />;
  }
  return <DefaultCheckbox {...rest} label={label} />;
};

export default Checkbox;
