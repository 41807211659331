import React from 'react';

import { Tabs as TabsComponent, Box } from '@liscio/ui';

export interface TabsProps {
  selectedOptionValue: string;
  onNavigationClick(key?: string): void;
}

export enum AVAILABLE_TABS {
  INVOICES = 'invoices',
  RECURRING = 'recurring',
}

export const Tabs: React.FC<TabsProps> = ({
  selectedOptionValue,
  onNavigationClick,
}) => {
  const options = [
    {
      label: 'Invoices',
      value: AVAILABLE_TABS.INVOICES,
    },
    {
      label: 'Recurring',
      value: AVAILABLE_TABS.RECURRING,
    },
  ];

  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <TabsComponent
        options={options}
        selectedOptionValue={selectedOptionValue}
        onNavigationClick={onNavigationClick}
      />
    </Box>
  );
};

export default Tabs;
