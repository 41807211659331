import { useCallback } from 'react';

import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { TaskType, AccountData, SearchTaskAccountsParams } from '@liscio/api';

import apiClient from 'fetch-utils/api-client';

const {
  accounts: {
    getAccountsAsTaskData,
    getAccountsOfContactOnTheBasisOfUserId,
    searchAccounts,
    getContactAccounts,
    getAccountOverview,
    getAccountBankAccounts,
    getAccountLogins,
    editBankAccount,
    createBankAccount,
    deleteBankAccount,
    updateAccountDetails,
    getLoginSecurityQuestions,
    editAccountLogin,
    createAccountLogin,
    deleteAccountLogin,
    editSecurityQuestion,
    createSecurityQuestion,
    deleteSecurityQuestion,
    sendAccountUpdateNotification,
    getInitialTaskAccounts,
    searchTaskAccounts,
    searchToDoTaskAccounts,
  },
  tasks: { getToDoTaskData },
} = apiClient;

export function useInfinitiveAccounts({
  enabled = true,
}: {
  enabled?: boolean;
}) {
  return useInfiniteQuery({
    queryKey: ['accounts'],
    queryFn: getContactAccounts,
    getNextPageParam: (lastPage, allPages) => {
      const pageSize = 15;
      const allElementsCount = lastPage?.count_data?.all || 0;

      if (allPages.length * pageSize < allElementsCount) {
        return allPages.length + 1;
      } else {
        return undefined;
      }
    },
    getPreviousPageParam: (firstPage, allPages) => {
      return allPages.length - 1;
    },
    enabled,
  });
}

export function useAccountOverview(accountId: string = '') {
  return useQuery({
    queryKey: ['account-overview', accountId],
    queryFn: getAccountOverview,
    enabled: Boolean(accountId),
  });
}

export function useAccountBankAccounts(accountId: string) {
  return useQuery({
    queryKey: ['account-bank-accounts', accountId],
    queryFn: getAccountBankAccounts,
  });
}

export function useAccountLogins(accountId: string) {
  return useQuery({
    queryKey: ['account-logins', accountId],
    queryFn: getAccountLogins,
  });
}

export function useLoginSecurityQuestions(loginId: string) {
  return useQuery({
    queryKey: ['account-login-security-questions', loginId],
    queryFn: getLoginSecurityQuestions,
    enabled: Boolean(loginId),
  });
}

export function useAccountsOfContactOnTheBasisOfUserId() {
  return useQuery({
    queryKey: ['accounts-of-contact-on-the-basis-of-user-id'],
    queryFn: getAccountsOfContactOnTheBasisOfUserId,
  });
}

export function useAccountsAsTaskData(isCustomer: boolean) {
  return useQuery({
    queryKey: ['accounts-as-task-data'],
    queryFn: getAccountsAsTaskData,
    enabled: !isCustomer,
  });
}

export function useSearchAccounts(
  accountSearchKeyword: string,
  isCustomer: boolean
) {
  return useQuery({
    queryKey: ['searched-accounts', accountSearchKeyword],
    queryFn: searchAccounts,
    enabled: !!accountSearchKeyword && !isCustomer,
  });
}

export const useUpdateAccountOverview = (
  accountId: string,
  mutateOptions?: DefaultMutationHookInterface
) => {
  const overview = useAccountOverview(accountId);
  const account = overview.data?.account!;
  const queryClient = useQueryClient();

  const updateAccountOverview = useCallback(
    (data: Partial<AccountData>) =>
      updateAccountDetails({
        ...account,
        ...data,
      }),
    [account]
  );

  return useMutation({
    mutationFn: updateAccountOverview,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['account-overview', accountId]);
      mutateOptions?.onSuccess && mutateOptions?.onSuccess();
    },
  });
};

export function useEditBankAccount(props?: DefaultMutationHookInterface) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editBankAccount,
    onSuccess: () => {
      queryClient.invalidateQueries(['account-overview']);
      queryClient.invalidateQueries(['account-bank-accounts']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useEditSecurityQuestion(props?: DefaultMutationHookInterface) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editSecurityQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries(['account-overview']);
      queryClient.invalidateQueries(['account-logins']);
      queryClient.invalidateQueries(['account-login-security-questions']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useEditAccountLogin(props?: DefaultMutationHookInterface) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editAccountLogin,
    onSuccess: () => {
      queryClient.invalidateQueries(['account-overview']);
      queryClient.invalidateQueries(['account-logins']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useCreateAccountLogin(props?: DefaultMutationHookInterface) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createAccountLogin,
    onSuccess: () => {
      queryClient.invalidateQueries(['account-overview']);
      queryClient.invalidateQueries(['account-logins']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useCreateBankAccount(props?: DefaultMutationHookInterface) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createBankAccount,
    onSuccess: () => {
      queryClient.invalidateQueries(['account-overview']);
      queryClient.invalidateQueries(['account-bank-accounts']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useCreateSecurityQuestion(
  props?: DefaultMutationHookInterface
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createSecurityQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries(['account-overview']);
      queryClient.invalidateQueries(['account-logins']);
      queryClient.invalidateQueries(['account-login-security-questions']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useDeleteBankAccount(props?: DefaultMutationHookInterface) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteBankAccount,
    onSuccess: () => {
      queryClient.invalidateQueries(['account-overview']);
      queryClient.invalidateQueries(['account-bank-accounts']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useDeleteAccountLogin(props?: DefaultMutationHookInterface) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteAccountLogin,
    onSuccess: () => {
      queryClient.invalidateQueries(['account-overview']);
      queryClient.invalidateQueries(['account-logins']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useDeleteSecurityQuestion(
  props?: DefaultMutationHookInterface
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteSecurityQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries(['account-overview']);
      queryClient.invalidateQueries(['account-logins']);
      queryClient.invalidateQueries(['account-login-security-questions']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useSendUpdateNotification(
  props?: DefaultMutationHookInterface
) {
  return useMutation({
    mutationKey: ['sending-notification'],
    mutationFn: sendAccountUpdateNotification,
    onSuccess: (_, variables) => {
      props?.onSuccess && props.onSuccess();
    },
  });
}

export const useInitialTaskAccounts = (taskType: TaskType) =>
  useQuery({
    queryKey: ['getInitialTaskAccounts', taskType],
    queryFn: async () => {
      // to_do tasks have different initial endpoint
      if (taskType === TaskType.ToDo) {
        const taskData = await getToDoTaskData();
        return taskData.accounts;
      }

      return getInitialTaskAccounts();
    },
  });

export const useSearchTaskAccounts = (
  taskType: TaskType,
  params: SearchTaskAccountsParams
) =>
  useQuery({
    queryKey: ['searchTaskAccounts', taskType, params],
    queryFn: async () => {
      // to_do tasks have different search endpoint
      if (taskType === TaskType.ToDo) {
        const accounts = await searchToDoTaskAccounts(params.keyword);
        return accounts;
      }

      const accounts = await searchTaskAccounts(params);
      return accounts;
    },
    enabled: Boolean(params.keyword.trim()),
  });
