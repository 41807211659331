export default function removeDocIdAndFile(
  id: string,
  docIds: number[],
  fileList: File[]
) {
  // this function depends on the assumption that doc_ids and files are in the same order
  // i.e., that doc_ids[3] is the generated doc_id for the file at files[3].
  // this assumption should be true because the order in which we supply the files
  // is the order in which we get doc_ids from the Promise.allSettled() call.
  const indexOfId = docIds?.indexOf(Number(id));
  const files = Array.from(fileList) as File[];

  [docIds, files].forEach((arr) => arr.splice(indexOfId!, 1));

  return {
    newDocIds: [...docIds!],
    newFiles: [...files],
  };
}
