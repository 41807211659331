/*
 *  20240301 - Production License Web PI for Liscio Inc
 *  (*.lisciostaging.com|*.liscio.me|localhost)
 *  valid until November 15th, 2024.txt
 */
export const VITE_SCANBOT_KEY_WEB =
  'bYgxFru2ot8xrhoGY5Ij46hvrtI04n' +
  'D8h3Ra9Y93qVOvRtDfig0KJQpniAII' +
  'Pe9TFp7GX9bdyokM0UKP/bNhQ55NLm' +
  'S5xjWfHdqIn8IMpfhM6Q+M3OXQrQzt' +
  'eNfVFEcGoqN4j4JZGkcHCGFl5Mqoe6' +
  'I4yH83U6RGavmUOmdA2EHktpRLLv0B' +
  'GMso84jhx9a7oDrpDHcelRPc47676W' +
  '/7T3LEycW/NE57kREbiP1ktVUZX94p' +
  'iO3qxKYtHUI3OeGwe/YG8XBwwKkF3W' +
  'pj/EbxE7in+qCPZad8Qtf/oisDxZr1' +
  'SwBSYsIyzV2gqI99WQHKGec4AB8sA3' +
  'EGFUfCEVGI1A==\nU2NhbmJvdFNESw' +
  'oqLmxpc2Npb3N0YWdpbmcuY29tfGxv' +
  'Y2FsaG9zdDozMDAwfCoubGlzY2lvLm' +
  '1lfGxvY2FsaG9zdAoxNzM2OTg1NTk5' +
  'CjU5MAo4\n';
