import { Theme } from '@mui/material';

import { styled, Box, Button, Typography, Icon } from '@liscio/ui';
import { pluralLabel } from '@liscio/utils';

type ExtendedTheme = { theme?: { drawer: number } & Theme };

const Wrapper = styled(Box)(({ theme }: ExtendedTheme) => ({
  width: '100%',
  height: '95px',
  position: 'fixed',
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  background: 'black',
  zIndex: theme!.zIndex!.drawer + 2,
}));

const ImagePreviewBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: 0,
  bottom: 0,
  height: ['-webkit-fill-available', '100vh'],
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 5000,
  background: `rgba(255, 255, 255, .65)`,
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  height: '65%',
  alignSelf: 'center',
  width: '35vw',
  margin: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledText = styled(Typography)(() => ({
  borderRadius: '5px',
  background: 'rgba(0, 0, 0, .3)',
  padding: '10px',
  marginBottom: '10px',
  color: 'white',
}));

interface BrowserScannerOverlayProps {
  howManyPages: number;
  handleCancelClick?: () => void;
  handleConfirmClick?: () => void;
  showOverlay: boolean;
  newPagePreview?: string;
}

export default function BrowserScannerOverlay({
  howManyPages,
  handleCancelClick = () => {},
  handleConfirmClick = () => {},
  showOverlay,
  newPagePreview,
}: BrowserScannerOverlayProps) {
  if (!showOverlay) return null;

  if (newPagePreview)
    return (
      <ImagePreviewBox>
        <StyledText>Page Scanned</StyledText>
        <img width="80%" src={newPagePreview} alt="new page preview" />
      </ImagePreviewBox>
    );

  return (
    <Wrapper>
      <ButtonBox>
        <Button
          variant="contained"
          size="large"
          color="error"
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
      </ButtonBox>
      <ButtonBox>
        {howManyPages > 0 && (
          <Button
            onClick={handleConfirmClick}
            variant="contained"
            color="primary"
            size="large"
          >
            {
              <Box sx={{ fontSize: '1rem' }}>
                {howManyPages} {pluralLabel(howManyPages, 'Page', 'Pages')}
              </Box>
            }
            <Icon icon="chevronRight" />
          </Button>
        )}
      </ButtonBox>
    </Wrapper>
  );
}
