import React, { useEffect } from 'react';

import {
  ClosePaymentDialogReasons,
  RegisteredAddingCardSessionResponse,
  PlaidMetadata,
  TaskDetails,
} from '@liscio/api';
import { GlobalLoader } from '@liscio/ui';

import { ErrorDialog } from 'components';

export interface PlaidAddingBankAccountProps {
  invoice: void | TaskDetails | undefined;
  closeFunction(closeReason: ClosePaymentDialogReasons): void;
  registerPlaidPaymentSessionError?: Error | null;
  plaidRegisteredPaymentSourceSession?: RegisteredAddingCardSessionResponse;
  addCustomerBankAccount: (params: {
    accountId: string | number;
    bankAccountId: string;
    publicToken: string;
    invoiceId: string | number;
  }) => void;
}

export const PlaidAddingBankAccountState: React.FC<
  PlaidAddingBankAccountProps
> = ({
  invoice,
  closeFunction,
  registerPlaidPaymentSessionError,
  plaidRegisteredPaymentSourceSession,
  addCustomerBankAccount,
}) => {
  const accountId = invoice?.account_id || '';
  const invoiceId = invoice?.id || '';

  useEffect(() => {
    const plaidToken = plaidRegisteredPaymentSourceSession?.link_token;

    if (plaidToken) {
      const plaidConfig = {
        token: plaidToken,
        onLoad: () => {},
        onSuccess: (publicToken: string, metadata: PlaidMetadata) => {
          addCustomerBankAccount({
            accountId,
            bankAccountId: metadata.account.id,
            invoiceId: invoiceId,
            publicToken,
          });
          closeFunction(ClosePaymentDialogReasons.VOID);
        },
        onExit: () => {
          closeFunction(ClosePaymentDialogReasons.CANCEL);
        },
      };

      //@ts-ignore
      const linkHandler = window.Plaid.create(plaidConfig);
      linkHandler.open();
    }
  }, [
    accountId,
    addCustomerBankAccount,
    closeFunction,
    invoiceId,
    plaidRegisteredPaymentSourceSession?.link_token,
  ]);

  return (
    <>
      <GlobalLoader open={!registerPlaidPaymentSessionError} />
      <ErrorDialog
        error={registerPlaidPaymentSessionError}
        onClose={() => closeFunction(ClosePaymentDialogReasons.VOID)}
      />
    </>
  );
};

export default PlaidAddingBankAccountState;
