import { useSMSWebsocket } from 'providers/WebsocketProvider/hooks';

interface WebsocketLauncherProps {
  authToken: string;
}

export default function WebsocketLauncher({
  authToken,
}: WebsocketLauncherProps) {
  useSMSWebsocket(authToken);

  return null;
}
