import React, { useEffect } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';

import { clearAuthLoginRedirect, getAuthLoginRedirect } from '@liscio/common';
import { GlobalLoader } from '@liscio/ui';

/**
 * Simple component for handling redirecting authenticated users
 * to the path they attempted visiting before authentication.
 * NOTE: This is a separate component to make managing all our
 * gated route components a little easier. Maybe a code smell...
 */
export const AuthenticatedRedirectRoute = () => {
  const navigate = useNavigate();
  const storedAuthRedirect = getAuthLoginRedirect();

  useEffect(() => {
    if (storedAuthRedirect) {
      navigate(storedAuthRedirect, { replace: true });
      clearAuthLoginRedirect();
    }
  }, [storedAuthRedirect, navigate]);

  // Don't render child-components if we're redirecting
  if (storedAuthRedirect) {
    return <GlobalLoader open />;
  }

  return <Outlet />;
};
