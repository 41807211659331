import { useState } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { makeFilter, MONTHS, RECENT_YEARS } from './uploadDocumentFormUtils';
import { useHomeData } from 'fetch-utils/users/user-hooks';

export default function useUploadFormUtils() {
  const { data: homeBlob } = useHomeData();
  const [monthsFilter, setMonthsFilter] = useState('');
  const [yearsFilter, setYearsFilter] = useState('');
  const [tagsFilter, setTagsFilter] = useState('');

  const allTags = homeBlob?.data?.pref_type_options?.upload_tags || [];

  const monthsOptions = MONTHS.filter(makeFilter(monthsFilter));
  const yearsOptions = RECENT_YEARS.filter(makeFilter(yearsFilter));
  const tagsOptions = allTags.filter(makeFilter(tagsFilter));

  const { getValues, setValue } = useFormContext();
  const { append: addTag, remove: removeTagByIndex } = useFieldArray({
    name: 'tags',
  });

  function handleTagsChange(item: RichOption) {
    if (!item) return setValue('tags', []);

    const { tags } = getValues();
    const isDuplicate = tags.find(
      (itemInForm: RichOption) => item.label === itemInForm?.label
    );

    if (!isDuplicate) addTag(item);
  }

  return {
    handleTagsChange,
    removeTagByIndex,
    setMonthsFilter,
    setYearsFilter,
    setTagsFilter,
    monthsFilter,
    yearsFilter,
    tagsFilter,
    monthsOptions,
    yearsOptions,
    tagsOptions,
  };
}
