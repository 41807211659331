const mapper = {
  contact: 'contact',
  emp: 'employee',
};

export const mapRecipientTypeToLabel: (type: 'contact' | 'emp') => string = (
  type
) => {
  return mapper[type] || type;
};

export default mapRecipientTypeToLabel;
