import React from 'react';

import { LinearProgress, LinearProgressProps } from '@mui/material';

import { styled } from '@liscio/ui';

type TopLoaderProps = LinearProgressProps & {
  open: boolean;
};

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.appBar + 1,
}));

export default function TopLoader({ open, ...props }: TopLoaderProps) {
  if (!open) return null;

  return <StyledLinearProgress {...props} />;
}
