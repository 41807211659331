import React, { useCallback, useState } from 'react';

import { InputAdornment, TextField, TextFieldProps } from '@mui/material';

import { IconButton, Icon } from '@liscio/ui';

export type PasswordInputProps = TextFieldProps & {
  // Control password visibility
  visible?: boolean;
  // Click handler for visibility icon
  onVisible?: (isShowing: boolean) => void;
};

export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  PasswordInputProps
>(({ visible, onVisible, ...textFieldProps }, ref) => {
  const [isVisible, setIsVisible] = useState(visible || false);
  const passwordIsVisible = visible ?? isVisible;
  const handleVisibilityClick = useCallback(() => {
    if (onVisible) {
      onVisible(passwordIsVisible);
    } else {
      setIsVisible(!passwordIsVisible);
    }
  }, [passwordIsVisible, onVisible, setIsVisible]);

  return (
    <TextField
      ref={ref}
      {...textFieldProps}
      type={passwordIsVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" style={{ color: 'inherit' }}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleVisibilityClick}
              edge="end"
              color="inherit"
              title={passwordIsVisible ? 'Hide password' : 'Show password'}
            >
              {passwordIsVisible ? <Icon icon="hide" /> : <Icon icon="show" />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
});
