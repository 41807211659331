export const MESSAGES_DOMAIN = 'messages';
export const MESSAGES_DOMAIN_URL = `/${MESSAGES_DOMAIN}/*`;

export const MESSAGES_PATHS = {
  list: '/list',
  details: '/details',
  new: '/new',
};

export const MESSAGES_PAGE_TITLES = {
  list: 'Messages',
  details: 'Message Details',
  new: 'New Message',
};
