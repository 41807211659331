import React from 'react';

import { Button, styled, useTheme, GoogleGIcon } from '@liscio/ui';

import { useOAuth } from './useOauth';

const GoogleButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.brandColors.white,
  color: '#4285f4',
  border: '1px solid #4285f4',
}));

export const GoogleOAuthLogin = () => {
  const theme = useTheme();
  const { initiateOAuth } = useOAuth('google');

  return (
    <GoogleButton
      type="button"
      onClick={initiateOAuth}
      variant="contained"
      sx={{
        backgroundColor: theme.palette.common.white,
      }}
      startIcon={<GoogleGIcon fontSize="small" />}
      size="large"
    >
      Login with Google
    </GoogleButton>
  );
};
