export const recurringEndDateLabel: (params: {
  isRecurring?: boolean;
  recurringEndDateString?: string | null;
}) => string = ({ isRecurring, recurringEndDateString }) => {
  if (!isRecurring) {
    return '-';
  }
  return recurringEndDateString || 'NEVER';
};

export default recurringEndDateLabel;
