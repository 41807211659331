import { useCallback } from 'react';

import { QueryKey, useQueryClient } from '@tanstack/react-query';

export const useReloadFunction: (queryKeys: Array<QueryKey>) => () => void = (
  queryKeys
) => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryKeys.forEach((queryKey) => {
      queryClient.resetQueries(queryKey);
    });
  }, [queryClient, queryKeys]);
};

export default useReloadFunction;
