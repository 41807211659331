import React from 'react';

import { styled, Stack } from '@liscio/ui';

import LogoImage from './assets/liscio_logo.png';

const Wrapper = styled(Stack)(() => ({
  paddingTop: '10vh',
  img: {
    width: '60%',
  },
}));

export const Logo = () => (
  <Wrapper justifyContent="center" alignItems="center">
    <img src={LogoImage} alt="logo" />
  </Wrapper>
);

export default Logo;
