import {
  updateResponseItemDocUploadDocument,
  updateResponseItemTextDocument,
  updateResponseItemBoolDocument,
  updateWorkflowRequestMutationDocument,
} from '@liscio/api';
import { useGraphQLClient } from '@liscio/common';

export function useUpdateResponseItemDocUpload() {
  return useGraphQLClient().useMutationGraphQL(
    updateResponseItemDocUploadDocument
  );
}

export function useUpdateResponseItemBool() {
  return useGraphQLClient().useMutationGraphQL(updateResponseItemBoolDocument);
}

export function useUpdateResponseItemText() {
  return useGraphQLClient().useMutationGraphQL(updateResponseItemTextDocument);
}

export function useUpdateWorkflowRequest() {
  return useGraphQLClient().useMutationGraphQL(
    updateWorkflowRequestMutationDocument
  );
}
