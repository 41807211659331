import { useEffect } from 'react';

import { toast } from 'react-hot-toast';

const FALLBACK_ERROR_MESSAGE = 'Something went wrong.';

export default function useToastError(error?: any, message?: string) {
  useEffect(() => {
    if (error) toast.error(message || error.message || FALLBACK_ERROR_MESSAGE);
  }, [error, message]);
}
