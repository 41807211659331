import { NavMenuItem } from '@liscio/common';
import {
  Menu,
  useMenuAnchor,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Icon,
} from '@liscio/ui';

export const BulkActionsNavMenuItem = () => {
  const { anchorEl, open, onOpen, onClose } = useMenuAnchor();

  return (
    <>
      <NavMenuItem
        data-testid="sidebar__bulk_actions"
        icon={<Icon icon="bulkActions" />}
        label="Bulk Actions"
        onClick={onOpen}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'center',
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        sx={{ ml: 1 }}
      >
        <MenuItem href="/bulkdownload">
          <ListItemIcon>
            <Icon icon="download" />
          </ListItemIcon>
          <ListItemText>Invite to Download App</ListItemText>
        </MenuItem>
        <MenuItem href="/bulksignature">
          <ListItemIcon>
            <Icon icon="signatureReq" />
          </ListItemIcon>
          <ListItemText>Get Signature</ListItemText>
        </MenuItem>
        <MenuItem href="/buldmessage">
          <ListItemIcon>
            <Icon icon="sms" />
          </ListItemIcon>
          <ListItemText>Send Message</ListItemText>
        </MenuItem>
        <MenuItem href="/bulkinvite">
          <ListItemIcon>
            <Icon icon="share" />
          </ListItemIcon>
          <ListItemText>
            Inv
            <MenuItem href="/bulkinvite">
              <ListItemIcon>
                <Icon icon="share" />
              </ListItemIcon>
              <ListItemText>Invite to Liscio</ListItemText>
            </MenuItem>
            ite to Liscio
          </ListItemText>
        </MenuItem>
        <MenuItem href="/bulkesign">
          <ListItemIcon>
            <Icon icon="edoc" />
          </ListItemIcon>
          <ListItemText>Send eDocs</ListItemText>
        </MenuItem>
        <MenuItem href="/bulkreassign">
          <ListItemIcon>
            <Icon icon="reassignTask" />
          </ListItemIcon>
          <ListItemText>Reassign Task Owner</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
