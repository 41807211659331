import { ThemeProvider } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { useViewportSize, createTheme } from '@liscio/ui';

/**
 * ConditionalThemeProvider
 */
export const ConditionalThemeProvider: React.FC = () => {
  const { isDesktop } = useViewportSize();
  const theme = createTheme();

  if (isDesktop) {
    return (
      <ThemeProvider theme={theme}>
        <Outlet />
      </ThemeProvider>
    );
  }
  return <Outlet />;
};
