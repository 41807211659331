import React, { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { Icon } from '@liscio/ui';

import { SpeedDial } from 'components';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from 'modules/dashboard/route-constants';
import {
  MESSAGES_DOMAIN,
  MESSAGES_PATHS,
} from 'modules/messages/route-constants';
import { TASKS_DOMAIN, TASKS_PATHS } from 'modules/tasks/route-constants';

export const FirmSpeedDial = () => {
  const navigate = useNavigate();
  const goBackUrl = `/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`;

  const goToCreateNewTask = useCallback(
    (option: string) => {
      const redirectUrl =
        option === 'go:new_task'
          ? `/${TASKS_DOMAIN}${TASKS_PATHS.newTask}`
          : `/${MESSAGES_DOMAIN}${MESSAGES_PATHS.new}`;
      navigate(redirectUrl, {
        state: {
          goBackUrl,
        },
      });
    },
    [goBackUrl, navigate]
  );

  return (
    <SpeedDial
      options={[
        {
          label: 'Message',
          value: 'new_message',
          Icon: <Icon icon="messageLines" />,
        },
        {
          label: 'Task',
          value: 'new_task',
          Icon: <Icon icon="checkbox" />,
        },
      ]}
      onNavigationClick={goToCreateNewTask}
    />
  );
};

export default FirmSpeedDial;
