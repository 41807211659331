export interface DesktopRedirectConfig {
  // If redirect present, redirect to this string
  redirect?: string;
}

/**
 * Path match map configuration
 */
export const exclusionPaths: string[] = [
  '/admin/*',
  'notifications/list',
  'authenticate/*',
  '/',
  'requests/*',
];

export const pathMap: { [path: string]: DesktopRedirectConfig } = {
  '/authenticate/ml/:id': { redirect: '/magicLink/:id' },
  '/': { redirect: '/' },
  '/messages/list': { redirect: '/all_messages' },
  '/messages/details/:id': { redirect: '/inbox/:id' },

  '/files/new': { redirect: '/fileupload/new' },
  '/messages/new': { redirect: '/message/new' },
  '/accounts/list': { redirect: '/accounts' },

  '/contacts/list': { redirect: '/contacts' },

  '/tasks/list': { redirect: '/all_tasks' },
  '/tasks/details/:id': { redirect: '/task/detail/:id' },

  '/invoices/list': { redirect: '/billing/invoices' },
  '/invoices/details/:id': { redirect: '/billing/invoices/:id' },
  '/dashboard/home': { redirect: '/dashboard' },
};

export const pathMapRoutes = Object.keys(pathMap).map((path) => ({
  path,
}));

export const exclusionPathMapRoutes = exclusionPaths.map((path) => ({ path }));

/**
 * Defer rendering of these paths to prevent OTP consumption prior to redirect
 */
export const deferredPaths = ['/magicLink/:id', '/ml/:id'];
