import React, { useEffect } from 'react';

import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import {
  useAuthStore,
  ApiClientProvider,
  initGraphQLClient,
  requestMiddleware,
} from '@liscio/common';
import { createTheme } from '@liscio/ui';

import AppRoutes from './AppRoutes';
import { PushNotifications } from 'components/PushNotifications/PushNotifications';
import { config } from 'config';
import apiClient from 'fetch-utils/api-client';
import LaunchDarklyProvider from 'providers/LaunchDarklyProvider';
import ScanbotProvider from 'providers/ScanbotProvider';
import { WebsocketProvider } from 'providers/WebsocketProvider';
import WebsocketLauncher from 'providers/WebsocketProvider/WebsocketLauncher';

import './assets/fonts/fonts.css';

const liscioTheme = createTheme();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 15 * 1000,
      staleTime: 10 * 1000,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const authToken = useAuthStore(({ auth_token }) => auth_token);
  useEffect(() => {
    if (Capacitor.getPlatform() === 'android') {
      StatusBar.setBackgroundColor({ color: '#434566' });
      //eslint-disable-next-line
      console.info('Liscio app');
    }
  }, []);

  const graphQLClient = initGraphQLClient(config.api.url, requestMiddleware);

  return (
    <HelmetProvider>
      <LaunchDarklyProvider>
        <WebsocketProvider>
          <ThemeProvider theme={liscioTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <QueryClientProvider client={queryClient}>
                <ApiClientProvider
                  apiClient={apiClient}
                  graphQLClient={graphQLClient}
                >
                  <ScanbotProvider>
                    <BrowserRouter>
                      <CssBaseline />
                      <Helmet title="Dashboard" />
                      <PushNotifications />
                      {authToken && <WebsocketLauncher authToken={authToken} />}
                      <Toaster
                        position="top-center"
                        containerStyle={{
                          marginTop: 'env(safe-area-inset-top)',
                        }}
                      />
                      <AppRoutes />
                    </BrowserRouter>
                  </ScanbotProvider>
                </ApiClientProvider>
              </QueryClientProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </WebsocketProvider>
      </LaunchDarklyProvider>
    </HelmetProvider>
  );
}

export default App;
