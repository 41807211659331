import {
  NavMenuItem,
  useNotifications,
  NotificationsPreview,
} from '@liscio/common';
import { Menu, Icon, useMenuAnchor } from '@liscio/ui';

import { routes } from 'routes';
import getNotificationRedirectUrl from 'utils/notifications/getNotificationRedirectUrl';

export const NotificationsNavMenuItem = () => {
  const { anchorEl, open, onOpen, onClose } = useMenuAnchor();
  const { data: notificationsData } = useNotifications({
    pageParam: 1,
    sortField: 'id',
    sortOrder: 'dsc',
  });

  return (
    <>
      <NavMenuItem
        data-testid="sidebar__notifications"
        icon={<Icon icon="notifications" />}
        badgeNumber={notificationsData?.pagination?.count}
        label="Notifications"
        onClick={onOpen}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'center',
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        sx={{ ml: 1 }}
      >
        <NotificationsPreview
          close={onClose}
          goBackUrl={routes.notiicationsList.link()}
          getNotificationRedirectUrl={getNotificationRedirectUrl}
        />
      </Menu>
    </>
  );
};
