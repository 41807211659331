import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { ProfileRole } from '@liscio/api';
import { useBiometrics } from '@liscio/common';

import { config } from 'config';
import { useLaunchDarklyIdentify } from 'custom-hooks/useLaunchDarklyIdentify';
import apiClient from 'fetch-utils/api-client';
import { useContactSMSStatus } from 'fetch-utils/sms/sms-hooks';

const {
  getHomeData,
  getMenuItems,
  getProfile,
  getTermsAndConditions,
  changePassword,
  updateProfile,
  updateUserAvatar,
  acceptTermsAndConditions,
} = apiClient.users;

export function useHomeData() {
  const { identify } = useLaunchDarklyIdentify();
  return useQuery({
    queryKey: ['home-data'],
    queryFn: async () => {
      const homeData = await getHomeData();

      // Update LD Context
      if (homeData) {
        await identify(homeData.data);
      }
      return homeData;
    },
    cacheTime: 36_000_000, // 10 hours
  });
}

export function useMenuItemsData() {
  return useQuery({
    queryKey: ['menu-items'],
    queryFn: getMenuItems,
    cacheTime: 36_000_000, // 10 hours
  });
}

export function useProfile() {
  return useQuery({
    queryKey: ['profile'],
    queryFn: getProfile,
    cacheTime: 36_000_000, // 10 hours
  });
}

export const useProfileData = () => {
  const { data, isLoading: isLoadingProfile } = useProfile();
  const { data: menuItemsResponse, isLoading: isLoadingMenuItems } =
    useMenuItemsData();
  const { data: contactSMSStatus, isLoading: isLoadingContactSMSStatus } =
    useContactSMSStatus();

  const invoiceMenuElement = menuItemsResponse?.data?.find(
    (menuElement) => menuElement.url === '/billing/invoices'
  );

  return {
    cpa_id: data?.cpa_id,
    cpa_user_id: data?.cpa_user_id,
    avatar: data?.avatar,
    email: data?.email,
    first_name: data?.first_name,
    middle_name: data?.middle_name,
    last_name: data?.last_name,
    name:
      data?.first_name || data?.last_name
        ? `${data.first_name} ${data.last_name}`
        : undefined,
    phone: data?.phone,
    role: data?.role,
    title: data?.title,
    isCustomer: data?.role === ProfileRole.Client,
    isFirmUser:
      data?.role === ProfileRole.FirmAdmin ||
      data?.role === ProfileRole.FirmEmployee,
    isFirmAdmin: data?.role === ProfileRole.FirmAdmin,
    isLoading:
      isLoadingProfile || isLoadingMenuItems || isLoadingContactSMSStatus,
    hasInvoiceMenuElement: Boolean(invoiceMenuElement),
    hasSMSElement: contactSMSStatus?.phoneLineOrder === 'complete',
    two_factor_enabled: data?.two_factor_enabled,
  };
};

export function useTermsAndConditions() {
  return useQuery({
    queryKey: ['terms-and-conditions'],
    queryFn: getTermsAndConditions,
    cacheTime: 36_000_000, // 10 hours
  });
}

export function useAcceptTermsAndConditions(
  props?: DefaultMutationHookInterface
) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (version: string) => acceptTermsAndConditions(version),
    onSuccess: async () => {
      client.refetchQueries(['home-data']);
      client.refetchQueries(['terms-and-conditions']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useChangePassword(props?: DefaultMutationHookInterface) {
  const { email } = useProfileData();
  const { saveBiometricCredentials, isBiometricLoginEnabled } = useBiometrics({
    webAppUrl: config.webApp.url,
  });

  return useMutation({
    mutationFn: ({
      currentPassword,
      newPassword,
    }: {
      currentPassword: string;
      newPassword: string;
    }) => changePassword({ currentPassword, newPassword }),
    onSuccess: async (_, data) => {
      // Update biometric credentials
      if (email && isBiometricLoginEnabled) {
        await saveBiometricCredentials({ email, password: data.newPassword });
      }

      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useUpdateProfile(props?: DefaultMutationHookInterface) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({
      firstName,
      middleName,
      lastName,
      phone,
      title,
      isTextingAllowed,
    }: {
      firstName: string;
      middleName: string;
      lastName: string;
      phone: string;
      title: string;
      isTextingAllowed: boolean;
    }) =>
      updateProfile({
        firstName,
        middleName,
        lastName,
        phone,
        title,
        isTextingAllowed,
      }),
    onSuccess: () => {
      client.invalidateQueries(['profile']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useUpdateUserAvatar(props?: DefaultMutationHookInterface) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({ base64Picture }: { base64Picture: string }) =>
      updateUserAvatar({ base64Picture }),
    onSuccess: () => {
      client.invalidateQueries(['profile']);
      props?.onSuccess && props.onSuccess();
    },
  });
}
