import React from 'react';

import { styled, useTheme, Button, MicrosoftIcon } from '@liscio/ui';

import { useOAuth } from './useOauth';

const OutlookButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.brandColors.white,
  color: theme.palette.common.neutrals[800],
  border: `1px solid ${theme.palette.common.neutrals[800]}`,
}));

export const OutlookOAuthLogin = () => {
  const theme = useTheme();
  const { initiateOAuth } = useOAuth('outlook365');

  return (
    <OutlookButton
      type="button"
      onClick={initiateOAuth}
      variant="contained"
      sx={{ background: theme.palette.common.white }}
      startIcon={<MicrosoftIcon fontSize="small" />}
      size="large"
    >
      Login with Microsoft
    </OutlookButton>
  );
};
