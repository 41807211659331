import React from 'react';

import { Chip as MuiChip } from '@mui/material';

export interface UserChipProps {
  userName?: string | null;
  accountName?: string | null;
  onRemoveClick(): void;
}

export const UserChip: React.FC<UserChipProps> = ({
  userName,
  accountName,
  onRemoveClick,
}) => {
  const label = accountName ? `${userName} • ${accountName}` : userName;
  return (
    <MuiChip sx={{ width: 'auto' }} label={label} onDelete={onRemoveClick} />
  );
};

export default UserChip;
