import React from 'react';

import { Paper, CircularProgress } from '@mui/material';

import { styled, Box } from '@liscio/ui';

export interface AndroidStyleProps {
  touchDiff: number;
}

const Wrapper = styled(Box)({
  position: 'relative',
});

const CircleWrapper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  left: 'calc(50% - 20px)',
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: theme.zIndex.modal + 10,
}));

export const AndroidStyle: React.FC<AndroidStyleProps> = ({ touchDiff }) => {
  return (
    <Wrapper>
      {Boolean(touchDiff) && (
        <CircleWrapper elevation={24} sx={{ top: touchDiff }}>
          <CircularProgress variant="indeterminate" disableShrink size={30} />
        </CircleWrapper>
      )}
    </Wrapper>
  );
};

export default AndroidStyle;
