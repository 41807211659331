import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import { DASHBOARD_PAGE_TITLES, DASHBOARD_PATHS } from './route-constants';
import { DashboardView } from './views';

export const ModuleRoutes = () => (
  <>
    <Helmet title={DASHBOARD_PAGE_TITLES.home} />
    <Routes>
      <Route path={DASHBOARD_PATHS.home} Component={DashboardView} />
    </Routes>
  </>
);

export default ModuleRoutes;
